import useCustomToast from "@/core/hooks/useCustomToast"
import { useAdminUserGroupStore } from "../../stores/admin-user-group.store"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { UserGroup } from "../../models/user-group.model"

const ConfirmUserGroupDeletionAlert: React.FC = () => {
    /** */
    const selectedUserGroup = useAdminUserGroupStore( s => s.selectedUserGroup )
    const { removeUserGroup, deselectedUserGroup } = useAdminUserGroupStore()
    const toast = useCustomToast()

    const handleDeleteUserGroup = () => {
        removeUserGroup( selectedUserGroup?.id! ).then(
            () => toast.success( 'Grupo eliminado correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al intentar eliminar el grupo' )
        )
    }

    return (
        <CustomAlertDialog
            id="confirmUserGroupDeletionAlert"
            title="Eliminar grupo"
            size="xl"
            cancelText="Cancelar"
            acceptText="Estoy de acuerdo"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onAccept={handleDeleteUserGroup}
            onCloseComplete={deselectedUserGroup}
        >
            {( data?: UserGroup ) => (
                <>
                    <p className="text-lg">
                        Se eliminará el grupo <strong className="text-brand-500">{data?.name}</strong>. ¿Estás de acuerdo?
                    </p>
                </>
            )}
        </CustomAlertDialog>
    )
}

export default ConfirmUserGroupDeletionAlert