import BrandPageHead from '@/core/components/molecules/BrandPageHead'
import FileCard from '@/core/components/molecules/FileCard';
import { useComponentsStore } from '@/core/stores/components.store';
import { CheckIcon } from '@chakra-ui/icons';
import { Badge, Box, Button, SimpleGrid } from '@chakra-ui/react';
import { BiUpload } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useConciliationStore } from '../stores/conciliation.store';
import WitnessesApproveModal from './WitnessesApproveModal';
import { useModalStore } from '@/core/stores/modal.store';
import ConciliationLoadFileModal from './ConciliationLoadFileModal';
import { useState } from 'react';
import { EvidencesFiles } from '../models/conciliation.model';

function WitnessesEvidencesPage() {
  const openModal = useModalStore(e => e.openModal)
  const { uiConciliationComponents } = useComponentsStore()
  const { selectedWitnessesMonth, setSelectedFile } = useConciliationStore()
  const [selectFile, setSelectFile] = useState<File | null>(null);
  const navigate = useNavigate()
  const handleClick = (file: EvidencesFiles) => {
    setSelectedFile(file)
    navigate('/views/reconciliations/witnesses/evidences/document-view')
  }
  return (
    <>
      <BrandPageHead subtitle={selectedWitnessesMonth?.month} type='evidences' >
        {
          uiConciliationComponents.addWitnessesFiles && <Button rightIcon={<BiUpload />} onClick={() => openModal('ConciliationLoadFileModal')}>
            Cargar documento
          </Button>
        }
        {
          uiConciliationComponents.approveWitnesses && selectedWitnessesMonth?.status !== 'Aceptada' && <Button rightIcon={<CheckIcon />} onClick={() => openModal('Witnessesapprove')}>
            Aceptar testigos
          </Button>
        }
      </BrandPageHead>
      <SimpleGrid columns={{ base: 1, lg: 4 }} spacing={4}>
        {
          selectedWitnessesMonth?.files?.map((evidence, index) => (
            <Box key={index} onClick={() => handleClick(evidence)} cursor='pointer'>
              <FileCard nameFile={evidence.name!} typeFile={evidence.type!} id={evidence.id} file={evidence.file} />
            </Box>
          ))
        }
      </SimpleGrid>
      <WitnessesApproveModal />
      <ConciliationLoadFileModal setSelectedFile={setSelectFile} accept='image' />
    </>
  )
}

export default WitnessesEvidencesPage