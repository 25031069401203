import BrandBox from '@/core/components/molecules/BrandBox'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface CompairTitleProps {
  title: string
}

function CompairTitle({ title }: CompairTitleProps) {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // -1 indica que queremos ir una página atrás en el historial
  };
  
  return (
    <BrandBox className='mb-3'>
      <Flex justifyContent='space-between'>
        <Flex gap={2} alignItems={'center'}>
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
            variant="ghost"
            onClick={handleBack} // Agrega la función de retroceso
          />
          <Text>Comparando</Text>
          <Text fontWeight="bold">{title}</Text>
        </Flex>
        <Flex gap={2}>
          <Text fontWeight="bold">Campaña:</Text>
          <Text>nombre campaña</Text>
        </Flex>
      </Flex>
    </BrandBox>
  )
}

export default CompairTitle