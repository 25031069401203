import { Box, Flex, Text, Button, Divider, Image, Tooltip, IconButton, AspectRatio } from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { RxDotsVertical } from 'react-icons/rx';
import { useUserStore } from '@/modules/auth/stores/user.store';
import { useCampaignStore } from '@/modules/campaigns/store/campaign.store';

interface PlanningDetailCardProps {
  title?: string;
  status?: string;
  creationDate?: string;
}

const ConciliationDetailHeadCard = ({ title, status, creationDate }: PlanningDetailCardProps) => {
  const selectedCampaign = useCampaignStore(e => e.selectedCampaign)
  const {userData} = useUserStore();
  return (
    <>
      <Box p="1" pb={2}>
        <Flex pb={'2'} justify="space-between" align="center">
          <Flex columnGap={2}>
            <AspectRatio width={'50px'} ratio={4 / 3}>
              <Image borderRadius='md' src={selectedCampaign?.imgCampaign ?? 'https://via.placeholder.com/150'} alt='Dan Abramov' />
            </AspectRatio>
            <Flex direction={'column'} gap={1}>
              <Flex align="center">
                <Text fontSize="xl" fontWeight="semibold" mr="2">
                  {selectedCampaign?.campaignName}
                </Text>
              </Flex>
              <Flex gap={5}>
                <Text display={'flex'} alignItems={'center'} gap={1} fontSize="sm">
                  <FiUser />
                  <Text fontWeight="bold">Autor: </Text> {userData?.firstName}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box>
            <Tooltip label='Opciones'>
              <Button
                as={IconButton}
                icon={<RxDotsVertical color='gray' size={20} />}
                variant="ghost"
                size="md"
                margin={'0'}
                padding={'6px'}
                borderRadius='full'
                onClick={(e) => console.log('open')}
              />
            </Tooltip>
          </Box>
        </Flex>
        <Divider borderColor="gray.300" />
      </Box>
    </>
  );
};

export default ConciliationDetailHeadCard;
