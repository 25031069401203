import BrandBox from '@/core/components/molecules/BrandBox'
import { AspectRatio, Badge, Box, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { EvidencesByMonth, EvidencesFiles } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'

interface ConciliationLetter {
  provider?: string
  month?: string
  numberBillings?: number
  evidencesNumber?: number
  status?: string
  type?: string
  evidences?: EvidencesByMonth[]
  files?: EvidencesFiles[]
}

interface WitnessesProps {
  conciliation: ConciliationLetter
}

function WitnessesCards({ conciliation }: WitnessesProps) {
  const { selectedWitnessesProvider } = useConciliationStore();
  return (
    <Box cursor='pointer'>
      <BrandBox className='!p-3'>
        <Flex gap={1} mb={2}>
          <AspectRatio maxW='400px' w='100%' ratio={4 / 3}>
            <Image src={selectedWitnessesProvider ? selectedWitnessesProvider?.evidences?.[0].files?.[0].file : conciliation?.evidences?.[0].files?.[0].file} objectFit='cover' />
          </AspectRatio>
          <AspectRatio maxW='400px' w='100%' ratio={4 / 3}>
            <Image src={selectedWitnessesProvider ? selectedWitnessesProvider?.evidences?.[0].files?.[1].file : conciliation?.evidences?.[0].files?.[1].file} objectFit='cover' />
          </AspectRatio>
        </Flex>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text color='brand.500' fontWeight='semibold'>{conciliation.month ? conciliation.month : conciliation.provider}</Text>
          {
            conciliation.status ?
              <Badge paddingX={2} rounded='full' colorScheme={conciliation.status === 'Pendiente' ? 'yellow' : 'green'}>{conciliation.status}</Badge>
              :
              <Text color='gray' fontWeight='semibold'>{conciliation.type}</Text>
          }
        </Flex>
        {
          conciliation.evidencesNumber && <>
            <Divider pt={2} />
            <Box pt={2} fontWeight='semibold'>
              Evidencias cargadas ({conciliation.files?.length})
            </Box>
          </>
        }

      </BrandBox>
    </Box>
  )
}

export default WitnessesCards