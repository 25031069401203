import { Box, Flex } from "@chakra-ui/react"
import CompairFilters from "./CompairFilters"
import CompairTitle from "./CompairTitle"
import OnlyViewExcelTable from "@/core/components/organism/OnlyViewExcelTable"

interface CompairTitleProps {
  title: string
}
function CompairPage({ title }: CompairTitleProps) {
  return (
    <>
      <CompairTitle title={title} />
      <CompairFilters />
      <Box display={'flex'} mt={3}>
        <OnlyViewExcelTable key={0} />
        <OnlyViewExcelTable key={1} />
      </Box>
    </>
  )
}

export default CompairPage