import { useModalStore } from '@/core/stores/modal.store';
import { ViewIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Select, Tooltip } from '@chakra-ui/react'
import CompairSummaryModal from './CompairSummaryModal';
const versions: any = [];
const providers = ['Proveedor 1', 'Proveedor 2', 'Proveedor 3'];
const months = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function CompairFilters() {
  const openModal = useModalStore(e => e.openModal)
  return (
    <>
      <Flex gap={4}>
        <Flex gap={4} w={'47%'}>
          <Select variant='white' placeholder="Versión">
            {versions.map((version: any) => (
              <option key={version.label} value={version.label}>{version.label}</option>
            ))}
          </Select>
          <Select variant='white' placeholder="Proveedor">
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </Select>
          <Select variant='white' placeholder="Mes">
            {months.map((month, index) => (
              <option key={index} value={month.toLowerCase()}>{month}</option>
            ))}
          </Select>
        </Flex>
        <Tooltip label='Ver resumen'>
          <IconButton
            w={'auto'}
            aria-label="View"
            icon={<ViewIcon />}
            colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
            variant="solid" // O puedes usar "solid" para un botón lleno
            onClick={() => openModal('CompairSummaryModal')}
          />
        </Tooltip>

        <Flex gap={4} w={'47%'}>
          <Select variant='white' placeholder="Versión">
            {versions.map((version: any) => (
              <option key={version.label} value={version.label}>{version.label}</option>
            ))}
          </Select>
          <Select variant='white' placeholder="Proveedor">
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </Select>
          <Select variant='white' placeholder="Mes">
            {months.map((month, index) => (
              <option key={index} value={month.toLowerCase()}>{month}</option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <CompairSummaryModal />
    </>
  )
}

export default CompairFilters