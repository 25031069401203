import { useFormik } from "formik";
import CustomModal from "../../../core/components/organism/CustomModal"
import { Alert, AlertIcon, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { CalendarIcon, EditIcon } from "@chakra-ui/icons";
import { useModalStore } from "@/core/stores/modal.store";
import { FcParallelTasks } from "react-icons/fc";
import { useEffect, useState } from "react";
import { Task } from "gantt-task-react";
import { BsPercent } from "react-icons/bs";
import * as Yup from "yup"
import { DatesUtil } from "@/core/utils/dates.util";
import { usePlanningStore } from "../store/planning.store";

const EditTaskModal: React.FC = () => {
  /** */
  const { setTasksRoadMapselect, tasksRoadmapSelected } = usePlanningStore();
  const closeModal = useModalStore(s => s.closeModal)
  const [currentTask, setCurrentTask] = useState<Task>();
  const [dateError, setDateError] = useState('');

  const editTast = () => {
    const initialDate = new Date(taskForm.values.initialDate)
    const endDate = new Date(taskForm.values.endDate)
    if (initialDate > endDate) {
      setDateError('La fecha inicial es mayor que la fecha final')
      return
    }
    if (currentTask?.type === 'task') {
      const tasksProject = tasksRoadmapSelected.filter((e) => e.project === currentTask.project).filter((e) => e.name === taskForm.values.name);
      const project = tasksRoadmapSelected.filter((e) => e.id === currentTask.project)[0]
      if (currentTask.name !== taskForm.values.name && tasksProject.length !== 0) {
        setDateError('Ya existe una tarea con el mismo nombre dentro del proyecto seleccionado')
        return
      }
      if (initialDate.toISOString().split('T')[0] < project.start.toISOString().split('T')[0] || endDate.toISOString().split('T')[0] > project.end.toISOString().split('T')[0]) {
        setDateError('Las fechas de la tarea están fuera del rango del proyecto seleccionado')
        return
      }
    } else {
      const project = tasksRoadmapSelected.filter((e) => e.name === taskForm.values.name)
      if (currentTask?.name !== taskForm.values.name && project.length !== 0) {
        setDateError('Ya existe un proyecto con el mismo nombre')
        return
      }
    }

    const task: Task = {
      ...currentTask!,
      ...{
        start: DatesUtil.formatStringToDate(taskForm.values.initialDate),
        end: DatesUtil.formatStringToDate(taskForm.values.endDate),
        name: taskForm.values.name,
        progress: taskForm.values.progress,
      }
    }
    const updatedArray = tasksRoadmapSelected.map(e => e.id === task.id ? task : e);
    setTasksRoadMapselect(updatedArray)
    closeModal('editTaskModal')
    setDateError('')
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('El nombre de la actividad es requerido'),
    initialDate: Yup.date().required('La fecha inicial de la tarea es requerida'),
    endDate: Yup.date().required('La fecha final de la tarea es requerida'),
  });

  const taskForm = useFormik({
    onSubmit: editTast,
    validateOnBlur: true,
    initialValues: { name: '', initialDate: '2024-02-01', endDate: '2024-02-01', progress: 0 },
    validationSchema
  })

  useEffect(() => {
    if (currentTask) {
      taskForm.setValues({
        name: currentTask.name,
        initialDate: DatesUtil.formatDateToString(currentTask.start),
        endDate: DatesUtil.formatDateToString(currentTask.end),
        progress: currentTask.progress,
      });
      setDateError('')
    }
    // eslint-disable-next-line
  }, [currentTask, taskForm.setValues])

  return (
    <CustomModal id="editTaskModal" title="Editar actividad" size="md" withButtons={true} showCancelButton={true}
      onCancel={() => console.log('Cancelar')}
      onAccept={taskForm.handleSubmit}
      closeOnAccept={false}
      cancelText="Cancelar"
      acceptText="Guardar cambios"
      icon={FcParallelTasks}
    >
      {(data: Task) => {
        setCurrentTask(data);

        return <form onSubmit={taskForm.handleSubmit}>
          <FormControl mb={2} isInvalid={!!taskForm.errors.name}>
            <FormLabel mb={0}>Nombre de {data?.project ? 'tarea' : 'proyecto'}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EditIcon color="gray.300" />
              </InputLeftElement>
              <Input id="name" type="text" placeholder={`Nombre de ${data?.project ? 'tarea' : 'proyecto'}`}
                onChange={taskForm.handleChange}
                value={taskForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.initialDate}>
            <FormLabel mb={0}>Fecha inicial</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="initialDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.initialDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.initialDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.endDate}>
            <FormLabel mb={0}>Fecha final</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="endDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.endDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.endDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.progress}>
            <FormLabel mb={0}>Progreso de actividad</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsPercent color="gray.300" />
              </InputLeftElement>
              <Input id="progress" type="number" placeholder="0" min={0} max={100}
                onChange={taskForm.handleChange}
                value={taskForm.values.progress}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.progress}</FormErrorMessage>
          </FormControl>
          {dateError && <Alert status='error'>
            <AlertIcon />
            {dateError}
          </Alert>}
        </form>
      }}
    </CustomModal>
  )
}

export default EditTaskModal