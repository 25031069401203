import useCustomToast from "@/core/hooks/useCustomToast"
import { useAdminRoleStore } from "../../stores/admin-role.store"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { AdminRole } from "../../models/admin-role.model"

const ConfirmRoleDeletionAlert: React.FC = () => {
    /** */
    const selectedAdminRole = useAdminRoleStore( s => s.selectedAdminRole )
    const { removeAdminRole, deselectAdminRole } = useAdminRoleStore()
    const toast = useCustomToast()

    const handleDeleteRole = () => {
        removeAdminRole( selectedAdminRole?.id! ).then(
            () => toast.success( 'Rol eliminado correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al intentar eliminar el rol' )
        )
    }

    return (
        <CustomAlertDialog
            id="confirmRoleDeletionAlert"
            title="Eliminar rol"
            size="xl"
            cancelText="Cancelar"
            acceptText="Estoy de acuerdo"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onAccept={handleDeleteRole}
            onCloseComplete={deselectAdminRole}
        >
            {( data?: AdminRole ) => (
                <>
                    <p className="text-lg">
                        Se eliminará el rol <strong className="text-brand-500">{data?.name}</strong>. ¿Estás de acuerdo?
                    </p>
                </>
            )}
        </CustomAlertDialog>
    )
}

export default ConfirmRoleDeletionAlert