import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import { useUserStore } from "@/modules/auth/stores/user.store"
import { Box, Button, Input, InputGroup, InputLeftElement, Select } from "@chakra-ui/react"
import { TbFilterSearch } from "react-icons/tb"
import { TbUserPlus } from "react-icons/tb"
import { useAdminUserStore } from "../stores/admin-user.store"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import { useState } from "react"
import { TbPencil } from "react-icons/tb"
import { TbTrash } from "react-icons/tb"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { useModalStore } from "@/core/stores/modal.store"
import { AdminUser } from "../models/admin-user.model"
import CreateOrEditUserModal from "../components/users/CreateOrEditUserModal"
import BrandBadge from "@/core/components/atoms/BrandBadge"
import { useAdminRoleStore } from "../stores/admin-role.store"
import ConfirmUserDeletionAlert from "../components/users/ConfirmUserDeletionAlert"

const UsersPage: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const { retrieveUsers, selectUser } = useAdminUserStore()
    const users = useAdminUserStore( s => s.users )
    const { retrieveRoles } = useAdminRoleStore()
    const [ selectedUserType, setSelectedUserType ] = useState( 'all' )
    const [ inputFilter, setInputFilter] = useState<string>('')
    const openModal = useModalStore( s => s.openModal )

    useMountedEffect( () => {
        retrieveUsers( companyData?.id! )
        retrieveRoles()
    })

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'fullName', sortable: true },
        { header: 'Correo', accessor: 'email', sortable: true },
        {
            header: 'Tipo de usuario', accessor: 'userType', sortable: true, customRow: [
                ( row: AdminUser ) => <BrandBadge label={row.userType!} color={row.external ? 'purple' : 'default'} />
            ]
        },
        {
            header: 'Acciones', accessor: '', positionRow: 'end', customRow: [
                row => <ActionIcon label="Editar usuario" icon={TbPencil} onHandle={() => handleEditUser( row )} />,
                row => <ActionIcon label="Eliminar usuario" icon={TbTrash} onHandle={() => handleDeleteUser( row )} />
            ]
        }
    ]

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    const filterUsers = () => {
        const filteredUsersByType = users.filter( item => {
            if ( selectedUserType === 'internal' ) {
                return !item.external
            } else if ( selectedUserType === 'external' ) {
                return item.external
            } else {
                return true
            }
        })

        const filteredUsersByInput = filteredUsersByType.filter( item => {
            const searchString = `${item.name} ${item.lastName} ${item.email} ${item.userType || ''}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }

    const handleNewUser = () => {
        openModal( 'createOrEditUserModal' )
    }

    const handleEditUser = ( row: AdminUser ) => {
        selectUser( row )
        openModal( 'createOrEditUserModal' )
    }

    const handleDeleteUser = ( row: AdminUser ) => {
        selectUser( row )
        openModal( 'confirmUserDeletionAlert', row )
    }

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-between">
                <Select
                    bg="white"
                    borderRadius="md"
                    w={'auto'}
                    value={selectedUserType}
                    onChange={( event ) => setSelectedUserType( event.target.value )}
                >
                    <option value="all">Todos</option>
                    <option value="internal">Internos</option>
                    <option value="external">Externos</option>
                </Select>
                <div className="flex items-center gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    <Box>
                        <Button
                            leftIcon={<TbUserPlus />}
                            colorScheme="brand"
                            onClick={handleNewUser}>
                            Agregar usuario
                        </Button>
                    </Box>
                </div>
            </div>
            {/* Datos */}
            <BrandTable
                columns={columns}
                data={filterUsers()}
                rowsPerPageOptions={[5, 10, 15]}
                defaultRowsPerPage={5}
                showPagination={true}
            />
            {/* Modals | Alerts */}
            <CreateOrEditUserModal />
            <ConfirmUserDeletionAlert />
        </article>
    )
}

export default UsersPage