import BrandBadge from "@/core/components/atoms/BrandBadge"
import BrandBox from "@/core/components/molecules/BrandBox"
import { DataUtil } from "@/core/utils/data.util"
import { IconType } from "react-icons"

interface AmountIndicatorProps {
    icon: IconType
    filterText?: string
    label: string
    amount: number
    currency: string
    isActive?: boolean
}

const AmountIndicator: React.FC<AmountIndicatorProps> = ({
    icon: Icon,
    filterText,
    label,
    amount,
    currency,
    isActive = false
}) => {
    /** */

    return (
        <BrandBox
            className={`flex flex-col gap-2 ${isActive ? '!bg-brand-500 !text-white' : ''}`}>
            <div className="flex items-center justify-between">
                <div className="rounded-full bg-gray-200 p-1">
                    <Icon className="text-brand-500" />
                </div>
                {filterText && (
                    <BrandBadge label={filterText} color="gray" />
                )}
            </div>
            <small>{label}</small>
            <strong className="text-2xl">
                {`${currency}${DataUtil.numberFormat( amount )}`}
            </strong>
        </BrandBox>
    )
}

export default AmountIndicator