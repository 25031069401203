import { Link, useLocation } from 'react-router-dom'
import logo from '@/assets/images/identity/logo.png'
import isotype from '@/assets/images/identity/isotype-om.svg'
import { memo, useEffect, useState } from 'react'
import Notifications from './Notifications'
import UserMenu from '@/core/components/molecules/UserMenu'
import Company from '@/core/components/atoms/Company'
import Navigation from '@/core/components/molecules/Navigation'
import { ExcludePathsNav } from '@/core/constants/exclude-paths-nav'

interface NavbarProps {
    userName: string
    companyName?: string
    signOut: () => void
}

const Navbar: React.FC<NavbarProps> = ( props ) => {
    /** */
    const location = useLocation()
    const [ showNavigation, setShowNavigation ] = useState( false )

    useEffect( () => {
        const excludeRoutesNavigation = ExcludePathsNav
        const isExcludeRouteNavigation = excludeRoutesNavigation.includes( location.pathname )

        setShowNavigation( !isExcludeRouteNavigation )
    }, [location, setShowNavigation])

    return (
        <header className="sticky top-0 z-40 flex items-center justify-between px-16 py-4 bg-white">
            <div className="flex">
                {/* Logo */}
                <Link to="/views">
                    <img className="md:block hidden h-[28px]" src={logo} alt="Logo Orkestamedia" />
                    <img className="md:hidden block h-[28px]" src={isotype} alt="Logo Orkestamedia" />
                </Link>
                {/* Navigation */}
                {showNavigation && (
                    <Navigation />
                )}
            </div>
            <div className='flex'>
                {/* Company info... */}
                <Company
                    name={props.companyName}
                />
                {/* Notifications */}
                <Notifications />
                {/* Handle User */}
                <UserMenu
                    userName={props.userName}
                    signOut={props.signOut}
                />
            </div>
        </header>
    )
}

export default memo( Navbar )