import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";

/** Contrato del Store */
interface LoaderState {
    loadingCount: number
    showLoader: () => void
    hideLoader: () => void
    isLoading: () => boolean
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    loadingCount: 0
}

/** Definición del Store */
const Store: StateCreator<LoaderState> = ( set, get ) => ({

    ...initialState,

    showLoader: () => set( state => ({ loadingCount: state.loadingCount + 1 }) ),

    hideLoader: () => set( state => ({ loadingCount: state.loadingCount > 0 ? state.loadingCount - 1 : 0 }) ),

    isLoading: () => get().loadingCount > 0,

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useLoaderStore = create<LoaderState>()(
    immer( Store )
)