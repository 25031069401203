import { Button, FormControl, FormLabel, Switch } from "@chakra-ui/react"
import { useAdminOtpPoliciesStore } from "../stores/admin-otp-policies.store"
import LinkButton from "@/core/components/atoms/LinkButton"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import { useUserStore } from "@/modules/auth/stores/user.store"
import { useModalStore } from "@/core/stores/modal.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useAdminUserStore } from "../stores/admin-user.store"
import AddOtpUsersExceptionsModal from "../components/config/AddOtpUsersExceptionsModal"

const AuthenticationPage: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const policyData = useAdminOtpPoliciesStore( s => s.policyData )
    const pwdPolicies = useAdminOtpPoliciesStore( s => s.pwdPolicies )
    const otpPolicies = useAdminOtpPoliciesStore( s => s.otpPolicies )
    const notApplicableUsers = useAdminOtpPoliciesStore( s => s.notApplicableUsers )
    const {
        retrievePolicyData,
        updatePolicyData,
        retrieveNotApplicableUsers,
        mutatePwdPolicies,
        mutateOtpPolicies
    } = useAdminOtpPoliciesStore()
    const { retrieveUsers } = useAdminUserStore()
    const openModal = useModalStore( s => s.openModal )
    const toast = useCustomToast()

    const handlePwdPolicy = ( index: number, type: string, value?: any ) => {
        const policies = [...pwdPolicies]

        const updatedPolicies = policies.map( ( policy, idx ) => {

            if ( type === 'status' && ( index === idx ) ) {

                const input = document.getElementById( `input-policy${index}` )

                policy.active = !policy.active

                if ( policy.active && input ) {
                    input.removeAttribute( 'disabled' )
                    input.focus()
                }

                ( !policy.active && input ) && input.setAttribute( 'disabled', 'true' )
            }

            if ( type === 'value' ) {
                index === idx && ( policy.value = value )
            }

            return policy
        })

        mutatePwdPolicies( updatedPolicies )
    }

    const handleOtpPolicy = ( index: number, type: string, value?: any ) => {
        const policies = [...otpPolicies]

        const updatedPoliciesOtp = policies.map( ( policy, idx ) => {

            if ( type === 'status' && ( index === idx ) ) {

                const input = document.getElementById( `input-policy-otp${index}` )

                policy.active = !policy.active

                if ( policy.active && input ) {
                    input.removeAttribute( 'disabled' )
                    input.focus()
                }

                if ( policy.active && policy.policyType === 'OTP_REQUIRED' ) {
                    handleAddExceptionsModal()
                }

                ( !policy.active && input ) && input.setAttribute( 'disabled', 'true' )
            }

            if ( type === 'value' ) {
                index === idx && ( policy.value = value )
            }

            return policy
        });

        mutateOtpPolicies( updatedPoliciesOtp )
    }

    const handleAddExceptionsModal = () => {
        retrieveUsers( companyData?.id!, notApplicableUsers )
        openModal( 'addOtpUsersExceptionsModal' )
    }

    const handleSavePolicies = () => {
        updatePolicyData( policyData! ).then(
            () => toast.success( 'Políticas actualizadas correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al actualizar las políticas' )
        )
    }

    useMountedEffect( () => {
        retrievePolicyData( companyData?.id! )
        retrieveNotApplicableUsers( companyData?.id! )
    })

    return (
        <>
            <div className="w-full flex justify-between gap-4">
                <div className="w-1/2 border-2 rounded-lg p-4 border-slate-300">
                    <h1 className="rounded-lg text-brand-500 w-full text-center py-2 font-semibold">
                        Políticas
                    </h1>
                    <div className="h-full max-h-72 overflow-x-auto">
                        {pwdPolicies.map( ( policy, index ) => (
                            <div key={index} className="flex justify-between mt-2">
                                <div className="px-2 py-1 pr-0 flex justify-center items-center">
                                    <FormControl display='flex' alignItems='center'>
                                        <div className="mr-3">
                                            <Switch id={'sw-policy'+index}
                                                size="md"
                                                isChecked={policy.active}
                                                onChange={() => handlePwdPolicy( index, 'status' )}
                                            />
                                        </div>
                                        <FormLabel className="cursor-pointer text-gray-600"
                                            htmlFor={'sw-policy'+index} mb='0'>
                                            {policy.description}
                                        </FormLabel>
                                    </FormControl>
                                </div>
                                <div className="px-2 py-1 pl-0 text-center flex justify-center align-center">
                                    { policy.valueType !== 'BOOLEAN' && (
                                        <>
                                            <input id={'input-policy'+index} type="number"
                                                className="px-2 text-center rounded-lg bg-slate-200 w-14 mr-2"
                                                value={policy.value}
                                                onChange={ e => handlePwdPolicy( index, 'value', e.target.value ) }
                                                disabled={ policy.active ? undefined : true }
                                                min={policy.min}
                                                max={policy.max} />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                        {pwdPolicies.length === 0 && (
                            <div className="flex justify-center mt-2">
                                No hay políticas definidas
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-1/2 border-2 rounded-lg p-4 border-slate-300">
                    <h1 className="rounded-lg text-brand-500 w-full text-center py-2 font-semibold">
                        Autenticación a 2 pasos
                    </h1>
                    <div className="h-full max-h-72 overflow-x-auto">
                        {otpPolicies.map( ( policy, index ) => (
                            <div key={index} className="flex justify-between mt-2">
                                <div className="px-2 py-1 pr-0 flex justify-center items-center cursor-pointer">
                                    <FormControl display='flex' alignItems='center'>
                                        <div className="mr-3">
                                            <Switch id={'sw-policy-otp'+index}
                                                size="md"
                                                isChecked={policy.active}
                                                onChange={() => handleOtpPolicy( index, 'status' )}
                                            />
                                        </div>
                                        <FormLabel className="cursor-pointer text-gray-600"
                                            htmlFor={'sw-policy-otp'+index} mb='0'>
                                            {policy.description}
                                        </FormLabel>
                                    </FormControl>
                                </div>
                                <div className="px-2 py-1 pl-0 text-center flex justify-center align-center">
                                    { policy.valueType !== 'BOOLEAN' && (
                                        <>
                                            <input id={'input-policy-otp'+index} type="number"
                                                className="px-2 text-center rounded-lg bg-slate-200 w-14 mr-2"
                                                value={policy.value}
                                                onChange={ e => handleOtpPolicy( index, 'value', e.target.value ) }
                                                disabled={ policy.active ? undefined : true }
                                                min={policy.min}
                                                max={policy.max} />
                                        </>
                                    )}
                                    { policy.policyType === 'OTP_REQUIRED' && (
                                        <LinkButton
                                            onClick={ () => handleAddExceptionsModal() }>
                                            <b>Agregar excepciones</b>
                                        </LinkButton>
                                    )}
                                </div>
                            </div>
                        ))}
                        {otpPolicies.length === 0 && (
                            <div className="flex justify-center mt-2">
                                No hay políticas definidas para la autenticación a 2 pasos
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center mt-8">
                <Button
                    type="submit"
                    rounded="full"
                    size="md"
                    onClick={handleSavePolicies}
                    fontWeight="medium"
                    px={"2rem"}
                    isLoading={false}>
                    Actualizar políticas
                </Button>
            </div>
            {/* Modals */}
            <AddOtpUsersExceptionsModal />
        </>
    )
}

export default AuthenticationPage