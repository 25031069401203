import { Apis } from "@/apis";
import HttpClient from "../http/http-client";
import { GenericApiResponse } from "../models/generic-api-response";

export class VaultService {
    /** */
    private http = new HttpClient( `${Apis.BASE}/api/general/vault` )

    public getFile(idFile: string): Promise<GenericApiResponse> {
      return this.http.get( `/documentData/${idFile}`, {})
    }
}