import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { useConciliationStore } from '../stores/conciliation.store'

function WitnessesApproveModal() {
  const { selectedWitnessesMonth, selectedWitnessesProvider, witnessesProviders, setSelectedWitnesses, setSelectedWitnessesMonth, setSelectedWitnessesProvider } = useConciliationStore();
  const handleClosePlanning = () => {
    const dataMonth = {
      ...selectedWitnessesMonth,
      status: 'Aceptada'
    }
    const arrayEvidences = selectedWitnessesProvider?.evidences?.map(e => e.month === selectedWitnessesMonth?.month ? dataMonth : e)
    const dataProv = {
      ...selectedWitnessesProvider,
      evidences: arrayEvidences
    }
    const dataWit = witnessesProviders.map(e => e.provider === dataProv.provider ? dataProv : e)
    setSelectedWitnesses(dataWit)
    setTimeout(() => {
      setSelectedWitnessesProvider(selectedWitnessesProvider?.provider!)
      setSelectedWitnessesMonth(selectedWitnessesMonth?.month!)
    }, 100);

  }
  return (
    <CustomAlertDialog
      id="Witnessesapprove"
      title={`Aceptar testigos`}
      cancelText="Cancelar"
      acceptText="Aceptar testigos"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleClosePlanning}
    >
      {() => (
        <Box>
          <Text mb={1}>Estás a punto de aprobar toda la evidencia del mes de <b>{selectedWitnessesMonth?.month}</b> del proveedor <b>{selectedWitnessesProvider?.provider}</b></Text>
          <Text>¿deseas continuar?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default WitnessesApproveModal