import CustomModal from "@/core/components/organism/CustomModal"
import { useEffect, useState } from "react"
import { FcTreeStructure } from "react-icons/fc"
import { useAdminCompanyStore } from "../../stores/admin-company.store"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Box, FormControl, FormErrorMessage, Grid, GridItem, Input, InputGroup, Select } from "@chakra-ui/react"
import { Activites } from "@/core/constants/activities.constant"
import BrandBox from "@/core/components/molecules/BrandBox"
import HeadModule from "@/core/components/molecules/HeadModule"
import MultiSelect from "@/core/components/molecules/MultiSelect"
import { Services } from "@/core/constants/services.constant"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbTrash } from "react-icons/tb"
import { Company } from "../../models/company"
import { UpdateCompany } from "../../models/update-company.model"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useModalStore } from "@/core/stores/modal.store"

const CreateOrEditCompanyModal: React.FC = () => {
    /** */
    const selectedOwnerCompany = useAdminCompanyStore( s => s.selectedOwnerCompany )
    const companies = useAdminCompanyStore( s => s.allCompanies )
    const [ selectedCompany, setSelectedCompany ] = useState<Company>()
    const { deselectOwnerCompany, updateCompany, createCompany } = useAdminCompanyStore()
    const [ modalTitle, setModalTitle ] = useState<string>( '' )
    const [ selectedServices, setSelectedServices ] = useState<{id: string, name: string}[]>( [] )
    const regExpRFC = new RegExp(
        /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i
    )
    const toast = useCustomToast()
    const closeModal = useModalStore( s => s.closeModal )
    const [ isLegalRepresentativeError, setIsLegalRepresentativeError ] = useState<boolean>( false )

    useEffect( () => {
        let title = selectedOwnerCompany ? 'Editar empresa' : 'Agregar empresa'

        setModalTitle( title )

        if ( selectedOwnerCompany ) {
            const company = companies.find( item => item.id === selectedOwnerCompany.foreignCompanyId )
            
            setSelectedCompany( company )

            companyForm.setFieldValue( 'name', company?.name )
            companyForm.setFieldValue( 'taxRegime', company?.type )
            companyForm.setFieldValue( 'rfc', company?.rfc )
            companyForm.setFieldValue( 'web', company?.web )
            companyForm.setFieldValue( 'sector', company?.sector )
            companyForm.setFieldValue( 'employeesNumber', company?.employeesSize )

            if ( company?.servicesOffered) {
                const splitServices = company.servicesOffered.split('|')
                let services: {id: string, name: string}[] = []

                for ( const service of splitServices ) {
                    const element = { id: '', name: service }
                    services.push( element )
                }

                setSelectedServices( services )
            }

            companyForm.setFieldValue( 'street', company?.taxResidence?.street )
            companyForm.setFieldValue( 'exteriorNumber', company?.taxResidence?.outdoorNum )
            companyForm.setFieldValue( 'interiorNumber', company?.taxResidence?.interiorNumber )
            companyForm.setFieldValue( 'neighborhood', company?.taxResidence?.colonia )
            companyForm.setFieldValue( 'city', company?.taxResidence?.city )
            companyForm.setFieldValue( 'zipCode', company?.taxResidence?.cp )
            companyForm.setFieldValue( 'country', company?.taxResidence?.country )

            if ( company?.legalRepresentatives ) {
                const newLegals = company?.legalRepresentatives.map( item => {
                    return {
                        name: item.name,
                        phaterName: item.apaterno,
                        motherName: item.amaterno,
                        curp: item.curp,
                        email: item.email
                    }
                })

                companyForm.setFieldValue( 'legalRepresentatives', newLegals )
            }

        } else {
            companyForm.setFieldValue( 'legalRepresentatives', [{
                name: "",
                phaterName: "",
                motherName: "",
                curp: "",
                email: ""
            }])
        }

        // eslint-disable-next-line
    }, [ selectedOwnerCompany ] )

    const handleCloseModal = () => {
        deselectOwnerCompany()
        companyForm.resetForm()
        setSelectedServices( [] )
    }

    const handleAddRepresentative = () => {
        const newItem = {
            name: "",
            phaterName: "",
            motherName: "",
            curp: "",
            email: ""
        }

        companyForm.setFieldValue( 'legalRepresentatives', [
            ...companyForm.values.legalRepresentatives,
            newItem
        ])
    }

    const handleRemoveRepresentative = ( index: number ) => {
        const updatedItem = companyForm.values.legalRepresentatives.filter(
            ( _, i ) => i !== index
        )

        companyForm.setFieldValue( 'legalRepresentatives', updatedItem )
    }

    const generateRequest = () => {
        const request = {
            id: selectedCompany ? selectedCompany.id : null,
            employeesSize: Number( companyForm.values.employeesNumber ),
            legalRepresentatives: companyForm.values.legalRepresentatives.map( item => {
                return {
                    amaterno: item.motherName,
                    apaterno: item.phaterName,
                    curp: item.curp,
                    name: item.name,
                    email: item.email
                }
            }),
            name: companyForm.values.name,
            rfc: companyForm.values.rfc,
            sector: companyForm.values.sector,
            web: companyForm.values.web,
            servicesOffered: selectedServices.map( item => item.name).join('|'),
            taxResidence: {
                city: companyForm.values.city,
                colonia: companyForm.values.neighborhood,
                cp: companyForm.values.zipCode,
                interiorNumber: companyForm.values.interiorNumber,
                outdoorNum: companyForm.values.exteriorNumber,
                street: companyForm.values.street,
                country: companyForm.values.country
            },
            type: companyForm.values.taxRegime,
            enable: true
        } as UpdateCompany

        if ( !selectedCompany ) {
            const representative = companyForm.values.legalRepresentatives[0]

            request.user = {} as any
            request.user.username = representative.email
            request.user.name = representative.name
            request.user.rfc = representative.curp
            request.user.email = representative.email
            request.user.roles = [ "Admin" ]
            request.user.active = true
        } else {
            if ( selectedCompany.user ) {
                request.user = {} as any
                request.user.username = selectedCompany.user.username
                request.user.name = selectedCompany.user.name
                request.user.rfc = selectedCompany.user.rfc
                request.user.email = selectedCompany.user.email
                request.user.roles = selectedCompany.user.roles
                request.user.active = true
            }
        }

        return request
    }

    const createOrEditCompany = () => {
        const legalRepresentative = companyForm.values.legalRepresentatives[0]

        if ( !legalRepresentative?.curp
            || !legalRepresentative?.email
            || !legalRepresentative?.motherName
            || !legalRepresentative?.phaterName
            || !legalRepresentative?.name
        ) {
            setIsLegalRepresentativeError( true )
            return
        }

        selectedCompany
            ? handleUpdateCompany()
            : handleCreateCompany()
    }

    const handleUpdateCompany = () => {
        const body = generateRequest()

        setIsLegalRepresentativeError( false )

        updateCompany( selectedOwnerCompany!, body ).then(
            () => {
                toast.success( 'Empresa actualizada correctamente' )
                closeModal( 'createOrEditCompanyModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al actualizar la empresa' )
        )
    }

    const handleCreateCompany = () => {
        const body = generateRequest()

        setIsLegalRepresentativeError( false )

        createCompany( selectedOwnerCompany?.id!, body ).then(
            () => {
                toast.success( 'Empresa creada correctamente' )
                closeModal( 'createOrEditCompanyModal' )
            }
        ).catch(
            () => () => toast.error( 'Ocurrió un error al crear la empresa' )
        )
    }

    const companyForm = useFormik({
        initialValues: {
            // General
            name: '',
            taxRegime: '',
            rfc: '',
            web: '',
            sector: '',
            employeesNumber: '',
            servicesOffered: [{
                id: '',
                name: ''
            }],
            // Address
            street: '',
            exteriorNumber: '',
            interiorNumber: '',
            neighborhood: '',
            city: '',
            zipCode: '',
            country: '',
            // Legal Representative
            legalRepresentatives: [
                {
                    name: "",
                    phaterName: "",
                    motherName: "",
                    curp: "",
                    email: ""
                }
            ]
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required( "Este campo es requerido" ),
            taxRegime: Yup.string().required( "Este campo es requerido" ),
            rfc: Yup.string().required( "Este campo es requerido" )
                .matches( regExpRFC, "El RFC no es válido" ),
            sector: Yup.string().required( "Este campo es requerido" )
        }),
        onSubmit: createOrEditCompany
    })

    return (
        <CustomModal id="createOrEditCompanyModal"
            size="6xl"
            icon={FcTreeStructure}
            title={modalTitle}
            acceptText={selectedOwnerCompany ? 'Actualizar empresa' : 'Guardar empresa'}
            closeOnAccept={false}
            onCloseComplete={handleCloseModal}
            onAccept={companyForm.handleSubmit}
        >
            {() => (
                <form onSubmit={companyForm.handleSubmit}>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={{ base: 12, md: 6 }}>
                            {/* Company data */}
                            <BrandBox className="p-0">
                                <HeadModule title="Datos de la empresa" />
                                <Box overflow="auto" maxH="20rem" px={2}>
                                    {/* Name */}
                                    <FormControl isInvalid={!!companyForm.errors.name}>
                                        <label htmlFor="name" className="text-sm leading-8">
                                            Nombre
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.name}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.name}</FormErrorMessage>
                                    </FormControl>
                                    {/* Tax regime */}
                                    <FormControl isInvalid={!!companyForm.errors.taxRegime}>
                                        <label className="text-sm leading-8">
                                            Régimen fiscal
                                        </label>
                                        <Select
                                            name="taxRegime"
                                            defaultValue="moral"
                                            onChange={companyForm.handleChange}>
                                            <option value="moral">Personal moral</option>
                                            <option value="fisica">Personal física</option>
                                        </Select>
                                        <FormErrorMessage>{companyForm.errors.taxRegime}</FormErrorMessage>
                                    </FormControl>
                                    {/* RFC */}
                                    <FormControl isInvalid={!!companyForm.errors.rfc}>
                                        <label htmlFor="rfc" className="text-sm leading-8">
                                            RFC
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="rfc"
                                                name="rfc"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.rfc}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.rfc}</FormErrorMessage>
                                    </FormControl>
                                    {/* Website */}
                                    <FormControl isInvalid={!!companyForm.errors.web}>
                                        <label htmlFor="web" className="text-sm leading-8">
                                            Sitio Web
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="web"
                                                name="web"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.web}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.web}</FormErrorMessage>
                                    </FormControl>
                                    {/* Sector */}
                                    <FormControl isInvalid={!!companyForm.errors.sector}>
                                        <label className="text-sm leading-8">
                                            Sector al que pertenece
                                        </label>
                                        <Select
                                            value={companyForm.values.sector}
                                            name="sector"
                                            placeholder="Sector al que pertenece"
                                            onChange={companyForm.handleChange}>
                                            {/* onChange={e => { companyForm.setFieldValue( "sector", e.target.value ) }}> */}
                                            {Activites.map(( item, idx ) => (
                                                <option key={idx} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{companyForm.errors.sector}</FormErrorMessage>
                                    </FormControl>
                                    {/* Website */}
                                    <FormControl isInvalid={!!companyForm.errors.employeesNumber}>
                                        <label htmlFor="employeesNumber" className="text-sm leading-8">
                                            Número de empleados
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="employeesNumber"
                                                name="employeesNumber"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.employeesNumber}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.employeesNumber}</FormErrorMessage>
                                    </FormControl>
                                    {/* Offered services */}
                                    <FormControl className="w-full">
                                        <label className="text-sm leading-8">
                                            Servicios ofrecidos
                                        </label>
                                        <MultiSelect
                                            options={Services}
                                            selectedOptions={selectedServices}
                                            keyProp="id"
                                            displayProp="name"
                                            placeholder="Selecciona los servicios"
                                            onSelect={( items ) => {
                                                setSelectedServices( items )
                                                companyForm.setFieldValue( 'servicesOffered', [] )
                                                companyForm.setFieldValue( 'servicesOffered', items )
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </BrandBox>
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 6 }}>
                            {/* Fiscal Address company */}
                            <BrandBox className="p-0">
                                <HeadModule title="Domicilio fiscal" />
                                <Box overflow="auto" maxH="20rem" px={2}>
                                    {/* Street */}
                                    <FormControl isInvalid={!!companyForm.errors.street}>
                                        <label htmlFor="street" className="text-sm leading-8">
                                            Calle
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="street"
                                                name="street"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.street}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.street}</FormErrorMessage>
                                    </FormControl>
                                    {/* Exterior number */}
                                    <FormControl isInvalid={!!companyForm.errors.exteriorNumber}>
                                        <label htmlFor="exteriorNumber" className="text-sm leading-8">
                                            Número exterior
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="exteriorNumber"
                                                name="exteriorNumber"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.exteriorNumber}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.exteriorNumber}</FormErrorMessage>
                                    </FormControl>
                                    {/* Interior number */}
                                    <FormControl isInvalid={!!companyForm.errors.interiorNumber}>
                                        <label htmlFor="interiorNumber" className="text-sm leading-8">
                                            Número interior
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="interiorNumber"
                                                name="interiorNumber"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.interiorNumber}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.interiorNumber}</FormErrorMessage>
                                    </FormControl>
                                    {/* Neighborhood */}
                                    <FormControl isInvalid={!!companyForm.errors.neighborhood}>
                                        <label htmlFor="neighborhood" className="text-sm leading-8">
                                            Colonia
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="neighborhood"
                                                name="neighborhood"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.neighborhood}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.neighborhood}</FormErrorMessage>
                                    </FormControl>
                                    {/* City */}
                                    <FormControl isInvalid={!!companyForm.errors.city}>
                                        <label htmlFor="city" className="text-sm leading-8">
                                            Ciudad
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="city"
                                                name="city"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.city}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.city}</FormErrorMessage>
                                    </FormControl>
                                    {/* Zip code */}
                                    <FormControl isInvalid={!!companyForm.errors.zipCode}>
                                        <label htmlFor="zipCode" className="text-sm leading-8">
                                            Código Postal
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="zipCode"
                                                name="zipCode"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.zipCode}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.zipCode}</FormErrorMessage>
                                    </FormControl>
                                    {/* Country */}
                                    <FormControl isInvalid={!!companyForm.errors.country}>
                                        <label htmlFor="country" className="text-sm leading-8">
                                            País
                                        </label>
                                        <InputGroup>
                                            <Input
                                                id="country"
                                                name="country"
                                                type="text"
                                                onChange={companyForm.handleChange}
                                                value={companyForm.values.country}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{companyForm.errors.country}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            </BrandBox>
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 12 }}>
                            {/* Legal Representatives */}
                            <BrandBox className="p-0">
                                <HeadModule title="Representantes legales"
                                    handleAction={handleAddRepresentative}
                                    actionText="Agregar"
                                />
                                {companyForm.values.legalRepresentatives.length > 0 && (
                                    <Box overflow="auto" maxH="20rem" px={2} pb={2}>
                                        {companyForm.values.legalRepresentatives?.map(( item, i ) => (
                                            <Grid key={i} templateColumns="repeat(11, 1fr)" gap={4}>
                                                <GridItem colSpan={{ base: 11, md: 2 }}>
                                                    {/* Legal name */}
                                                    <FormControl>
                                                        <label htmlFor="name" className="text-sm leading-8">
                                                            Nombre(s)
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                id={`legalRepresentatives[${i}].name`}
                                                                name={`legalRepresentatives[${i}].name`}
                                                                type="text"
                                                                onChange={companyForm.handleChange}
                                                                value={item.name}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 11, md: 2 }}>
                                                    {/* Legal Pather name */}
                                                    <FormControl>
                                                        <label htmlFor="phaterName" className="text-sm leading-8">
                                                            Apellido paterno
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                id={`legalRepresentatives[${i}].phaterName`}
                                                                name={`legalRepresentatives[${i}].phaterName`}
                                                                type="text"
                                                                onChange={companyForm.handleChange}
                                                                value={item.phaterName}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 11, md: 2 }}>
                                                    {/* Legal Mother name */}
                                                    <FormControl>
                                                        <label htmlFor="motherName" className="text-sm leading-8">
                                                            Apellido materno
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                id={`legalRepresentatives[${i}].motherName`}
                                                                name={`legalRepresentatives[${i}].motherName`}
                                                                type="text"
                                                                onChange={companyForm.handleChange}
                                                                value={item.motherName}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 11, md: 2 }}>
                                                    {/* Legal CURP */}
                                                    <FormControl>
                                                        <label htmlFor="curp" className="text-sm leading-8">
                                                            CURP
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                id={`legalRepresentatives[${i}].curp`}
                                                                name={`legalRepresentatives[${i}].curp`}
                                                                type="text"
                                                                onChange={companyForm.handleChange}
                                                                value={item.curp}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 11, md: 2 }}>
                                                    {/* Legal Email */}
                                                    <FormControl>
                                                        <label htmlFor="email" className="text-sm leading-8">
                                                            Correo
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                id={`legalRepresentatives[${i}].email`}
                                                                name={`legalRepresentatives[${i}].email`}
                                                                type="text"
                                                                onChange={companyForm.handleChange}
                                                                value={item.email}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 9, md: 1 }}
                                                    display="flex"
                                                    alignItems="end"
                                                    justifyContent="end"
                                                >
                                                    {/* Remove legal */}
                                                    <Box pb={1}>
                                                        <ActionIcon label="Quitar representante" icon={TbTrash} onHandle={() => handleRemoveRepresentative( i )} />
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        ))}
                                        {isLegalRepresentativeError && (
                                            <small className="text-red-500">
                                                Al menos debe indicar los datos de un representante
                                            </small>
                                        )}
                                    </Box>
                                )}
                            </BrandBox>
                        </GridItem>
                    </Grid>
                </form>
            )}
        </CustomModal>
    )
}

export default CreateOrEditCompanyModal