import { Box, Button, IconButton, Tab, TabIndicator, TabList, Tabs, Tooltip } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { TbSettings2 } from "react-icons/tb"
import { useModalStore } from "@/core/stores/modal.store"
import CleanPlanningStore from "@/modules/planning/utils/clean-planning-store.utils"
import SubSectionsModal from "@/modules/planning/components/SubSectionsModal"

const FlowTabs: React.FC = () => {
    const campaignSubSection =  [
      {
          name: "Resumen",
          type: "SUMMARY",
          enable: true
      },
      {
          name: "TVA",
          type: "TVA",
          enable: true
      },
      {
          name: "PTV",
          type: "PTV",
          enable: true
      },
      {
        name: "TVL",
        type: "TVL",
        enable: true
    },
    {
        name: "Radio",
        type: "RADIO",
        enable: true
    },
    {
        name: "Estadios",
        type: "STADIUM",
        enable: true
    },
    {
        name: "OOH",
        type: "OOH",
        enable: true
    },
    {
        name: "Prensa",
        type: "PRESS",
        enable: true
    },
    {
        name: "Digital",
        type: "DIGITAL",
        enable: true
    },
    ]

    const openModal = useModalStore(e => e.openModal)
    /** */
    const navigate = useNavigate()
    const location = useLocation()

    const tabIndex: { [key: string]: number } = {
        '/views/campaigns/detail/flow/summary': 0,
        '/views/campaigns/detail/flow/tva': 1,
        '/views/campaigns/detail/flow/tva/compare': 1,
        '/views/campaigns/detail/flow/ptv': 2,
        '/views/campaigns/detail/flow/ptv/compare': 2,
        '/views/campaigns/detail/flow/tvl': 3,
        '/views/campaigns/detail/flow/tvl/compare': 3,
        '/views/campaigns/detail/flow/radio': 4,
        '/views/campaigns/detail/flow/radio/compare': 4,
        '/views/campaigns/detail/flow/stadium': 5,
        '/views/campaigns/detail/flow/stadium/compare': 5,
        '/views/campaigns/detail/flow/ooh': 6,
        '/views/campaigns/detail/flow/ooh/compare': 6,
        '/views/campaigns/detail/flow/press': 7,
        '/views/campaigns/detail/flow/press/compare': 7,
        '/views/campaigns/detail/flow/digital': 8,
        '/views/campaigns/detail/flow/digital/compare': 8,
    }

    const currentIndex = tabIndex[location.pathname] ?? 0

    const handleTabsChange = (index: number) => {
        const paths = [
            '/views/campaigns/detail/flow/summary',
            '/views/campaigns/detail/flow/tva',
            '/views/campaigns/detail/flow/ptv',
            '/views/campaigns/detail/flow/tvl',
            '/views/campaigns/detail/flow/radio',
            '/views/campaigns/detail/flow/stadium',
            '/views/campaigns/detail/flow/ooh',
            '/views/campaigns/detail/flow/press',
            '/views/campaigns/detail/flow/digital'
        ]
        CleanPlanningStore()
        navigate(paths[index])
    }

    return (
        <>
            <Box display={'flex'} mb={3}>
                <Tabs index={currentIndex}
                    variant='unstyled'
                    position='relative'
                    colorScheme="brand"
                    onChange={handleTabsChange}
                >
                    <TabList>
                        {campaignSubSection?.map((e) => (
                            e.enable &&
                            <Tab key={e.type} className="flex items-center gap-1">
                                <span>{e.name}</span>
                            </Tab>
                        ))}
                    </TabList>
                    <TabIndicator />
                </Tabs>
                <Tooltip label="Gestionar sub secciones">
                    <Button className="ml-4"
                        as={IconButton}
                        icon={<TbSettings2 size={20} />}
                        variant="ghost"
                        size="sm"
                        onClick={() => openModal('SubSectionsModal')}
                    />
                </Tooltip>
            </Box>
            <SubSectionsModal />
        </>
    )
}

export default FlowTabs