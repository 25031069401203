import { useFormik } from "formik";
import CustomModal from "../../../core/components/organism/CustomModal"
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Planning } from "@/core/models/planning.model";
import useCustomToast from "@/core/hooks/useCustomToast";
import { useModalStore } from "@/core/stores/modal.store";
import { useNavigationStore } from "@/core/stores/navigation.store";
import { FcPlanner } from "react-icons/fc";
import { usePlanningStore } from "../store/planning.store";

const AddRoadMapModal: React.FC = () => {
  /** */
  const { retrievePlanningItems, updateRoadMapPlanningItem, selectedPlanning } = usePlanningStore();
  const toast = useCustomToast()
  const closeModal = useModalStore(s => s.closeModal)
  const sectionId = useNavigationStore(e => e.selectedNavigation)

  const createRoadMap = () => {

    const roadMapList = [
      ...(selectedPlanning?.roadMapList ?? []),
      {
        name: roadmapForm.values.name
      }
    ];

    updateRoadMapPlanningItem(selectedPlanning?.id ?? '', roadMapList).then(
      () => {
        toast.success('Roadmap creado correctamente')
        roadmapForm.resetForm()
        retrievePlanningItems(sectionId?.id!)
        closeModal('addRoadMapModal')
      }
    ).catch(
      () => toast.error('Ocurrió un error al crear el Roadmap')
    )
  }
  const roadmapForm = useFormik<Planning>({
    onSubmit: createRoadMap,
    validateOnBlur: true,
    initialValues: { name: 'Roadmap' }
  })
  return (
    <CustomModal id="addRoadMapModal" title="Crear Roadmap" size="md" withButtons={true} showCancelButton={true}
      onCancel={() => console.log('Cancelar')}
      onAccept={roadmapForm.handleSubmit}
      cancelText="Cancelar"
      acceptText="Crear roadmap"
      icon={FcPlanner}
    >
      {() => (
        <form onSubmit={roadmapForm.handleSubmit}>
          <FormControl isInvalid={!!roadmapForm.errors.name}>
            <FormLabel>Nombre de roadmap</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EditIcon color="gray.300" />
              </InputLeftElement>
              <Input id="name" type="text" placeholder="Nombre roadmap"
                onChange={roadmapForm.handleChange}
                value={roadmapForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{roadmapForm.errors.name}</FormErrorMessage>
          </FormControl>
        </form>
      )}
    </CustomModal>
  )
}

export default AddRoadMapModal