import CustomModal from "@/core/components/organism/CustomModal"
import { FcBusinessContact } from "react-icons/fc"
import { useAdminUserStore } from "../../stores/admin-user.store"
import { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, FormControl, FormErrorMessage, Grid, GridItem, Input, InputGroup, Select, useBoolean } from "@chakra-ui/react"
import MultiSelect from "@/core/components/molecules/MultiSelect"
import { useAdminRoleStore } from "../../stores/admin-role.store"
import { Role } from "../../models/role.model"
import FileDropzone from "@/core/components/organism/FileDropzone"
import { FilesUtil } from "@/core/utils/files.util"
import { UtilService } from "@/core/services/util.service"
import useCustomToast from "@/core/hooks/useCustomToast"
import { AdminUser } from "../../models/admin-user.model"
import { App } from "@/core/enums/app.enum"
import { useUserStore } from "@/modules/auth/stores/user.store"
import { useModalStore } from "@/core/stores/modal.store"

const CreateOrEditUserModal: React.FC = () => {
    /** Services */
    const utilService = new UtilService()
    /** */
    const selectedUser = useAdminUserStore( s => s.selectedUser )
    const { addUser, addExternalUser, updateUser, retrieveUserById } = useAdminUserStore()
    const { deselectedUser } = useAdminUserStore()
    const closeModal = useModalStore( s => s.closeModal )
    const modals = useModalStore( s => s.modals )
    const [ modalTitle, setModalTitle ] = useState<string>( '' )
    const roles = useAdminRoleStore( s => s.roles )
    const [ selectedRoles, setSelectedRoles ] = useState<Role[]>([])
    const [ file, setFile ] = useState<FormData | undefined>( undefined )
    const [ fileExtension, setFileExtension ] = useState<string>( '' )
    const [ userType, setUsertype ] = useState<string>( 'internal' )
    const toast = useCustomToast()
    const companyData = useUserStore( s => s.companyData )
    const [ isChangeImage, setIsChangeImage ] = useBoolean( false )

    const createOrEditUserWithFile = () => {
        if ( !file ) {
            selectedUser
                ? handleEdit( undefined, selectedUser?.id! )
                : handleCreate()
        } else {
            utilService.uploadFile( file! ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const fileId: string = response.respuesta
                        const userId: string = selectedUser?.id!

                        selectedUser
                            ? handleEdit( fileId, userId )
                            : handleCreate( fileId )
                    }
                }
            ).catch(
                () => toast.error( 'Ocurrió un error al subir el archivo' )
            )
        }
    }

    function generateRequest( fileId?: string, userId?: string ) {
        return {
            id: userId || null,
            name: userForm.values.name,
            lastName: userForm.values.lastName,
            email: userForm.values.email,
            idApp: App.ID,
            image: selectedUser?.image || `image/${fileExtension}`,
            image64: selectedUser ? selectedUser.image64 : fileId || null,
            phone: selectedUser ? selectedUser.phone : '+52-',
            rfc: '',
            companies: [ companyData?.id! ],
            status: true,
            username: userForm.values.email,
            roles: selectedRoles.map( item => item.id )
        } as AdminUser
    }

    const handleCreate = ( fileId?: string ) => {
        const body = generateRequest( fileId )

        if ( userType === 'internal' ) {
            addUser( body ).then(
                () => {
                    toast.success( 'Usuario agregado correctamente' )
                    closeModal( 'createOrEditUserModal' )
                }
            ).catch(
                () => toast.error( 'Ocurrió un error al agregar el usuario' )
            )
        } else if ( userType === 'external' ) {
            addExternalUser( body ).then(
                () => {
                    toast.success( 'Usuario agregado correctamente' )
                    closeModal( 'createOrEditUserModal' )
                }
            ).catch(
                () => toast.error( 'Ocurrió un error al agregar el usuario' )
            )
        }

    }

    const handleEdit = ( fileId?: string, userId?: string ) => {
        const body = generateRequest( fileId, userId )

        updateUser( body ).then(
            () => {
                toast.success( 'Usuario actualizado correctamente' )
                closeModal( 'createOrEditUserModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al actualizar el usuario' )
        )
    }

    const handleCloseModal = () => {
        deselectedUser()
        setSelectedRoles([])
        userForm.resetForm()
        setFile( undefined )
        setFileExtension( '' )
        setIsChangeImage.off()
    }

    const userForm = useFormik({
        initialValues: {
            userType: '',
            name: '',
            lastName: '',
            email: ''
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required( "Este campo es requerido" ),
            lastName: Yup.string().required( "Este campo es requerido" ),
            email: Yup.string().required( "Este campo es requerido" )
                .email( "El correo capturado no es válido" )
        }),
        onSubmit: createOrEditUserWithFile
    })

    useEffect( () => {
        const isOpen = modals['createOrEditUserModal']?.isOpen
        /** Se consulta de nuevo el usuario
         * Para recuperar la imagen y reducir el costo en la consulta general
         */
        if ( selectedUser && isOpen ) {
            retrieveUserById( selectedUser.id )
        }

        // eslint-disable-next-line
    }, [modals['createOrEditUserModal']?.isOpen])

    useEffect( () => {
        let title = selectedUser ? 'Editar usuario' : 'Agregar usuario'
        title += ` ${selectedUser ? selectedUser.external ? '(Externo)' : '(Interno)' : ''}`

        setModalTitle( title )

        if ( selectedUser ) {
            userForm.setFieldValue( 'name', selectedUser.name )
            userForm.setFieldValue( 'lastName', selectedUser.lastName )
            userForm.setFieldValue( 'email', selectedUser.email )

            if ( selectedUser.roles ) {
                const filteredRoles = roles.filter( role =>
                    selectedUser.roles.some(
                        userRole => userRole === role.id
                    )
                )

                setSelectedRoles( filteredRoles )
            }
        } else {
            userForm.setFieldValue( 'name', '' )
            userForm.setFieldValue( 'lastName', '' )
            userForm.setFieldValue( 'email', '' )
        }
        // eslint-disable-next-line
    }, [selectedUser])

    const handleSelect = ( selectedValues: any ) => {
        setSelectedRoles( selectedValues )
    }

    const handleImageUpload = ( file: File ) => {
        const formData = new FormData()
        const extension = FilesUtil.extractFileExtension( file )

        formData.append( "file", file )

        setFileExtension( extension )

        setFile( formData ) 
    }

    const getBase64Img = ( user: AdminUser ) => {
        if ( !user.image64 ) return ''

        if ( !user.image64.startsWith( 'data:' ) ) {
            return `data:${user.image};base64,${user?.image64}`
        }

        return user.image64
    }

    return (
        <CustomModal id="createOrEditUserModal"
            size="xl"
            icon={FcBusinessContact}
            title={modalTitle}
            acceptText={selectedUser ? 'Actualizar usuario' : 'Guardar usuario'}
            closeOnAccept={false}
            onCloseComplete={handleCloseModal}
            onAccept={userForm.handleSubmit}
        >
            {() => (
                <form onSubmit={userForm.handleSubmit} className="flex flex-col gap-2">
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={{ base: 12, md: 6 }}>
                            {/* Nombre usuario */}
                            <FormControl isInvalid={!!userForm.errors.name}>
                                <label htmlFor="name" className="text-sm leading-8">
                                    Nombre
                                </label>
                                <InputGroup>
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={userForm.handleChange}
                                        value={userForm.values.name}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{userForm.errors.name}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 6 }}>
                            {/* Apellido usuario */}
                            <FormControl isInvalid={!!userForm.errors.lastName}>
                                <label htmlFor="lastName" className="text-sm leading-8">
                                    Apellido
                                </label>
                                <InputGroup>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        onChange={userForm.handleChange}
                                        value={userForm.values.lastName}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{userForm.errors.lastName}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    {/* Email */}
                    <FormControl isInvalid={!!userForm.errors.email}>
                        <label htmlFor="email" className="text-sm leading-8">
                            Correo
                        </label>
                        <InputGroup>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                onChange={userForm.handleChange}
                                value={userForm.values.email}
                            />
                        </InputGroup>
                        <FormErrorMessage>{userForm.errors.email}</FormErrorMessage>
                    </FormControl>
                    {/* Tipo de usuario */}
                    {!selectedUser && (
                        <FormControl className="w-full">
                            <label className="text-sm leading-8">
                                Tipo de usuario
                            </label>
                            <Select
                                value={userType}
                                onChange={( e ) => setUsertype( e.target.value )}>
                                <option value='internal'>Interno</option>
                                <option value='external'>Externo</option>
                            </Select>
                        </FormControl>
                    )}
                    {/* Selección de roles */}
                    <FormControl className="w-full">
                        <label className="text-sm leading-8">
                            Rol
                        </label>
                        <MultiSelect
                            options={roles}
                            selectedOptions={selectedRoles}
                            keyProp="id"
                            displayProp="name"
                            placeholder="Selecciona los roles"
                            onSelect={handleSelect}
                        />
                    </FormControl>
                    {/* Imagen de perfil */}
                    <FormControl className="w-full">
                        <label className="text-sm leading-8">
                            Imagen de perfil
                        </label>
                        {selectedUser?.image64 && !isChangeImage ? (
                            <div className="w-full flex flex-col items-center gap-4">
                                <Button variant="outline"
                                    onClick={() => setIsChangeImage.on()}>
                                    Cambiar imagen
                                </Button>
                                <img className="max-h-48"
                                    alt={selectedUser.fullName}
                                    src={getBase64Img( selectedUser )}
                                />
                            </div>
                        ) : (
                            <FileDropzone
                                accept="image/*"
                                onDropFile={handleImageUpload}
                            />
                        )}
                    </FormControl>
                </form>
            )}
        </CustomModal>
    )
}

export default CreateOrEditUserModal