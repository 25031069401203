import CustomModal from "@/core/components/organism/CustomModal"
import FileDropzone from "@/core/components/organism/FileDropzone"
import { useModalStore } from "@/core/stores/modal.store"
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, useBoolean } from "@chakra-ui/react"
import { useFormik } from "formik"
import { FcUpload } from "react-icons/fc"
import { useState } from "react"
import { FilesUtil } from "@/core/utils/files.util"
import { useConciliationStore } from "../stores/conciliation.store"
import { WitnessesProviders } from "../models/conciliation.model"

interface LoadProps {
  setSelectedFile?: (e: File | null) => void
  accept?: string
}

const ConciliationLoadFileModal: React.FC<LoadProps> = ({ setSelectedFile, accept }) => {
  const closeModal = useModalStore(s => s.closeModal)
  const [hasErrorFile, setHasErrorFile] = useBoolean(false)
  const { selectedWitnessesMonth, witnessesProviders, selectedWitnessesProvider, setSelectedWitnesses, setSelectedWitnessesMonth, setSelectedWitnessesProvider } = useConciliationStore();
  const [file, setFile] = useState<File | null>(null)

  const handleBudgetFile = () => {
    if (file === null) {
      setHasErrorFile.on()
      return
    }
    setHasErrorFile.off()
    const witnessesProv = witnessesProviders.filter((e) => e.provider === selectedWitnessesProvider?.provider)[0]
    const witnesesMon = witnessesProv.evidences?.filter((e) => e.month === selectedWitnessesMonth?.month)[0]

    const extension = FilesUtil.extractFileExtension(file)
    FilesUtil.fileToBase64(file!)
      .then(base64 => {
        const img = `data:${extension};base64,${base64}`;
        const data = {
          file: img,
          name: loadFileForm.values.name,
          id: loadFileForm.values.id,
          type: 'image'
        }
        const newData = [
          ...witnesesMon?.files!,
          data
        ]
        /* {
          ...witnesesMon,
          files: newData
        } */
        const witMon = witnessesProv.evidences?.map((e) => e.month === witnesesMon?.month ? {
          ...witnesesMon,
          files: newData
        } : e)
        const witProv = {
          ...witnessesProv,
          evidences: witMon
        }
        const finalData = witnessesProviders.map((e) => e.provider === witProv.provider ? witProv : e)
        setSelectedWitnesses(finalData as WitnessesProviders[])
        setSelectedWitnessesProvider(witnessesProv.provider!)
        setSelectedWitnessesMonth(witnesesMon?.month!)
        
      });
    /* setSelectedFile(file) */
    closeModal('ConciliationLoadFileModal')
  }

  const loadFileForm = useFormik({
    onSubmit: handleBudgetFile,
    validateOnBlur: true,
    initialValues: {
      name: '',
      id: ''
    }
  })

  const handleFileUpload = (file: File) => {
    setFile(file);
  }

  return (
    <CustomModal id="ConciliationLoadFileModal"
      title="Cargar documento"
      size="xl"
      icon={FcUpload}
      showCancelButton={false}
      onAccept={loadFileForm.handleSubmit}
      closeOnAccept={false}
      acceptText="Cargar">
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FormControl isInvalid={!!loadFileForm.errors.name} mb={1}>
            <FormLabel fontWeight={'normal'}>Nombre</FormLabel>
            <InputGroup>
              <Input id="name" type="text" placeholder="Nombre"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.id}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!loadFileForm.errors.id} mb={1}>
            <FormLabel fontWeight={'normal'}>ID</FormLabel>
            <InputGroup>
              <Input id="id" type="text" placeholder="ID"
                onChange={loadFileForm.handleChange}
                value={loadFileForm.values.id}
              />
            </InputGroup>
            <FormErrorMessage>{loadFileForm.errors.id}</FormErrorMessage>
          </FormControl>
          <FileDropzone accept={accept === 'pdf' ? 'application/pdf' : accept === 'image' ? 'image/*' : '*'} onDropFile={handleFileUpload} />
          {hasErrorFile && (
            <span className="text-red-500">
              El archivo es requerido
            </span>
          )}
        </form>
      )}
    </CustomModal>
  )
}

export default ConciliationLoadFileModal