import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import chakraTheme from '@/theme/chakra-theme'
import Router from '@/routes/Router'
import Loader from '@/core/components/molecules/Loader'
import RestrictedActionModal from '@/core/components/organism/RestrictedActionModal'

const App: React.FC = () => {
  return (
    <section className='app'>
      <ChakraProvider theme={chakraTheme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        {/* Modal de restricción de acceso */}
        <RestrictedActionModal />
        {/* Loader para peticiones http */}
        <Loader />
      </ChakraProvider>
    </section>
  )
}

export default App
