import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CommentsPanel from './CommentsPanel'
import { useCampaignStore } from '../store/campaign.store';

function StrategyPage() {
  const selectedCampaign = useCampaignStore(e => e.selectedCampaign)
  const [pdfSrc, setPdfSrc] = useState<string | null>(null);
  /* useEffect(() => {
    console.log(selectedCampaign);
    if (selectedCampaign?.strategyPdf !== null) {
      const objectUrl = URL.createObjectURL(selectedCampaign?.strategyPdf as File);
      setPdfSrc(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedCampaign]) */
  return (
    <Flex>
      <Box w={'70%'}>
        {
          selectedCampaign?.strategyPdf && (
            <object type="application/pdf" data={selectedCampaign?.strategyPdf} width="100%" height="100%">
              <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={selectedCampaign?.strategyPdf}>Descargar PDF</a>.</p>
            </object>
          )
        }
      </Box>
      <CommentsPanel />
    </Flex>
  )
}

export default StrategyPage