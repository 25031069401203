import { StateCreator, create } from "zustand";
import { Company } from "../../admin/models/company";
import { User } from "../models/user";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { SecureSessionStorage } from "@/core/stores/secure-sessionstorage.store";
    
/** Contrato del Store */
interface UserState {
    userData?: User
    companyData?: Company
    setUserData: ( data: User ) => void
    setCompanyData: ( data: Company ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    userData: undefined,
    companyData: undefined
}

/** Definición del Store */
const Store: StateCreator<UserState> = ( set ) => ({

    ...initialState,

    setUserData: ( data: User ) => set({ userData: data }),

    setCompanyData: ( data: Company ) => set({ companyData: data }),

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useUserStore = create<UserState>()(
    persist(
        immer( Store ),
        {
            name: 'user-storage',
            storage: SecureSessionStorage
        }
    )
)