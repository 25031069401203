interface BrandBadgeProps {
    label: string
    color?: 'default' | 'green' | 'blue' | 'yellow' | 'red' | 'gray' | 'purple'
}

const BrandBadge: React.FC<BrandBadgeProps> = ({ label, color }) => {
    /** */
    const defaultClasses = 'w-fit flex items-center justify-center rounded-full px-2'

    const classesBadgeColor = () => {
        switch ( color ) {
            case 'green':
                return 'bg-green-200 text-green-700'
            case 'blue':
                return 'bg-blue-100 text-blue-700'
            case 'yellow':
                return 'bg-yellow-100 text-yellow-700'
            case 'red':
                return 'bg-red-200 text-red-700'
            case 'gray':
                return 'bg-gray-200 text-gray-700'
            case 'purple':
                return 'bg-purple-200 text-purple-700'
            case 'default':
            default:
                return 'bg-brand-100 text-brand-700'
        }
    }

    return (
        <div className={`${defaultClasses} ${classesBadgeColor()}`}>
            <small className="font-bold">
                { label }
            </small>
        </div>
    )
}

export default BrandBadge