import { Navigate, RouteObject } from "react-router-dom"
import BriefPage from "../../components/BriefPage"
import StrategyPage from "../../components/StrategyPage"
import CoverPage from "../../components/CoverPage"
import CampaignSectionRoutes from "./flow-section/FlowSection.routes"
import FlowSectionLayout from "./flow-section/FlowSectionLayout"

const PlanningSectionRoutes: RouteObject[] = [
    { path: 'cover', element: <CoverPage /> },
    { path: 'brief', element: <BriefPage /> },
    { path: 'strategy', element: <StrategyPage /> },
    {
        path: 'flow',
        element: <FlowSectionLayout />, 
        children: [
          { index: true, element: <Navigate to="summary" replace /> },
          ...CampaignSectionRoutes
        ]
      },
]

export default PlanningSectionRoutes