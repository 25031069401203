import { Box, Button, IconButton, Tab, TabIndicator, TabList, Tabs, Tooltip } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { TbSettings2 } from "react-icons/tb"
import { useModalStore } from "@/core/stores/modal.store"
import { usePlanningStore } from "@/modules/planning/store/planning.store"
import CleanPlanningStore from "@/modules/planning/utils/clean-planning-store.utils"
import SubSectionsModal from "@/modules/planning/components/SubSectionsModal"

const ConciliationTabs: React.FC = () => {
    const campaignSubSection = [
        {
            name: "Testigos",
            type: "WITNESSES",
            enable: true
        },
        {
            name: "Órdenes de compra",
            type: "BUYORDER",
            enable: true
        },
        {
            name: "Carta de conciliación",
            type: "CONCILIATIONLETTER",
            enable: true
        },
        {
            name: "Facturación",
            type: "BILLING",
            enable: true
        },
    ]

    const openModal = useModalStore(e => e.openModal)
    /** */
    const navigate = useNavigate()
    const location = useLocation()

    const tabIndex: { [key: string]: number } = {
        '/views/reconciliations/detail/witnesses': 0,
        '/views/reconciliations/detail/buy-order': 1,
        '/views/reconciliations/detail/conciliation-letter': 2,
        '/views/reconciliations/detail/billing': 3,
    }

    const currentIndex = tabIndex[location.pathname] ?? 0

    const handleTabsChange = (index: number) => {
        const paths = [
            '/views/reconciliations/detail/witnesses',
            '/views/reconciliations/detail/buy-order',
            '/views/reconciliations/detail/conciliation-letter',
            '/views/reconciliations/detail/billing',
        ]
        CleanPlanningStore()
        navigate(paths[index])
    }

    return (
        <>
            <Box display={'flex'}>
                <Tabs index={currentIndex}
                    variant='unstyled'
                    position='relative'
                    colorScheme="brand"
                    onChange={handleTabsChange}
                >
                    <TabList>
                        {campaignSubSection?.map((e, index) => (
                            e.enable &&
                            <Tab key={index} className="flex items-center gap-1">
                                <span>{e.name}</span>
                            </Tab>
                        ))}
                    </TabList>
                    <TabIndicator />
                </Tabs>
                <Tooltip label="Gestionar sub secciones">
                    <Button className="ml-4"
                        as={IconButton}
                        icon={<TbSettings2 size={20} />}
                        variant="ghost"
                        size="sm"
                        onClick={() => openModal('SubSectionsModal')}
                    />
                </Tooltip>
            </Box>
            <SubSectionsModal />
        </>
    )
}

export default ConciliationTabs