import BrandBox from "@/core/components/molecules/BrandBox"
import { DataUtil } from "@/core/utils/data.util"
import { Divider } from "@chakra-ui/react"
import { Legend, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip } from "recharts"

interface MediosRadialChartProps {
    label: string
    amount?: number
    currency?: string
    nsColor: string
    prColor: string
    data: any[]
}

const MediosRadialChart: React.FC<MediosRadialChartProps> = ({
    label,
    amount,
    currency,
    nsColor,
    prColor,
    data
}) => {
    /** */
    const CustomTooltip = () => {
        return (
            <BrandBox className="bg-[#1E1B39] text-white">
                <div className="flex flex-col">
                    {data.map( item => (
                        <>
                            <div className="">
                                <strong>{item.firstLabel}: </strong>
                                <strong>${DataUtil.numberFormat( item.firstQuantity )}</strong>
                            </div>
                            <div className="">
                                <strong>{item.secondLabel}: </strong>
                                <strong>${DataUtil.numberFormat( item.secondQuantity )}</strong>
                            </div>
                        </>
                    ))}
                </div>
            </BrandBox>
        )
    }

    const RenderLegend = () => {
        return (
            <ul className="flex justify-center gap-6">
                {data.map(( item, index ) => (
                    <>
                        <li key={index+item.firstLabel}>
                            <span style={{
                                    display: 'inline-block',
                                    width: 10,
                                    height: 10,
                                    backgroundColor: nsColor,
                                    borderRadius: '50%',
                                    marginRight: 8
                                }}>
                            </span>
                            {item.firstLabel}
                        </li>
                        <li key={index+item.secondLabel}>
                            <span style={{
                                    display: 'inline-block',
                                    width: 10,
                                    height: 10,
                                    backgroundColor: prColor,
                                    borderRadius: '50%',
                                    marginRight: 8
                                }}>
                            </span>
                            {item.secondLabel}
                        </li>
                    </>
                ))}
            </ul>
        )
    }

    return (
        <BrandBox className="flex flex-col">
            <div className="flex items-center justify-between p-2">
                <div className="flex flex-col">
                    <small>Medio</small>
                    <strong>{label}</strong>
                </div>
                {amount && (
                    <div className="flex flex-col items-end">
                        <small>Inversión</small>
                        <strong>{`${currency}${DataUtil.numberFormat( amount )}`}</strong>
                    </div>
                )}
            </div>
            <Divider />
            <div className="flex items-center justify-center py-2">
                <ResponsiveContainer width="100%" height={250}>
                    <RadialBarChart
                        data={data}
                        endAngle={180}
                        innerRadius={80}
                        outerRadius={130}
                    >
                        <Tooltip content={<CustomTooltip />} />
                        <RadialBar
                            dataKey="secondQuantity"
                            fill={prColor}
                            stackId="a"
                            cornerRadius={5}
                            className="stroke-transparent stroke-2"
                        />
                        <RadialBar
                            dataKey="firstQuantity"
                            stackId="a"
                            cornerRadius={5}
                            fill={nsColor}
                            className="stroke-transparent stroke-2"
                        />
                        <Legend content={RenderLegend} />
                    </RadialBarChart>
                </ResponsiveContainer>
            </div>
        </BrandBox>
    )
}

export default MediosRadialChart