import { useExcelTableStore } from "@/core/stores/excel-table.store"
import { useBudgetStore } from "@/modules/planning/store/budget-card.store"
import { usePlanningStore } from "../store/planning.store"

const CleanPlanningStore = () => {
    useExcelTableStore.getState().reset()
    useBudgetStore.getState().reset()
    usePlanningStore.getState().setRoadMapSelect('');
    usePlanningStore.getState().setTasksRoadMapselect([]);
}

export default CleanPlanningStore