import { Apis } from "@/apis"
import HttpClient from "@/core/http/http-client"
import { ApiResponseLegacy } from "@/core/models/api-response-legacy"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { RoleRequest } from "../models/role-request.model"
import { AdminRole } from "../models/admin-role.model"

export class RoleService {
    /** */
    private httpBase = new HttpClient( `${Apis.BASE}/api/general/rol` )
    private http = new HttpClient( `${Apis.ORKESTADOC}/api/v1/rol` )

    public getAllRoles(): Promise<ApiResponseLegacy> {
        return this.httpBase.get<ApiResponseLegacy>( 'all' )
    }

    public getRolesByCompany( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( `/idCompany/${companyId}` )
    }

    public createRole( body: RoleRequest ): Promise<GenericApiResponse> {
        return this.http.put<GenericApiResponse>( '', body )
    }

    public updateRole( body: AdminRole ): Promise<GenericApiResponse> {
        return this.http.patch( '', body )
    }

    public deleteRole( roleId: string ): Promise<GenericApiResponse> {
        return this.http.delete<GenericApiResponse>( `/id/${roleId}` )
    }

}