import { Navigate, Outlet } from "react-router-dom"
import { useAuthStore } from "@/modules/auth/stores/auth.store"
import Navbar from "@/core/components/organism/Navbar"
import { useUserStore } from "@/modules/auth/stores/user.store"
import SessionExpireNotifier from "@/core/components/organism/SessionExpireNotifier"
import useMountedEffect from "@/core/hooks/useMountedEffect"

const ViewsLayout: React.FC = () => {
    /** */
    const authStatus = useAuthStore( s => s.status )
    const timestampExp = useAuthStore( s => s.timestampExp )
    const userData = useUserStore( s => s.userData )
    const companyData = useUserStore( s => s.companyData )
    const signOutUser = useAuthStore( s => s.signOutUser )
    const checkSessionExpires = useAuthStore( s => s.checkSessionExpires )

    useMountedEffect( () => {
        /** */
        timestampExp && checkSessionExpires()
    })

    if ( authStatus === 'unauthorized' ) {
        return <Navigate to='/auth' />
    }

    return (
        <section className="views">
            <Navbar
                userName={`${userData?.firstName} ${userData?.lastName}`}
                companyName={companyData?.name}
                signOut={signOutUser}
            />
            <section className="py-8 px-16 routes relative">
                <Outlet />
            </section>
            {/* Notificador de expiración de la sesión */}
            <SessionExpireNotifier />
        </section>
    )
}

export default ViewsLayout