import { StateCreator, create } from "zustand"
import { immer } from "zustand/middleware/immer"

/** Contrato del Store */
interface ModalConfig {
    [key: string]: {
        isOpen: boolean
        data?: any
    }
}
interface ModalState {
    modals: ModalConfig
    openModal: ( key: string, data?: any ) => void
    closeModal: ( key: string ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    modals: { }
}

/** Definición del Store */
const Store: StateCreator<ModalState> = ( set ) => ({

    ...initialState,

    openModal: ( key: string, data?: any ) => set( state => ({
        modals: { ...state.modals, [key]: { isOpen: true, data } }
    })),

    closeModal: ( key: string ) => set( state => ({
        modals: { ...state.modals, [key]: { ...state.modals[key], isOpen: false } }
    })),

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useModalStore = create<ModalState>()(
    // persist(
        immer( Store )
    //     {
    //         name: 'modal-store',
    //         getStorage: () => sessionStorage
    //     }
    // )
)