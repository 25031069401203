import React from 'react';
import { Box, Select, InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const WitnessesFilters = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" alignItems="center" gap="4" w={'100%'} mb={3}>
      <Select placeholder="Ordenar por" bg="white"
        borderRadius="md"
        w={'20%'}>
        <option value="name">Nombre</option>
        <option value="date">Fecha</option>
      </Select>

      <InputGroup width="80%">
        <Input placeholder={`Buscar`} />
        <InputRightElement children={<SearchIcon color="gray.300" />} />
      </InputGroup>
      <Select placeholder="Todos los medios" bg="white"
        borderRadius="md"
        w={'20%'}>
        <option value="name">TVA</option>
        <option value="date">PTV</option>
        <option value="date">TVL</option>
        <option value="date">Radio</option>
        <option value="date">Estadios</option>
        <option value="date">OOH</option>
        <option value="date">Prensa</option>
        <option value="date">Digital</option>
      </Select>
    </Box>
  );
};

export default WitnessesFilters;
