
import GC from '@mescius/spread-sheets';
export class ExcelUtils {

  public static deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  public static getFileType(file: File) {
    var FileType = {
      SJS: 'sjs',
      Excel: 'xlsx',
      SSJson: 'ssjson',
      Csv: 'csv',
    }
    if (!file) {
      return;
    }

    var fileName = file.name;
    var extensionName = fileName.substring(fileName.lastIndexOf(".") + 1);

    if (extensionName === 'sjs') {
      return FileType.SJS;
    } else if (extensionName === 'xlsx' || extensionName === 'xlsm') {
      return FileType.Excel;
    } else if (extensionName === 'ssjson' || extensionName === 'json') {
      return FileType.SSJson;
    } else if (extensionName === 'csv') {
      return FileType.Csv;
    }
  }

  public static applyCellStyles(sheet: GC.Spread.Sheets.Worksheet, y: number, x: number) {

    /* console.log(sheet);
    const rowCount = sheet.getRowCount();
    const colCount = sheet.getColumnCount();
    const range = new GC.Spread.Sheets.Range(0, 0, rowCount, colCount);
    const activeStyle = new GC.Spread.Sheets.Style();
    activeStyle.backColor = "#0344dc"; // Ejemplo de estilo
    activeStyle.foreColor = "white"
  
    sheet.cellStates.add(range, GC.Spread.Sheets.CellStatesType.active, activeStyle); */
    const activeStyle = sheet.getStyle(y, x, GC.Spread.Sheets.SheetArea.viewport);
    activeStyle.backColor = "#0344dc"; // Ejemplo de estilo
    activeStyle.foreColor = "white"
    sheet.setStyle(y, x, activeStyle, GC.Spread.Sheets.SheetArea.viewport);
  }
}