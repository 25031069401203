import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { usePlanningStore } from "@/modules/planning/store/planning.store"
import { Task } from "gantt-task-react"
import { useEffect, useState } from "react"

const DateRoadmapAlert: React.FC = () => {
  const { setTasksRoadMapselect, tasksRoadmapSelected } = usePlanningStore()
  const [taskSelected, setTaskSelected] = useState<Task>();
  const [oldTask, setOldTask] = useState<Task>();

  useEffect(() => {
    if (taskSelected) {
      let oldTask: Task = tasksRoadmapSelected.filter(e => e.id === taskSelected.project)[0]
      setOldTask(oldTask)
    }
  }, [taskSelected])

  const handleCancel = () => {
    setTasksRoadMapselect(tasksRoadmapSelected);
  }

  const handleDateRoadmap = () => {
    console.log(oldTask, taskSelected)
    if (taskSelected?.start! < oldTask?.start! || taskSelected?.end! > oldTask?.end!) {
      /* let newTasks: Task[] = tasksRoadmapSelected.map(t => (t.id === taskSelected?.id ? taskSelected : t));
      setTasksRoadMapselect(newTasks); */
      if (taskSelected?.start! < oldTask?.start!) {
        let newProject: Task[] = tasksRoadmapSelected.map(t => (t.id === oldTask?.id ? {
          ...oldTask,
          start: taskSelected?.start!
        } : t)).map(t => (t.id === taskSelected?.id ? taskSelected : t));
        setTasksRoadMapselect(newProject);
      }
      if (taskSelected?.end! > oldTask?.end!) {
        let newProject: Task[] = tasksRoadmapSelected.map(t => (t.id === oldTask?.id ? {
          ...oldTask,
          end: taskSelected?.end!
        } : t)).map(t => (t.id === taskSelected?.id ? taskSelected : t));
        setTasksRoadMapselect(newProject);
      }
    }
  }

  return (
    <CustomAlertDialog
      id="DateRoadmapAlert"
      title={`Fecha de tarea`}
      cancelText="No, cancelar"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleDateRoadmap}
      onCancel={handleCancel}
    >
      {(task) => {
        setTaskSelected(task)
        return <Box>
          <Text>La fecha de la tarea se está saliendo del rango de fechas del proyecto.</Text>
          <Text>¿Deseas ajustar la fecha del proyecto?</Text>
        </Box>
      }
      }
    </CustomAlertDialog>
  )
}

export default DateRoadmapAlert