

export class DataUtil {

    public static extractErrorMessage(response: string) {

        const defaultMessage = 'Error desconocido al procesar la respuesta'

        if (!response) return defaultMessage

        const regex = /Exception: \|([^/]+)\//
        const match = response.match(regex)

        if (match && match[1]) {
            return match[1].trim()
        } else {
            return defaultMessage
        }
    }

    public static truncateText(value: string, maxChars: number, ellipsis?: string): string {
        return value.length > maxChars
            ? `${value.slice(0, maxChars)}${ellipsis || '...'}`
            : value
    }

    public static capitalizeText(value: string): string {

        if (!value || value === '') return value

        let capitalized = ''
        const words: string[] = value.split(' ')

        for (const word of words) {
            const firstLetter = word.charAt(0).toUpperCase()
            const remainingLetters = word.slice(1).toLowerCase()

            capitalized !== '' && (capitalized = `${capitalized} `)
            capitalized = `${capitalized}${firstLetter}${remainingLetters}`
        }

        return capitalized
    }

    public static isValidJsonString(str: string) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    public static numberCurrencyFormat(value: number): string {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formatter.format(value) 
    }

    public static numberFormat( value: number ): string {
        return value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    public static getAlphabetLetterFromNumber(num: number): string {
        if (num < 1 || num > 26) {
          throw new Error("Number must be between 1 and 26.");
        }
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return alphabet[num - 1];
      }

}