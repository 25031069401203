import { useState } from "react"
import { useAdminPermissionStore } from "../stores/admin-permission.store"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import { TbFilterSearch, TbTrash } from "react-icons/tb"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import { HiLockClosed } from "react-icons/hi"

const PermissionsPage: React.FC = () => {
    /** */
    const permisions = useAdminPermissionStore( s => s.permisions )
    const { retrievePermission } = useAdminPermissionStore()
    const [ inputFilter, setInputFilter] = useState<string>('')

    const columns: BrandTableColumn[] = [
        {
            header: 'Nombre', accessor: 'tag', sortable: true, customRow: [
                // eslint-disable-next-line
                row => <span>{row.tag.replace(/[\[\]]/g, "")}</span>
            ]
        },
        { header: 'Descripción', accessor: 'description', sortable: true },
        {
            header: 'Nivel de permiso', accessor: '', positionRow: 'center', customRow: [
                row => <span className="flex flex-col items-center justify-center lock-icon">
                            <HiLockClosed color={getMethodColor( row.method )} size={20} />
                            <small className="font-semibold text-xs"
                                style={{ 'color': getMethodColor( row.method ) }}>
                                {row.method} - {getMethodDescription( row.method )}
                            </small>
                        </span>
            ]
        },
        {
            header: '', accessor: '', positionRow: 'end', customRow: [
                row => <ActionIcon label="Eliminar permiso" icon={TbTrash} disabled={true} onHandle={() => row} />,
            ]
        }
    ]

    const getMethodDescription = ( method: string ) => {
        switch( method.toUpperCase() ) {
            case 'GET': return 'Bajo'
            case 'POST': return 'Moderado'
            case 'PUT': return 'Moderado alto'
            case 'PATCH': return 'Moderado alto'
            case 'DELETE': return 'Alto peligro'
            default: return 'Permiso sin descripción'
        }
    }

    const getMethodColor = ( method: string ) => {
        switch( method.toUpperCase() ) {
            case 'GET': return '#60a5fa'
            case 'POST': return '#2dd4bf'
            case 'PUT': return '#4ade80'
            case 'PATCH': return '#facc15'
            case 'DELETE': return '#f87171'
            default: return '#000000'
        }
    }

    const filterPermissions = () => {
        const filteredPermissionsByInput = permisions.filter( item => {
            const searchString = `${item.name} ${item.description}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredPermissionsByInput
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    useMountedEffect( () => {
        retrievePermission()
    })

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-between">
                <div className="flex flex-wrap gap-4">
                    <div className="flex items-center gap-1">
                        <HiLockClosed color="#60a5fa" />
                        <small>Recuperar información</small>
                    </div>
                    <div className="flex items-center gap-1">
                        <HiLockClosed color="#2dd4bf" />
                        <small>Enviar nueva información</small>
                    </div>
                    <div className="flex items-center gap-1">
                        <HiLockClosed color="#4ade80" />
                        <small>Actualizar información importante</small>
                    </div>
                    <div className="flex items-center gap-1">
                        <HiLockClosed color="#facc15" />
                        <small>Actualizar información parcial</small>
                    </div>
                    <div className="flex items-center gap-1">
                        <HiLockClosed color="#f87171" />
                        <small>Eliminar información</small>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                </div>
            </div>
            {/* Datos */}
            <BrandTable
                columns={columns}
                data={filterPermissions()}
                rowsPerPageOptions={[5, 10, 15]}
                defaultRowsPerPage={5}
                showPagination={true}
            />
            {/* Modals | Alerts */}
            {/* <CreateOrEditRoleModal /> */}
            {/* <ConfirmRoleDeletionAlert /> */}
        </article>
    )
}

export default PermissionsPage