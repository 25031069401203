import { useToast } from "@chakra-ui/react";

const useCustomToast = () => {
    const toast = useToast();

    function error(msg: string, title?: string) {
        toast({
            title: title ?? 'Error',
            description: msg,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
            variant: "top-accent",
        });
    }

    function success(msg: string, title?: string) {
        toast({
            title: title ?? 'Éxito',
            description: msg,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
            variant: "top-accent",
        });
    }

    function info(msg: string , title?: string){
        toast({
            title: title ?? 'Información',
            description: msg,
            status: "info",
            duration: 15000,
            isClosable: true,
            position: 'top-left',
        });
    }
    
    return { success, error, info };
};

export default useCustomToast;
