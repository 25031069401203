import BrandBox from "@/core/components/molecules/BrandBox"
import { DataUtil } from "@/core/utils/data.util"
import { Divider } from "@chakra-ui/react"
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts"

interface MediosCompareChartProps {
    label: string
    amount: number
    currency: string
    nsColor: string
    prColor: string
    data: any[]
}

const MediosCompareChart: React.FC<MediosCompareChartProps> = ({
    label,
    amount,
    currency,
    nsColor,
    prColor,
    data
}) => {
    /** */
    const legendNames: {[ key: string ]: string } = {
        ns: 'No. Spots',
        tr: 'TRPS P 18-45 ABC+C'
    }

    const RenderLegend = ( props: any ) => {
        const { payload } = props;

        return (
            <ul className="flex justify-center gap-6">
                {payload.map(( entry: any, index: number ) => (
                    <li key={`item-${index}`}>
                        <span style={{
                                display: 'inline-block',
                                width: 10,
                                height: 10,
                                backgroundColor: entry.color,
                                borderRadius: '50%',
                                marginRight: 8
                            }}>
                        </span>
                        {legendNames[entry.value] || entry.value}
                    </li>
                ))}
            </ul>
        )
    }

    const CustomTooltip = ( payload: any ) => {
        const label = payload.label

        return (
            <BrandBox className="bg-[#1E1B39] text-white">
                <div className="flex flex-col">
                    {data.map( item => (
                        <>
                            {item.name === label && (
                                <div className="flex flex-col gap-2">
                                    <div className="flex items-center gap-2">
                                        <strong>{legendNames['ns']}:</strong>
                                        <strong>
                                            {item.currency
                                                ? `$${DataUtil.numberFormat( item.ns )}`
                                                : item.ns
                                            }
                                        </strong>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <strong>{legendNames['tr']}:</strong>
                                        <strong>
                                            {item.currency
                                                ? `$${DataUtil.numberFormat( item.ns )}`
                                                : item.tr
                                            }
                                        </strong>
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </BrandBox>
        )
    }

    return (
        <BrandBox className="flex flex-col">
            <div className="flex items-center justify-between p-2">
                <div className="flex flex-col">
                    <small>Medio</small>
                    <strong>{label}</strong>
                </div>
                <div className="flex flex-col items-end">
                    <small>Inversión</small>
                    <strong>{`${currency}${DataUtil.numberFormat( amount )}`}</strong>
                </div>
            </div>
            <Divider />
            <div className="flex items-center justify-center py-2">
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                        barCategoryGap="18%"
                    >
                        <XAxis dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={{ fontSize: 12 }}
                        />
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        {/* <YAxis /> */}
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={RenderLegend} />
                        <Bar dataKey="ns"
                            fill={nsColor}
                            radius={[5, 5, 5, 5]}
                        />
                        <Bar dataKey="tr"
                            fill={prColor}
                            radius={[5, 5, 5, 5]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </BrandBox>
    )
}

export default MediosCompareChart