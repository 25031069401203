import { Apis } from "@/apis"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { Area } from "@/core/models/area.model"

export class AreaService {
    /** */
    private http = new HttpClient( `${Apis.ORKESTAMEDIA}/api/v1/area` )

    public getAreasByCompany( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get( `/full/companyId/${companyId}` )
    }

    public updateArea( body: Area ): Promise<GenericApiResponse> {
        return this.http.patch( '', body )
    }

    public createArea( body: Area ): Promise<GenericApiResponse> {
        return this.http.put( '', body )
    }

}