import React, { useEffect, useState } from 'react';
import { Select, Button, Flex } from '@chakra-ui/react';
import { BiCheck, BiClipboard, BiCloudUpload } from 'react-icons/bi';
import { LuPanelLeft } from 'react-icons/lu';
import LoadFileModal from './LoadFileModal';
import { useModalStore } from '@/core/stores/modal.store';
import { useExcelTableStore } from '@/core/stores/excel-table.store';
import BinnacleModal from '@/modules/planning/components/BinnacleModal';
import { useNavigate } from 'react-router-dom';

interface VersionsProps {
  file: File | null
  label: string
}

interface FiltersSectionsProps {
  selectedVersion: string
  setSelectedVersion: (selectedVersion: string) => void
  tvType?: string
}

const FlowFiltersSections: React.FC<FiltersSectionsProps> = ({ selectedVersion, setSelectedVersion, tvType }) => {
  const [versions, setVersions] = useState<VersionsProps[]>([]);
  const { setSelectedFile, openFile } = useExcelTableStore();
  const openModal = useModalStore(s => s.openModal)
  const navigate = useNavigate()
  const providers = ['Proveedor 1', 'Proveedor 2', 'Proveedor 3'];
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const handleNewVersion = (e: File | null) => {
    setVersions([
      ...versions,
      {
        label: e?.name!,
        file: e
      }
    ])
  }

  function arrayBufferToFile(arrayBuffer: ArrayBuffer, fileName: string, mimeType: string) {
    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Crea un objeto File a partir del Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  const readFile = () => {
    let ver: VersionsProps[] = []
    fetch('/dummy/FLOW.xlsx')
      .then(response => response.arrayBuffer())
      .then(data => {
        const file = arrayBufferToFile(data, 'flow1.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        ver.push({
          file: file, label: 'flow1.xlsx'
        })
        fetch('/dummy/PRESUPUESTO.xlsx')
          .then(response => response.arrayBuffer())
          .then(data => {
            const file = arrayBufferToFile(data, 'flow2.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            ver.push({
              file: file, label: 'flow2.xlsx'
            })
            setVersions(ver);
          })
          .catch(error => console.error('Error al leer el archivo:', error));
      })
      .catch(error => console.error('Error al leer el archivo:', error));
  }

  useEffect(() => {
    if (selectedVersion) {
      const fileSelected = versions.find(e => e.label === selectedVersion);
      setSelectedFile(fileSelected?.file!)
      setTimeout(() => {
        openFile()
      }, 10)
    }
  }, [selectedVersion])

  useEffect(() => {
    readFile()
  }, [])

  return (
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Flex gap={4} className='w-[55%]'>
        <Select variant='white' placeholder="Selecciona una versión" value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)}>
          {versions.map((version) => (
            <option key={version.label} value={version.label}>{version.label}</option>
          ))}
        </Select>
        <Select variant='white' placeholder="Selecciona un proveedor">
          {providers.map((provider, index) => (
            <option key={index} value={provider}>{provider}</option>
          ))}
        </Select>
        <Select variant='white' placeholder="Selecciona un mes">
          {months.map((month, index) => (
            <option key={index} value={month.toLowerCase()}>{month}</option>
          ))}
        </Select>
      </Flex>
      <Flex gap={4}>
        <Button leftIcon={<LuPanelLeft />} variant="outline" onClick={() => navigate(`/views/campaigns/detail/flow/${tvType}/compare`)}>Comparar</Button>
        <Button leftIcon={<BiClipboard />} variant="outline" onClick={() => openModal('BinnacleModal')}>Bitácora</Button>
        {
          selectedVersion ? <Button rightIcon={<BiCheck />}>Aprobar</Button> :
            <Button rightIcon={<BiCloudUpload />} onClick={() => openModal('LoadFileModal')} colorScheme="brand">{/* </Button> */}
              Cargar documento
            </Button>
        }
      </Flex>
      <LoadFileModal setSelectedFile={handleNewVersion} />
      <BinnacleModal selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion} />
    </Flex>
  );
};

export default FlowFiltersSections;
