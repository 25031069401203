import React, { useState } from 'react';
import { Select, Button, Flex, Box, background } from '@chakra-ui/react';
import { LuList, LuPieChart } from "react-icons/lu";

interface FlowFiltersProps {
  active: number;
  setActive: (active: number) => void
}

const FlowFilters: React.FC<FlowFiltersProps> = ({active, setActive}) => {
  const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  return (
    <Flex alignItems="center" gap={4} w={'100%'} justifyContent='space-between' mb={4}>
      <Flex gap={4} w={'100%'}>
        <Box w={'100%'}>
          <Select placeholder="Selecciona un tipo" variant={'white'}>
            <option value="mensual">Mensual</option>
            <option value="anual">Anual</option>
          </Select>
        </Box>
        <Box w={'100%'}>
          <Select placeholder="Selecciona un mes" variant={'white'}>
            {months.map((month, index) => (
              <option key={index} value={month.toLowerCase()}>{month}</option>
            ))}
          </Select>
        </Box>
        <Box w={'100%'}>
          <Select placeholder="Selecciona un año" variant={'white'}>
            {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </Select>
        </Box>
      </Flex>
      <Flex gap={4}>
        <Button className={active === 0 ? 'opacity-100' : 'opacity-50'} borderWidth={2} variant='outline' onClick={() => setActive(0)}>
          <LuPieChart className='w-5 h-5' />
        </Button>
        <Button className={active === 1 ? 'opacity-100' : 'opacity-50'} borderWidth={2} variant='outline' onClick={() => setActive(1)}>
          <LuList className='w-5 h-5' />
        </Button>
      </Flex>
    </Flex>
  );
};

export default FlowFilters;
