import { StateCreator, create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { SecureSessionStorage } from "./secure-sessionstorage.store";

/** Contrato del Store */
type Timer = {
    time: number
    isRunning: boolean
}

interface TimerState {
    timers: Record<string, Timer>
    startTimer: ( id: string ) => void
    stopTimer: ( id: string ) => void
    resetTimer: ( id: string, time: number ) => void
    decrementTime: ( id: string ) => void
    addTimer: ( id: string, time: number ) => void
    removeTimer: ( id: string ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    timers: { }
}

/** Definición del Store */
const Store: StateCreator<TimerState> = ( set ) => ({

    ...initialState,

    startTimer: ( id ) => set( state => ({
        timers: {
            ...state.timers,
            [id]: { ...state.timers[id], isRunning: true }
        }
    })),

    stopTimer: ( id ) => set( state => ({
        timers: {
            ...state.timers,
            [id]: { ...state.timers[id], isRunning: false }
        }
    })),

    resetTimer: ( id, time ) => set( state => ({
        timers: {
            ...state.timers,
            [id]: { time, isRunning: false }
        }
    })),

    decrementTime: ( id ) => set( state => ({
        timers: {
            ...state.timers,
            [id]: { ...state.timers[id], time: ( state.timers[id]?.time || 60 ) - 1 }
        }
    })),

    addTimer: ( id, time ) => set( state => ({
        timers: {
            ...state.timers,
            [id]: { time, isRunning: false }
        }
    })),

    removeTimer: ( id: string ) => set( state => {
        const { [id]:_, ...rest } = state.timers
        return { timers: rest }
    }),

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useTimerStore = create<TimerState>()(
    persist(
        immer( Store ),
        {
            name: 'timer-store',
            storage: SecureSessionStorage
        }
    )
)