import { useUserStore } from "@/modules/auth/stores/user.store"
import { useAdminRoleStore } from "../stores/admin-role.store"
import { useState } from "react"
import { useModalStore } from "@/core/stores/modal.store"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbFilterSearch, TbPencil, TbTrash, TbCirclePlus2 } from "react-icons/tb"
import { AdminRole } from "../models/admin-role.model"
import { Box, Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import ConfirmRoleDeletionAlert from "../components/roles/ConfirmRoleDeletionAlert"
import CreateOrEditRoleModal from "../components/roles/CreateOrEditRoleModal"
import { useAdminPermissionStore } from "../stores/admin-permission.store"

const RolesPage: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const adminRoles = useAdminRoleStore( s => s.adminRoles )
    const { retrieveAdminRoles, selectAdminRole } = useAdminRoleStore()
    const { retrievePermission } = useAdminPermissionStore()
    const [ inputFilter, setInputFilter] = useState<string>('')
    const openModal = useModalStore( s => s.openModal )

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'name', sortable: true },
        { header: 'Descripción', accessor: 'description', sortable: true },
        {
            header: '', accessor: '', positionRow: 'end', customRow: [
                row => <ActionIcon label="Editar rol" icon={TbPencil} onHandle={() => handleEditRole( row )} />,
                row => <ActionIcon label="Eliminar rol" icon={TbTrash} onHandle={() => handleDeleteRole( row )} />
            ]
        }
    ]

    const handleNewRole = () => {
        openModal( 'createOrEditRoleModal' )
    }

    const handleDeleteRole = ( row: AdminRole ) => {
        selectAdminRole( row )
        openModal( 'confirmRoleDeletionAlert', row )
    }

    const handleEditRole = ( row: AdminRole ) => {
        selectAdminRole( row )
        openModal( 'createOrEditRoleModal' )
    }

    const filterAdminRoles = () => {
        const filteredAdminRolesByInput = adminRoles.filter( item => {
            const searchString = `${item.name} ${item.description}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredAdminRolesByInput
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    useMountedEffect( () => {
        retrieveAdminRoles( companyData?.id! )
        retrievePermission()
    })

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-end">
                <div className="flex items-center gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    <Box>
                        <Button
                            leftIcon={<TbCirclePlus2 />}
                            colorScheme="brand"
                            onClick={handleNewRole}>
                            Agregar rol
                        </Button>
                    </Box>
                </div>
            </div>
            {/* Datos */}
            <BrandTable
                columns={columns}
                data={filterAdminRoles()}
                rowsPerPageOptions={[5, 10, 15]}
                defaultRowsPerPage={5}
                showPagination={true}
            />
            {/* Modals | Alerts */}
            <CreateOrEditRoleModal />
            <ConfirmRoleDeletionAlert />
        </article>
    )
}

export default RolesPage