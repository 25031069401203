import { Outlet } from "react-router-dom";

const CampaignsLayout: React.FC = () => {
    /** */
    return (
        <>
            <Outlet />
        </>
    );
}

export default CampaignsLayout