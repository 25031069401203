import { Outlet } from "react-router-dom"
import ConfigTabs from "./components/ConfigTabs"

const ConfigLayout: React.FC = () => {
    /** */
    return (
        <>
            <ConfigTabs />
            <section className="py-6">
                <Outlet />
            </section>
        </>
    )
}

export default ConfigLayout