import { useModalStore } from "@/core/stores/modal.store"
import { useAdminRoleStore } from "../../stores/admin-role.store"
import { FcMindMap } from "react-icons/fc"
import { useEffect, useMemo, useState } from "react"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useUserStore } from "@/modules/auth/stores/user.store"
import { useFormik } from "formik"
import * as Yup from "yup"
import CustomModal from "@/core/components/organism/CustomModal"
import { FormControl, FormErrorMessage, Input, InputGroup } from "@chakra-ui/react"
import CheckboxTree from "react-checkbox-tree"
import "react-checkbox-tree/lib/react-checkbox-tree.css"
import { useAdminPermissionStore } from "../../stores/admin-permission.store"
import { ImCheckboxChecked, ImCheckboxUnchecked, ImMinus, ImPlus, ImStack } from "react-icons/im"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { HiLockClosed } from "react-icons/hi"
import LinkButton from "@/core/components/atoms/LinkButton"
import { BiSelectMultiple } from "react-icons/bi"
import { RiCheckboxMultipleBlankLine } from "react-icons/ri"
import { RoleRequest } from "../../models/role-request.model"
import { AdminRole } from "../../models/admin-role.model"

interface TreeNode {
    value: string
    label: string
    children?: TreeNode[]
    className?: string
    orderValue: string
}

const CreateOrEditRoleModal: React.FC = () => {
    /** */
    const selectedAdminRole = useAdminRoleStore( s => s.selectedAdminRole )
    const companyData = useUserStore( s => s.companyData )
    const permissions = useAdminPermissionStore( s => s.permisions )
    const { addAdminRole, updateAdminRole, deselectAdminRole } = useAdminRoleStore()
    const closeModal = useModalStore( s => s.closeModal )
    const [ modalTitle, setModalTitle ] = useState<string>( '' )
    const [ permissionsChecked, setPermissionsChecked ] = useState<string[]>( [] )
    const [ permissionsExpanded, setPermissionsExpanded ] = useState<string[]>( [] )
    const toast = useCustomToast()

    const handleCloseModal = () => {
        deselectAdminRole()
        setPermissionsExpanded( [] )
        setPermissionsChecked( [] )
        roleForm.resetForm()
    }

    const createOrEditRole = () => {
        selectedAdminRole
            ? updateRole()
            : createRole()
    }

    const updateRole = () => {
        const body: AdminRole = {
            app: selectedAdminRole?.app!,
            createdDate: selectedAdminRole?.createdDate!,
            id: selectedAdminRole?.id!,
            idApp: selectedAdminRole?.idApp!,
            idCompany: selectedAdminRole?.idCompany!,
            lastModifiedDate: selectedAdminRole?.lastModifiedDate!,
            namePermissionList: selectedAdminRole?.namePermissionList!,
            permissionList: selectedAdminRole?.permissionList!,
            name: roleForm.values.name,
            description: roleForm.values.description,
            idPermissionList: permissionsChecked
        }

        updateAdminRole( body ).then(
            () => {
                toast.success( 'Rol actualizado correctamente' )
                closeModal( 'createOrEditRoleModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al actualizar el rol' )
        )
    }

    const createRole = () => {
        const body: RoleRequest = {
            name: roleForm.values.name,
            description: roleForm.values.description,
            idCompany: companyData?.id!,
            idPermissionList: permissionsChecked
        }

        addAdminRole( body ).then(
            () => {
                toast.success( 'Rol creado correctamente' )
                closeModal( 'createOrEditRoleModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al crear el rol' )
        )
    }

    const permissionTreeNodes = useMemo( () => {
        const nodes: TreeNode[] = []

        permissions.forEach( originalNode => {
            const tag = originalNode.tag
            let parentNode: TreeNode = nodes.find( node => node.value === tag ) as TreeNode

            if ( !parentNode ) {
                parentNode = {
                    value: tag,
                    label: tag.replace(/[\[\]]/g, ""),
                    children: [],
                    className: 'text-gray-700 text-sm font-semibold my-1',
                    orderValue: tag
                }

                nodes.push( parentNode )
            }

            parentNode?.children?.push({
                value: originalNode.id,
                label: originalNode.description,
                className: `text-gray-700 text-sm font-light my-1 method-class-${originalNode.method?.toLowerCase()}`,
                orderValue: originalNode.method
            })
        })

        nodes.forEach( node => {
            node.children?.sort(( a, b ) => ( a.orderValue > b.orderValue ? 1 : -1 ))
        })

        return nodes
    }, [permissions])

    const roleForm = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required( "Este campo es requerido" ),
            description: Yup.string().required( "Este campo es requerido" )
        }),
        onSubmit: createOrEditRole
    })

    const handleSelectAll = () => {
        const allPermissions = permissionTreeNodes.reduce(( acc: any, node ) => {
            return [ ...acc, node.value, ...( node.children || [] ).map( child => child.value ) ]
        }, [] )

        setPermissionsChecked( allPermissions )
    }

    const handleDeselectAll = () => {
        setPermissionsChecked( [] )
    }

    useEffect( () => {
        setModalTitle( selectedAdminRole ? 'Editar rol' : 'Agregar rol' )

        if ( selectedAdminRole ) {
            roleForm.setFieldValue( 'name', selectedAdminRole.name )
            roleForm.setFieldValue( 'description', selectedAdminRole.description )
            setPermissionsChecked( selectedAdminRole.idPermissionList )
        } else {
            roleForm.setFieldValue( 'name', '' )
            roleForm.setFieldValue( 'description', '' )
        }

        // eslint-disable-next-line
    }, [selectedAdminRole])

    return (
        <CustomModal id="createOrEditRoleModal"
            size="xl"
            icon={FcMindMap}
            title={modalTitle}
            acceptText={selectedAdminRole ? 'Actualizar rol' : 'Guardar rol'}
            closeOnAccept={false}
            onCloseComplete={handleCloseModal}
            onAccept={roleForm.handleSubmit}
        >
            {() => (
                <form onSubmit={roleForm.handleSubmit} className="flex flex-col gap-2">
                    {/* Nombre rol */}
                    <FormControl isInvalid={!!roleForm.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            Nombre del rol
                        </label>
                        <InputGroup>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                onChange={roleForm.handleChange}
                                value={roleForm.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{roleForm.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!roleForm.errors.description}>
                        <label htmlFor="description" className="text-sm leading-8">
                            Descripción del rol
                        </label>
                        <InputGroup>
                            <Input
                                id="description"
                                name="description"
                                type="text"
                                onChange={roleForm.handleChange}
                                value={roleForm.values.description}
                            />
                        </InputGroup>
                        <FormErrorMessage>{roleForm.errors.description}</FormErrorMessage>
                    </FormControl>
                    {/* Tree */}
                    <div className="flex flex-col gap-2">
                        <label>Selecciona los permisos de este rol</label>
                        <div className="mb-2 ml-2">
                            <LinkButton leftIcon={<BiSelectMultiple size={20} />} onClick={handleSelectAll}>
                                Seleccionar todos
                            </LinkButton>
                            {permissionsChecked.length !== 0 && (
                                <LinkButton
                                    leftIcon={<RiCheckboxMultipleBlankLine size={20} />}
                                    onClick={handleDeselectAll}
                                    className="ml-4"
                                >
                                    Limpiar campos
                                </LinkButton>
                            )}
                        </div>
                    </div>
                    <div className="max-h-[300px] overflow-y-auto">
                        <CheckboxTree
                            nodes={permissionTreeNodes}
                            checked={permissionsChecked}
                            expanded={permissionsExpanded}
                            onCheck={checked => setPermissionsChecked( checked )}
                            onExpand={expanded => setPermissionsExpanded( expanded )}
                            icons={{
                                check: <ImCheckboxChecked />,
                                uncheck: <ImCheckboxUnchecked />,
                                halfCheck: <ImCheckboxChecked style={{ opacity: 0.4 }} />,
                                expandClose: <FaChevronDown />,
                                expandOpen: <FaChevronUp />,
                                expandAll: <ImPlus />,
                                collapseAll: <ImMinus />,
                                parentClose: <ImStack />,
                                parentOpen: <ImStack />,
                                leaf: (
                                    <span className="lock-icon">
                                        <HiLockClosed color="inherit" />
                                    </span>
                                ),
                            }}
                        />
                    </div>
                </form>
            )}
        </CustomModal>
    )
}

export default CreateOrEditRoleModal