import GC from '@mescius/spread-sheets';
import '@mescius/spread-sheets-print';
import '@mescius/spread-sheets-io';
import '@mescius/spread-sheets-shapes';
import '@mescius/spread-sheets-charts';
import '@mescius/spread-sheets-slicers';
import '@mescius/spread-sheets-pivot-addon';
import '@mescius/spread-sheets-reportsheet-addon';
import "@mescius/spread-sheets-tablesheet";
import "@mescius/spread-sheets-ganttsheet";
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react';
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Box } from '@chakra-ui/react';

// @ts-ignore
window.GC = GC;

GC.Spread.Sheets.LicenseKey = "axeleratum.com,E324514727894797#B1IZM3mZUF7d4dDVGZzSKRlRNx4bGZDVUdGS5MkbrM5avE4Qwdjdi36KCx6V996NW9WO9kEMYNUStVkNBhVQyk4TEhFNKJDbElHVIllbs54chJnQHJ6T8EHdGFkNwBjZR9ES5QWZEVzMntyZ5sGSLFjVKhTcz4GO994bmBVYWF7bzgEVid5dLJjMuh5SFRDRlhGVtR5MBdnYB9mMTt6ZURDO7Z4UJF6TPdXZlRXOyg6LrZXNrYmUodTZ7NlYjBDNzxGVE3WdkRmW6ZUbYlnU7Z5UJlkR4sERvpUWIxERNVUQPxUesJUMvkzQKlFaDFjbzoUTLZUdnVVZ6pkdIJXYww6QR5UYMdla8pFUYVmI0IyUiwiIFFDOwATRiojIIJCLzITNxMjNyEjN0IicfJye35XX3JSVBtUUiojIDJCLicTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI9ADMxATMgMDM9ADNyAjMiojI4J7QiwiI8ETOwQjMwIjI0ICc8VkIsISbvNmLtVHdhJXZsVGehBkcp5WakFGb6JiOiEmTDJCLlVnc4pjIsZXRiwiI7kzN4kDO7IzN4ETN4IzMiojIklkIs4XXiQXZlh6U4J7bwVmUiwiIlxmYhRFdvZXaQJCLiQXZlh6U4RnbhdkIbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQRNlRWdUMKtWOiVkSRtEby3UaR3kUJNVdOR5S9pUT5B5dIFVNzEUMtRmY5FneVNnUyZHa0hnTRZ4YLR5Yal4RSB5cJVTRqteRwh";        // Enter a valid license key.      

const OnlyViewExcelTable = () => {
  let hostStyle = {
    width: '50%',
    height: '600px',
    border: '1px solid darkgray'
  }

  function initSpread(spread: GC.Spread.Sheets.Workbook) {

  }

  return (
    <SpreadSheets workbookInitialized={spread => initSpread(spread)} hostStyle={hostStyle}>
      <Worksheet />
    </SpreadSheets>
  );
}

export default OnlyViewExcelTable;