
export class FilesUtil {

    public static bytesToMegabytes(bytes: number): string {
        /** */
        if (!bytes) return "---"

        return (bytes / 1024 / 1024).toFixed(2)
    }

    public static blobToBase64(blob: Blob): Promise<ArrayBuffer> {
        /** */
        return new Promise((resolve, reject) => {

            const reader = new FileReader()

            reader.onload = () => {
                resolve(reader.result as ArrayBuffer)
            }

            reader.onerror = (error) => {
                reject(error)
            }

            reader.readAsDataURL(blob)
        })
    }

    public static b64toBlob(
        b64Data: string,
        contentType: string = "",
        sliceSize: number = 512
    ): Blob {
        /** */
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

            const slice = byteCharacters.slice(offset, offset + sliceSize)
            const byteNumbers = new Array(slice.length)

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }

        const blob = new Blob(byteArrays, { type: contentType })

        return blob
    }

    public static getMimeType(extension: string): string {
        /** */
        switch (extension?.toLowerCase()) {
            case "pdf": return "application/pdf"
            case "doc": return "application/msword"
            case "docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            case "xls": return "application/vnd.ms-excel"
            case "xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            case "ppt": return "application/vnd.ms-powerpoint"
            case "pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
            case "jpg": return "image/jpeg"
            case "jpeg": return "image/jpeg"
            case "png": return "image/png"
            case "gif": return "image/gif"
            case "xml": return "text/xml"
            case "html": return "text/html"
            case "txt": return "text/plain"
            case "json": return "application/json"
            case "zip": return "application/zip"
            case "mp3": return "audio/mpeg"
            case "mp4": return "video/mp4"
            case "avi": return "video/x-msvideo"
            case "wmv": return "video/x-ms-wmv"
            case "mov": return "video/quicktime"
            case "flv": return "video/x-flv"
            case "ogg": return "video/ogg"
            case "webm": return "video/webm"
            case "csv": return "text/csv"
            default: return "application/octet-stream"
        }
    }

    public static handleIcon (typeFile: string) {
        if (typeFile === 'pdf') return '/general/icons/pdf.svg'
        if (typeFile === 'xml') return '/general/icons/xml.svg'
        if (typeFile === 'image') return '/general/icons/image.svg'
        if (typeFile === 'video') return '/general/icons/video.svg'
        return '/general/icons/image.svg'
      }

    public static htmlToBase64(htmlString: string): Promise<string> {
        /** */
        const blob = new Blob([htmlString], { type: "text/html" })
        const reader = new FileReader()

        return new Promise<string>((resolve, reject) => {

            reader.onload = () => {

                if (typeof reader.result === "string") {

                    let base64String = reader.result

                    base64String = base64String.replace(/^data:text\/htmlbase64,/, "")

                    resolve(base64String)
                } else {
                    reject("Error reading HTML as Base64")
                }
            }

            reader.onerror = () => {
                reject("Error reading HTML as Base64")
            }

            reader.readAsDataURL(blob)
        })
    }

    public static extractFileExtension(file: File): string {
        /** */
        const fileName: string = file.name
        const lastDotIndex: number = fileName.lastIndexOf('.')

        if (lastDotIndex === -1) return ''

        return fileName.substring(lastDotIndex + 1)

    }

    public static fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const result = reader.result as string;
                resolve(result.split(',')[1]);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

    public static filePDFToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    public static fileToBlob(file: File): Promise<Blob> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const blob = new Blob([arrayBuffer], { type: file.type });
            resolve(blob);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsArrayBuffer(file);
        });
      }
      

}