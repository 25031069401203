import { Box, Button, Flex, Select, SimpleGrid } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiClipboard, BiDownload, BiSave } from 'react-icons/bi';
import AddBudgetCardModal from './AddBudgetCardModal';
import { MdFileUpload } from 'react-icons/md';
import { useModalStore } from '@/core/stores/modal.store';
import { usePlanningStore } from '../store/planning.store';
import ExcelTable from '@/core/components/organism/ExcelTable';
import { useExcelTableStore } from '@/core/stores/excel-table.store';
import SaveExcelFileAlert from './SaveExcelFileAlert';
import { useBudgetStore } from '../store/budget-card.store';
import useCustomToast from '@/core/hooks/useCustomToast';
import { DatesUtil } from '@/core/utils/dates.util';
import BinnacleModal from './BinnacleModal';
import PlanningFilters from './PlanningFilters';
import BudgetCards from './BudgetCards';
import { title } from 'process';
import { PropsBudgetCards } from '../models/planning.models';

const budgetData: PropsBudgetCards[] = [
  {
    title: '5m2 Airport',
    badges: [
      'KP/PF: Sí', 'Div: Corporativo', 'REG: 8'
    ],
    place: 'Cancún',
    negotiationType: 'Exteriores',
    payType: 'Aeropuerto',
    medioType: 'Negociación',
    medio: 'FDRC'
  },
  {
    title: 'A&E network',
    badges: [
      'KP/PF: Sí', 'Div: Corporativo', 'REG: 8'
    ],
    place: 'Cancún',
    negotiationType: 'Exteriores',
    payType: 'Aeropuerto',
    medioType: 'Negociación',
    medio: 'FDRC'
  },
  {
    title: '5m2 Airport',
    badges: [
      'KP/PF: Sí', 'Div: Corporativo', 'REG: 8'
    ],
    place: 'Cancún',
    negotiationType: 'Exteriores',
    payType: 'Aeropuerto',
    medioType: 'Negociación',
    medio: 'FDRC'
  },
  {
    title: 'A&E network',
    badges: [
      'KP/PF: Sí', 'Div: Corporativo', 'REG: 8'
    ],
    place: 'Cancún',
    negotiationType: 'Exteriores',
    payType: 'Aeropuerto',
    medioType: 'Negociación',
    medio: 'FDRC'
  }
]

function BudgetCardPage() {
  const toast = useCustomToast()
  const { selectedPlanning } = usePlanningStore();
  const { getBudgetCardFile } = useBudgetStore();
  const { save } = useExcelTableStore();
  const openModal = useModalStore(e => e.openModal);
  const [selectedOption, setSelectedOption] = useState('roadmap1');
  const [selectedOption2, setSelectedOption2] = useState('roadmap1');
  const [active, setActive] = useState<number>(0)
  const openFile = useExcelTableStore(e => e.openFile);

  const handleSelectChange = (event: any) => {
    if (event.target.value) {
      setSelectedOption(event.target.value);
      getBudgetCardFile(event.target.value).then(
        () => { }
      ).catch(
        (e) => {
          toast.error('Ocurrió un error al obtener documento')
        }
      )
    }
  };

  const handleSelectChange2 = (event: any) => {
    setSelectedOption2(event.target.value);
  };

  useEffect(() => {
    if (active === 0) {
      setTimeout(() => {
        openFile()
      }, 10)
    }
  }, [active])

  return (
    <>
      <Box pt={4}>
        <PlanningFilters active={active} setActive={setActive} />
        {
          active === 0 ? (
            <>
              <Flex align="center" justifyContent={'space-between'}>
                <Box display={'flex'}>
                  <Select
                    placeholder="Versiones disponibles"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    bg="white"
                    borderRadius="md"
                    mr={4}
                    w={'auto'}
                  >
                    {
                      selectedPlanning?.budgetCardList?.map((e, index) => (
                        <option key={e.fileId} value={`${e.fileId}`}>Ver.{index + 1} {DatesUtil.formatDateFiles(e.createdDate!)}</option>
                      ))
                    }
                  </Select>
                  <Select
                    placeholder="Seleccione una opción"
                    value={selectedOption2}
                    onChange={handleSelectChange2}
                    bg="white"
                    borderRadius="md"
                    mr={4}
                    w={'auto'}
                  >
                    <option value="payType1">Tipo de pago 1</option>
                    <option value="payType2">Tipo de pago 2</option>
                    <option value="payType3">Tipo de pago 3</option>
                  </Select>
                </Box>
                <Box display={'flex'} gap={2}>
                  <Button leftIcon={<BiClipboard />} variant='outline' onClick={() => openModal('BinnacleModal')}>
                    Bitácora
                  </Button>
                  {/* <Button leftIcon={!budgetCardSelected ? <BiCheck /> : <MdFileUpload />} onClick={() => openModal('AddBudgetCardModal')} colorScheme="brand"> */}
                  <Button leftIcon={<MdFileUpload />} onClick={() => openModal('AddBudgetCardModal')} colorScheme="brand">{/* </Button> */}
                    {/* {!budgetCardSelected ? 'Aprobar' : 'Cargar documento'} */}
                    Cargar documento
                  </Button>
                </Box>
              </Flex>
              <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                <Button onClick={() => save()} leftIcon={<BiDownload />}>
                  Descargar
                </Button>
                <Button onClick={() => openModal('SaveExcelFileAlert')} leftIcon={<BiSave />} >
                  Guardar cambios
                </Button>
              </Box>
              <ExcelTable />
              <AddBudgetCardModal />
              <SaveExcelFileAlert />
              <BinnacleModal /></>
          ) :
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={4}>
              {
                budgetData.map((e, index) => (
                  <BudgetCards key={index} data={e} />
                ))
              }
            </SimpleGrid>
        }
      </Box>
    </>
  )
}

export default BudgetCardPage