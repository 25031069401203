import { Apis } from "@/apis";
import HttpClient from "@/core/http/http-client";
import { GenericApiResponse } from "@/core/models/generic-api-response";

export class PermissionService {
    /** */
    private http = new HttpClient( `${Apis.ORKESTADOC}/api/v1/permission` )

    public getPermissions(): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( '' )
    }

    public deletePermission( permissionId: string ): Promise<GenericApiResponse> {
        return this.http.delete<GenericApiResponse>( `/id/${permissionId}` )
    }

}