import { AreaService } from "@/core/services/area.service"
import { StateCreator, create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { Area } from "../models/area.model"

/** Services */
const areaService = new AreaService()

/** Contrato del Store */
interface AreaState {
    areaItems: Area[]
    selectedArea?: Area
    retrieveAreaItems: ( companyId: string ) => Promise<boolean>
    addAreaItem: ( item: Area ) => Promise<boolean>
    updateAreaItem: ( item: Area ) => Promise<boolean>
    setSelectedArea: ( item: Area ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    areaItems: [],
    selectedArea: undefined
}

/** Definición del Store */
const Store: StateCreator<AreaState> = ( set, get ) => ({

    ...initialState,

    retrieveAreaItems: ( companyId: string ) => {
        return new Promise( ( resolve, reject ) => {
            areaService.getAreasByCompany( companyId ).then(
                response => {
                    set({ areaItems: response.respuesta })
                    resolve( true )
                }
            ).catch( ( error ) => {
                get().reset()
            })
        })
    },

    addAreaItem: ( item: Area ) => {
        return new Promise( ( resolve, reject ) => {
            areaService.createArea( item ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newItem = response.respuesta
                        const items = get().areaItems

                        items.push( newItem )

                        set({ areaItems: items })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    updateAreaItem: ( item: Area ) => {
        return new Promise( ( resolve, reject ) => {
            areaService.updateArea( item ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const updatedItem: Area = response.respuesta
                        const items = get().areaItems

                        items.map( item => {
                            if ( updatedItem.id === item.id ) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ areaItems: items })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    setSelectedArea: ( item: Area ) => set({ selectedArea: item }),

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAreaStore = create<AreaState>()(
    immer( Store )
)