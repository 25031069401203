import { useModalStore } from "@/core/stores/modal.store"
import { useAdminUserGroupStore } from "../../stores/admin-user-group.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useFormik } from "formik"
import * as Yup from "yup"
import { UserGroupRequest } from "../../models/user-group-request.model"
import { useUserStore } from "@/modules/auth/stores/user.store"
import CustomModal from "@/core/components/organism/CustomModal"
import { FcPodiumWithAudience } from "react-icons/fc"
import { useEffect, useState } from "react"
import { Divider, FormControl, FormErrorMessage, Input, InputGroup } from "@chakra-ui/react"
import UsersGrid from "./UsersGrid"
import { AdminUser } from "../../models/admin-user.model"

const CreateOrEditUserGroupModal: React.FC = () => {
    /** */
    const selectedUserGroup = useAdminUserGroupStore( s => s.selectedUserGroup )
    const { addUserGroup, updateUserGroup, deselectedUserGroup } = useAdminUserGroupStore()
    const openModal = useModalStore( s => s.openModal )
    const closeModal = useModalStore( s => s.closeModal )
    const companyData = useUserStore( s => s.companyData )
    const [ modalTitle, setModalTitle ] = useState<string>( '' )
    const toast = useCustomToast()

    const createOrEditUserGroup = () => {
        /** */
        selectedUserGroup
            ? handleEdit()
            : handleCreate()
    }

    const generateRequest = () => {
        return {
            id: selectedUserGroup?.id || null,
            companyId: companyData?.id!,
            name: userGroupForm.values.name,
            description: userGroupForm.values.description,
            userList: []
        } as UserGroupRequest
    }

    const handleCreate = () => {
        /** */
        const body = generateRequest()

        addUserGroup( body ).then(
            () => {
                toast.success( 'Grupo creado correctamente' )
                closeModal( 'createOrEditUserGroupModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al crear el grupo' )
        )
    }

    const handleEdit = () => {
        /** */
        const body = generateRequest()

        body.userList = selectedUserGroup?.userList!

        updateUserGroup( body ).then(
            () => {
                toast.success( 'Grupo actualizado correctamente' )
                closeModal( 'createOrEditUserGroupModal' )
            }
        ).catch(
            () => toast.error( 'Ocurrió un error al actualizar el grupo' )
        )
    }

    const handleAddUser = ( user: AdminUser ) => {
        selectedUserGroup?.userList.push( user.id )
        handleEdit()
    }

    const userGroupForm = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required( "Este campo es requerido" ),
            description: Yup.string().required( "Este campo es requerido" )
        }),
        onSubmit: createOrEditUserGroup
    })

    const handleCloseModal = () => {
        deselectedUserGroup()
        userGroupForm.resetForm()
    }

    const handleDeleteUser = ( user: AdminUser ) => {
        openModal( 'confirmUserDeletionOnGroup', user )
    }

    useEffect( () => {
        setModalTitle(
            selectedUserGroup ? 'Editar grupo' : 'Nuevo grupo'
        )

        if ( selectedUserGroup ) {
            userGroupForm.setFieldValue( 'name', selectedUserGroup.name )
            userGroupForm.setFieldValue( 'description', selectedUserGroup.description )
        } else {
            userGroupForm.setFieldValue( 'name', '' )
            userGroupForm.setFieldValue( 'description', '' )
        }

        // eslint-disable-next-line
    }, [selectedUserGroup])

    return (
        <CustomModal id="createOrEditUserGroupModal"
            size="4xl"
            icon={FcPodiumWithAudience}
            title={modalTitle}
            acceptText={selectedUserGroup ? 'Actualizar' : 'Guardar'}
            closeOnAccept={false}
            onCloseComplete={handleCloseModal}
            onAccept={userGroupForm.handleSubmit}
        >
            {() => (
                <form onSubmit={userGroupForm.handleSubmit} className="flex flex-col gap-2">
                    {/* Nombre del grupo */}
                    <FormControl isInvalid={!!userGroupForm.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            Nombre del grupo
                        </label>
                        <InputGroup>
                            <Input
                                id="name"
                                name="name"
                                type="name"
                                onChange={userGroupForm.handleChange}
                                value={userGroupForm.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{userGroupForm.errors.name}</FormErrorMessage>
                    </FormControl>
                    {/* Descripción del grupo */}
                    <FormControl isInvalid={!!userGroupForm.errors.description}>
                        <label htmlFor="description" className="text-sm leading-8">
                            Descripción del grupo
                        </label>
                        <InputGroup>
                            <Input
                                id="description"
                                name="description"
                                type="description"
                                onChange={userGroupForm.handleChange}
                                value={userGroupForm.values.description}
                            />
                        </InputGroup>
                        <FormErrorMessage>{userGroupForm.errors.description}</FormErrorMessage>
                    </FormControl>
                    {/* Users */}
                    <Divider my={2} />
                    {/* <UsersGrid
                        pagesPerView={5}
                        selectedUserIds={selectedUserGroup ? selectedUserGroup.userList : undefined}
                        onDelete={user => handleDeleteUser( user )}
                        onAddUser={ user => handleAddUser( user )}
                    /> */}
                    {(selectedUserGroup && selectedUserGroup.userList.length > 0 )
                        && (
                            <UsersGrid
                                pagesPerView={5}
                                selectedUserIds={selectedUserGroup ? selectedUserGroup.userList : undefined}
                                onDelete={user => handleDeleteUser( user )}
                                onAddUser={ user => handleAddUser( user )}
                            />
                        )
                    }
                </form>
            )}
        </CustomModal>
    )
}

export default CreateOrEditUserGroupModal