import { StateCreator, create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import GC from '@mescius/spread-sheets';
import { saveAs } from 'file-saver';

/** Utilidades */
const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));
const getFileType = (file: File): 'xlsx' | 'csv' | 'sjs' | 'ssjson' | 'unknown' => {
  const fileName = file.name.toLowerCase();
  if (fileName.endsWith('.xlsx')) return 'xlsx';
  if (fileName.endsWith('.csv')) return 'csv';
  if (fileName.endsWith('.sjs')) return 'sjs';
  if (fileName.endsWith('.ssjson')) return 'ssjson';
  return 'unknown';
};

/** Contrato del Store */
interface FileStoreState {
  selectedFile: File | null;
  openFileType: string;
  openOptions: { [key: string]: any };
  fileTypeOptions: { [key: string]: any };
  spread: GC.Spread.Sheets.Workbook | null;
  setSpread: (spread: GC.Spread.Sheets.Workbook) => void;
  setOpenOptions: (options: { [key: string]: any }) => void;
  setSelectedFile: (file: File) => void;
  setSelectedFileTable: (file: File) => void;
  openFile: () => void;
  save: () => void;
  mapExportFileType: () => void;
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  selectedFile: null,
  openFileType: '',
  openOptions: {
    sjs: {},
    ssjson: {},
    xlsx: {},
    csv: {},
  },
  spread: null,
  fileTypeOptions: {
    SJS: 'sjs',
    Excel: 'xlsx',
    SSJson: 'ssjson',
    Csv: 'csv',
  }
};

/** Definición del Store */
const Store: StateCreator<FileStoreState> = (set, get) => ({
  ...initialState,

  setSpread: (spread: GC.Spread.Sheets.Workbook) => set({ spread }),

  setOpenOptions: (options: { [key: string]: any }) => set({ openOptions: options }),

  setSelectedFile: (file: File) => {
    const fileType = getFileType(file);
    set({ selectedFile: file, openFileType: fileType });
  },

  openFile: () => {
    const { selectedFile, spread, openOptions } = get();

    if (!spread || !selectedFile) {
      return;
    }

    const fileType = getFileType(selectedFile);
    // @ts-ignore
    const options = deepClone(openOptions[fileType]);


    spread.import(selectedFile, () => { }, () => { }, options);
  },

  setSelectedFileTable: (file: File) => {
    const { spread, openOptions, setSelectedFile } = get();
    const fileType = getFileType(file);
    //@ts-ignore
    setSelectedFile(file)
    if (!spread || !fileType) {
      return;
    }
    const options = deepClone(openOptions[fileType]);
    spread.import(file, () => { }, () => { }, options);
  },
  
  mapExportFileType: () => {
    const { selectedFile, fileTypeOptions, spread } = get();
    if (!spread || !selectedFile) {
      return;
    }
    var fileType = getFileType(selectedFile);
    if (fileType === fileTypeOptions.Csv) {
      return GC.Spread.Sheets.FileType.csv;
    }
    return GC.Spread.Sheets.FileType.excel;
  },

  save: () => {
    const { selectedFile, spread, openOptions, mapExportFileType } = get();
    /* if (!spread || !selectedFile) {
      return;
    } */
    var fileType = selectedFile ? getFileType(selectedFile) : 'xlsx';
    var fileName = `${selectedFile ? selectedFile.name : 'presupuesto'}.${fileType}`;
    const options = deepClone(openOptions[fileType]);
    options.fileType = mapExportFileType();
    spread!.export((blob: Blob) => {
      saveAs(blob, fileName);
    }, function () { }, options);
  },
  reset: () => set(initialState)
});

/** Exportación del Store */
export const useExcelTableStore = create<FileStoreState>()(
  immer(Store),
);