import { Center } from "@chakra-ui/react"
import { useLoaderStore } from "@/core/stores/loader.store"
import isotypeOm from "@/assets/images/identity/isotype-om.svg"

const Loader: React.FC = () => {
    /** */
    const isLoadign = useLoaderStore( s => s.isLoading() )

    if ( !isLoadign ) return null

    return (
        <Center position="fixed" top="0" left="0" width="100vw" height="100vh"
            zIndex={1500}
            backgroundColor="rgba(255, 255, 255, 0.5)">
            <div className="flex flex-col items-center gap-2">
                <img src={isotypeOm} alt="Isotipo Orkestamedia animado"
                    className="animate-spin w-20"
                />
                <h1 className="text-2xl font-semibold">
                    Cargando...
                </h1>
            </div>
        </Center>
    )
}

export default Loader