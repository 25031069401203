import { useFormik } from "formik";
import CustomModal from "../../../core/components/organism/CustomModal"
import { Alert, AlertIcon, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement, Select, Text, useBoolean } from "@chakra-ui/react";
import { CalendarIcon, EditIcon } from "@chakra-ui/icons";
import { useModalStore } from "@/core/stores/modal.store";
import { FcParallelTasks } from "react-icons/fc";
import { useEffect, useState } from "react";
import { Task } from "gantt-task-react";
import { TaskType } from "gantt-task-react/dist/types/public-types";
import { BsPercent } from "react-icons/bs";
import * as Yup from "yup"
import { DatesUtil } from "@/core/utils/dates.util";
import { usePlanningStore } from "../store/planning.store";

const AddTaskModal: React.FC = () => {
  const { setTasksRoadMapselect, tasksRoadmapSelected } = usePlanningStore();
  const closeModal = useModalStore(s => s.closeModal)
  const [selectedOption, setSelectedOption] = useState('');
  const [taskProject, setTaskProject] = useState<Task[]>();
  const [projectSelected, setProjectSelect] = useState<string>('');
  const [dateError, setDateError] = useState('');

  const handleSelectChange = (e: any) => {
    taskForm.values.activityType = e.target.value;
    setSelectedOption(e.target.value);
    const projectList = tasksRoadmapSelected.filter(e => e.type === 'project');
    setTaskProject(projectList);
  }

  const getDisplayOrder = () => {
    const projectList = tasksRoadmapSelected.filter(e => e.type === 'project');
    if (taskForm.values.activityType === 'project') {
      return projectList.length === 0 ? 1 : ((projectList[projectList.length - 1].displayOrder ?? 0) + 1000)
    } else {
      const projectData = tasksRoadmapSelected.filter((e) => e.id === projectSelected)[0]
      const tasksProject = tasksRoadmapSelected.filter((e) => e.project === projectSelected);
      return tasksProject.length === 0 ? (projectData.displayOrder ?? 0) + 1 : (projectData.displayOrder ?? 0) + tasksProject.length + 1
    }
  }

  const clearForm = () => {
    taskForm.resetForm()
    setSelectedOption('');
    setProjectSelect('');
    setDateError('')
  }

  const createTast = () => {
    const initialDate = new Date(taskForm.values.initialDate)
    const endDate = new Date(taskForm.values.endDate)
    if (initialDate > endDate) {
      setDateError('La fecha inicial es mayor que la fecha final')
      return
    }
    if (taskForm.values.activityType === 'task') {
      const tasksProject = tasksRoadmapSelected.filter((e) => e.project === projectSelected).filter((e) => e.name === taskForm.values.name);  
      const project = tasksRoadmapSelected.filter((e) => e.id === projectSelected)[0]
      if(tasksProject.length !== 0) {
        setDateError('Ya existe una tarea con el mismo nombre dentro del proyecto seleccionado')
        return
      }
      if (initialDate.toISOString().split('T')[0] < project.start.toISOString().split('T')[0] || endDate.toISOString().split('T')[0] > project.end.toISOString().split('T')[0]) {
        setDateError('Las fechas de la tarea están fuera del rango del proyecto seleccionado')
        return
      }
      console.log(project, taskForm.values.initialDate, taskForm.values.endDate)
    } else {
      console.log(tasksRoadmapSelected, projectSelected)
      const project = tasksRoadmapSelected.filter((e) => e.name === taskForm.values.name)
      if(project.length !== 0) {
        setDateError('Ya existe un proyecto con el mismo nombre')
        return
      }
    }

    const task: Task = {
      ...{
        start: DatesUtil.formatStringToDate(taskForm.values.initialDate),
        end: DatesUtil.formatStringToDate(taskForm.values.endDate),
        name: taskForm.values.name,
        id: taskForm.values.activityType !== 'project' ? `${projectSelected}${taskForm.values.name}id` : `${taskForm.values.name}id`,
        progress: taskForm.values.progress,
        type: taskForm.values.activityType as TaskType,
        styles: { backgroundColor: taskForm.values.activityType !== 'project' ? 'rgba(3, 68, 220, 1)' : 'rgba(49, 151, 149, 1)', backgroundSelectedColor: taskForm.values.activityType !== 'project' ? 'rgba(3, 68, 220, .8)' : 'rgba(49, 151, 149, .8)' },
      },
      ...(taskForm.values.activityType !== 'project' ? { project: `${projectSelected}`, displayOrder: getDisplayOrder() ?? 0 } : { hideChildren: false, displayOrder: getDisplayOrder() ?? 0 })
    }

    const body = [
      ...tasksRoadmapSelected
    ].concat(task)

    setTasksRoadMapselect(body);

    clearForm();
    closeModal('addTaskModal')
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('El nombre de la actividad es requerido'),
    initialDate: Yup.date().required('La fecha inicial de la tarea es requerida'),
    endDate: Yup.date().required('La fecha final de la tarea es requerida'),
    activityType: Yup.string().required('Seleccione un tipo de actividad'),
  });
  const taskForm = useFormik({
    onSubmit: createTast,
    validateOnBlur: true,
    initialValues: { name: '', initialDate: '2024-01-01', endDate: '2024-01-01', activityType: '', progress: 0 },
    validationSchema
  })

  useEffect(() => {
    setDateError('')
  }, [])

  return (
    <CustomModal id="addTaskModal" title="Agregar actividad" size="md" withButtons={true} showCancelButton={true}
      onCancel={() => clearForm()}
      onAccept={taskForm.handleSubmit}
      closeOnAccept={false}
      onClose={() => clearForm()}
      cancelText="Cancelar"
      acceptText="Agregar actividad"
      icon={FcParallelTasks}
    >
      {() => (
        <form onSubmit={taskForm.handleSubmit}>
          <FormControl mb={2} isInvalid={!!taskForm.errors.activityType}>
            <FormLabel mb={0}>Tipo de actividad</FormLabel>
            <Select
              placeholder="Seleccionar un tipo"
              value={selectedOption}
              onChange={handleSelectChange}
              bg="white"
              borderRadius="md"
              w={'auto'}
            >
              <option value="project">Proyecto</option>
              <option value="task">Tarea</option>
            </Select>
            <FormErrorMessage>{taskForm.errors.activityType}</FormErrorMessage>
          </FormControl>

          {selectedOption === 'task' &&
            <FormControl mb={2}>
              <FormLabel mb={0}>Proyecto relacionado</FormLabel>
              <Select
                placeholder="Seleccione proyecto"
                value={projectSelected}
                onChange={(e) => setProjectSelect(e.target.value)}
                bg="white"
                borderRadius="md"
                w={'auto'}
              >
                {taskProject?.map((e, index) => (
                  <option key={index} value={e.id}>{e.name}</option>
                ))}
              </Select>
            </FormControl>
          }
          <FormControl mb={2} isInvalid={!!taskForm.errors.name}>
            <FormLabel mb={0}>Nombre de {taskForm.values.activityType === 'project' ? 'proyecto' : 'tarea'}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EditIcon color="gray.300" />
              </InputLeftElement>
              <Input id="name" type="text" placeholder={`Nombre de ${taskForm.values.activityType === 'project' ? 'proyecto' : 'tarea'}`}
                onChange={taskForm.handleChange}
                value={taskForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.initialDate}>
            <FormLabel mb={0}>Fecha inicial</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="initialDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.initialDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.initialDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.endDate}>
            <FormLabel mb={0}>Fecha final</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="endDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.endDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.endDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.progress}>
            <FormLabel mb={0}>Progreso de actividad</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsPercent color="gray.300" />
              </InputLeftElement>
              <Input id="progress" type="number" placeholder="0" min={0} max={100}
                onChange={taskForm.handleChange}
                value={taskForm.values.progress}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.progress}</FormErrorMessage>
          </FormControl>
          {dateError && <Alert status='error'>
            <AlertIcon />
            {dateError}
          </Alert>}

        </form>
      )}
    </CustomModal>
  )
}

export default AddTaskModal