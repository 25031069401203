import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { AdminUser } from "../../models/admin-user.model"
import { useAdminUserStore } from "../../stores/admin-user.store"
import useCustomToast from "@/core/hooks/useCustomToast"

const ConfirmUserDeletionAlert: React.FC = () => {
    /** */
    const selectedUser = useAdminUserStore( s => s.selectedUser )
    const { removeUser, deselectedUser } = useAdminUserStore()
    const toast = useCustomToast()

    const handleDeleteUser = () => {
        removeUser( selectedUser?.id! ).then(
            () => toast.success( 'Usuario eliminado correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al intentar eliminar el usuario' )
        )
    }

    return (
        <CustomAlertDialog
            id="confirmUserDeletionAlert"
            title="Eliminar usuario"
            size="xl"
            cancelText="Cancelar"
            acceptText="Estoy de acuerdo"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onAccept={handleDeleteUser}
            onCloseComplete={deselectedUser}
        >
            {( data?: AdminUser ) => (
                <>
                    <p className="text-lg">
                        Se eliminará el usuario <strong className="text-brand-500">{data?.fullName}</strong>. ¿Estás de acuerdo?
                    </p>
                </>
            )}
        </CustomAlertDialog>
    )
}

export default ConfirmUserDeletionAlert