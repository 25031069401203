import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { TbBrandOauth, TbCalendarMonth  } from "react-icons/tb"

const ConfigTabs: React.FC = () => {
    /** */
    const navigate = useNavigate()
    const location = useLocation()

    const paths = [
        '/views/admin/config/authentication',
        '/views/admin/config/calendar'
    ]

    const getCurrentIndex = () => {
        const path = paths.find( path => location.pathname.startsWith( path ) )

        return path ? paths.indexOf( path ) : 0
    }

    const currentIndex = getCurrentIndex();

    const handleTabsChange = ( index: number ) => {
        navigate( paths[ index ] )
    }

    return (
        <Tabs index={currentIndex}
            variant="unstyled"
            position="relative"
            colorScheme="brand"
            onChange={handleTabsChange}
        >
            <TabList>
                <Tab className="flex items-center gap-1">
                    <TbBrandOauth />
                    <span>Autenticación</span>
                </Tab>
                <Tab className="flex items-center gap-1">
                    <TbCalendarMonth />
                    <span>Calendario</span>
                </Tab>
            </TabList>
            <TabIndicator />
        </Tabs>
    )
}

export default ConfigTabs