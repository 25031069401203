import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { SecureSessionStorage } from "../../../core/stores/secure-sessionstorage.store";
import { BudgetCard, Planning, RoadMap, SubSectionList } from "../../../core/models/planning.model";
import { Task } from "gantt-task-react";
import { PlanningService } from "../services/planning.service";

/** Services */
const planningService = new PlanningService()

/** Contrato del Store */
interface PlanningState {
    planningItems: Planning[]
    selectedPlanning?: Planning
    roadMapSelected?: string
    tasksRoadmapSelected: Task[]
    budgetCardSelected?: File;
    retrievePlanningItems: (sectionId: string) => Promise<boolean>
    addPlanningItem: (item: Planning) => Promise<boolean>
    updatePlanningItem: (item: Planning) => Promise<boolean>
    updateRoadMapPlanningItem: (planningSectionId: string, item: RoadMap[]) => Promise<boolean>
    updateBudgetCardPlanningItem: (planningSectionId: string, item: BudgetCard[]) => Promise<boolean>
    createBudgetCardDocument: (planningSectionId: string, idFile: string) => Promise<boolean>
    setSelectedPlanning: (item: Planning) => void
    setRoadMapSelect: (roadmap: string) => void
    setTasksRoadMapselect: (tasks: Task[]) => void
    setBudgetCardSelect: (file: File) => void
    updatePlanningSubSectionList: (planningSectionId: string, items: SubSectionList) => Promise<boolean>
    getSubSectionItemBy: (key: string) => SubSectionList | undefined
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    planningItems: [],
    tasksRoadmapSelected: [],
    roadMapSelected: '',
    selectedPlanning: undefined,
    budgetCardSelected: undefined
}

/** Definición del Store */
const Store: StateCreator<PlanningState> = (set, get) => ({

    ...initialState,

    retrievePlanningItems: (sectionId: string) => {
        return new Promise((resolve, reject) => {
            planningService.getPlanningBySection(sectionId).then(
                response => {
                    set({ planningItems: response.respuesta })
                    resolve(true)
                }
            ).catch(() => {
                get().reset()
                reject()
            })
        })
    },

    addPlanningItem: (item: Planning) => {
        return new Promise((resolve, reject) => {
            planningService.createPlanning(item).then(
                response => {
                    if (response.codigo === 0) {
                        const newItem = response.respuesta

                        const items = get().planningItems

                        items.push(newItem)

                        set({ planningItems: items })

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    updatePlanningItem: (item: Planning) => {
        return new Promise((resolve, reject) => {
            planningService.updatePlanning(item).then(
                response => {
                    if (response.codigo === 0) {
                        const updatedItem: Planning = response.respuesta
                        const items = get().planningItems

                        items.map(item => {
                            if (updatedItem.id === item.id) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ planningItems: items, selectedPlanning: updatedItem })

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    updateRoadMapPlanningItem: (planningSectionId: string, item: RoadMap[]) => {
        return new Promise((resolve, reject) => {
            planningService.updateRoadMapPlanning(planningSectionId, item).then(
                response => {
                    if (response.codigo === 0) {
                        const updatedItem: Planning = response.respuesta
                        const items = get().planningItems

                        items.map(item => {
                            if (updatedItem.id === item.id) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ planningItems: items, selectedPlanning: updatedItem })

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    updateBudgetCardPlanningItem: (planningSectionId: string, item: BudgetCard[]) => {
        return new Promise((resolve, reject) => {
            planningService.updateBudgetCardPlanning(planningSectionId, item).then(
                response => {
                    if (response.codigo === 0) {
                        const updatedItem: Planning = response.respuesta
                        const items = get().planningItems

                        items.map(item => {
                            if (updatedItem.id === item.id) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ planningItems: items, selectedPlanning: updatedItem })

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    createBudgetCardDocument: (planningSectionId: string, idFile: string) => {
        return new Promise((resolve, reject) => {
            planningService.budgetCardVault(planningSectionId, idFile).then(
                response => {
                    if (response.codigo === 0) {
                        const updatedItem: Planning = response.respuesta
                        const items = get().planningItems

                        items.map(item => {
                            if (updatedItem.id === item.id) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ planningItems: items, selectedPlanning: updatedItem })

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    updatePlanningSubSectionList: (planningSectionId: string, items: SubSectionList) => {
        return new Promise((resolve, reject) => {
            planningService.updatePlanningSubSection(planningSectionId, items).then(
                response => {
                    if (response.codigo === 0) {
                        const updatedItem: Planning = response.respuesta
                        const items = get().planningItems

                        items.map(item => {
                            if (updatedItem.id === item.id) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ planningItems: items, selectedPlanning: updatedItem })
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            ).catch(() => reject())
        })
    },

    getSubSectionItemBy: (key: string) => get().selectedPlanning?.subSectionList?.find(
        item => item.type === key
    ),

    setSelectedPlanning: (item: Planning) => set({ selectedPlanning: item }),

    setRoadMapSelect: (roadmap: string) => set({ roadMapSelected: roadmap }),

    setTasksRoadMapselect: (tasks: Task[]) => set({ tasksRoadmapSelected: tasks }),

    setBudgetCardSelect: (file?: File) => set({ budgetCardSelected: file }),

    reset: () => set(initialState)

})

/** Exportación del Store */
export const usePlanningStore = create<PlanningState>()(
    persist(
        immer(Store),
        {
            name: 'planning-store',
            storage: SecureSessionStorage
        }
    )
)