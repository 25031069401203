import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react"
import { useModalStore } from "@/core/stores/modal.store"
import React from "react"

interface CustomAlertDialogProps {
    id: string
    title: string
    size?: string
    acceptText?: string
    cancelText?: string
    onAccept?: () => void
    onCancel?: () => void
    showCancelButton?: boolean
    closeOnOverlayClick?: boolean
    closeOnEsc?: boolean
    onCloseComplete?: () => void 
    children: ( data?: any ) => React.ReactNode
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({
    id,
    title,
    size = 'md',
    acceptText = "Accept",
    cancelText = "Cancel",
    onAccept,
    onCancel,
    showCancelButton = true,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    onCloseComplete,
    children
}) => {
    /** */
    const { modals, closeModal } = useModalStore()
    const modal = modals[id]
    const cancelRef = React.useRef<HTMLButtonElement>( null )

    const handleComplete = () => {
        onCloseComplete && onCloseComplete()
    }

    const handleClose = () => {
        closeModal( id )
        onCancel && onCancel()
    }

    const handleAccept = () => {
        onAccept && onAccept()
        closeModal( id )
    }

    return (
        <AlertDialog
            size={size}
            isOpen={modal?.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleClose}
            closeOnOverlayClick={closeOnOverlayClick}
            closeOnEsc={closeOnEsc}
            onCloseComplete={handleComplete}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>{title}</AlertDialogHeader>
                    <AlertDialogBody>{children(modal?.data)}</AlertDialogBody>
                    <AlertDialogFooter>
                        {showCancelButton && (
                            <Button colorScheme="gray" ref={cancelRef} onClick={handleClose} mr={3}>
                                {cancelText}
                            </Button>
                        )}
                        <Button onClick={handleAccept}>
                            {acceptText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default CustomAlertDialog