import React from "react";
import { useModalStore } from "@/core/stores/modal.store";
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface CustomDrawerProps {
  id: string;
  title?: string;
  size: string;
  icon?: IconType;
  closeOnOverlayClick?: boolean;
  closeOnEsc?: boolean;
  withButtons?: boolean;
  cancelText?: string;
  acceptText?: string;
  onClose?: () => void;
  onCloseComplete?: () => void;
  onAccept?: () => void;
  onCancel?: () => void;
  closeOnCancel?: boolean;
  closeOnAccept?: boolean;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  centerButtons?: boolean;
  showOverlay?: boolean;
  children: (data?: any) => React.ReactNode;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  id,
  title,
  size,
  icon: Icon,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  withButtons = true,
  cancelText = 'Cancelar',
  acceptText = 'Aceptar',
  onClose,
  onCloseComplete,
  onAccept,
  onCancel,
  closeOnCancel = true,
  closeOnAccept = true,
  showCancelButton = true,
  showCloseButton = true,
  centerButtons = false,
  showOverlay = true,
  children
}) => {
  const { modals, closeModal } = useModalStore();
  const modal = modals[id];

  const handleClose = () => {
    onCancel && onCancel();
    closeOnCancel && closeModal(id);
  };

  const handleAccept = () => {
    onAccept && onAccept();
    closeOnAccept && closeModal(id);
  };

  return (
    <Drawer
      isOpen={modal?.isOpen}
      placement="right"
      onClose={() => { closeModal(id); onClose && onClose(); }}
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      onCloseComplete={onCloseComplete}
    >

      {showOverlay && <DrawerOverlay />}

      <DrawerContent>
        {showCloseButton && (
          <DrawerCloseButton />
        )}
        {title && (
          <DrawerHeader className="flex items-center gap-2">
            {Icon && <Icon />}
            <span>{title}</span>
          </DrawerHeader>
        )}
        <DrawerBody>
          <div className={`${!withButtons ? 'pb-4' : ''}`}>
            {children(modal?.data)}
          </div>
        </DrawerBody>
        {withButtons && (
          <DrawerFooter>
            <div className={`w-full flex gap-6 ${centerButtons ? 'justify-center' : 'justify-end'}`}>
              {showCancelButton && (
                <Button variant='outline' onClick={handleClose}>
                  {cancelText}
                </Button>
              )}
              <Button onClick={handleAccept}>
                {acceptText}
              </Button>
            </div>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default CustomDrawer;
