import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "@/modules/auth/stores/auth.store";

const AuthLayout: React.FC = () => {
    /** */
    const authStatus = useAuthStore( s => s.status )

    if ( authStatus === 'authorized' ) {
        return <Navigate to='/views' />
    }

    return (
        <Outlet />
    );
}

export default AuthLayout