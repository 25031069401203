import { Apis } from "@/apis";
import HttpClient from "../http/http-client";
import { GenericApiResponse } from "../models/generic-api-response";

export class UtilService {
    /** */
    private http = new HttpClient( `${Apis.ORKESTAMEDIA}/api/v1/util` )
    private httpBase = new HttpClient( `${Apis.BASE}/api/v1/general/vault` )

    public uploadFile( formData: FormData ): Promise<GenericApiResponse> {
        const headers: any = {
            'Content-Type': 'multipart/form-data',
        }

        return this.http.post( '/uploadToSession', formData, { headers } )
    }
}