import CustomAlertDialog from '@/core/components/organism/CustomAlertDialog'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { useConciliationStore } from '../stores/conciliation.store'
import { ConciliationBilling } from '../models/conciliation.model'

function BillingapproveModal() {
  const { selectedBillingMonth, SelectedBillingProvider, conciliationBilling, setSelectedBillings, setSelectedBillingMonth, setSelectedBillingProvider } = useConciliationStore();
  const approveRequirements = ['Orden de compra cerrada', 'Factura', 'Carta de conciliación', 'Testigos', 'Información fiscal']
  const handleClosePlanning = () => {
    const datamonth = {
      ...selectedBillingMonth,
      status: 'Factura aprobada'
    }
    const dataProv = SelectedBillingProvider?.billingsByMonth.map(e => e.month === datamonth.month ? datamonth : e)
    const data = conciliationBilling.map(e => e.provider === SelectedBillingProvider?.provider ? {
      ...SelectedBillingProvider,
      billingsByMonth: dataProv
    } : e)
    console.log(data, conciliationBilling, 'asdsd')
    setSelectedBillings(data as ConciliationBilling[])
    setTimeout(() => {
      setSelectedBillingProvider(SelectedBillingProvider?.provider!)
      setSelectedBillingMonth(selectedBillingMonth?.month!)
    }, 100);
  }

  return (
    <CustomAlertDialog
      id="Billingapprove"
      title={`Aprobar factura`}
      cancelText="Cancelar"
      acceptText="Aprobar factura"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleClosePlanning}
    >
      {() => (
        <Box>
          <Text mb={1}>La factura del proveedor se validó correctamente</Text>
          {
            approveRequirements.map((e, index) => (
              <Flex key={index} color='green' alignItems='center' columnGap={1} mb={1}>
                <IoMdCheckmarkCircleOutline /><Text>{e}</Text>
              </Flex>
            ))
          }
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default BillingapproveModal