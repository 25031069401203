import React, { useState } from 'react';
import { Select, Button, Flex, Box, background, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { LuList, LuPieChart } from "react-icons/lu";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import SearchInput from '@/core/components/molecules/SearchInput';
import { SearchIcon } from '@chakra-ui/icons';


interface PlanningFiltersProps {
  active: number;
  setActive: (active: number) => void
}

const PlanningFilters: React.FC<PlanningFiltersProps> = ({ active, setActive }) => {
  const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  return (
    <Flex alignItems="center" gap={4} w={'100%'} justifyContent='space-between' mb={4}>
      <Flex gap={4}>
        <Button className={active === 0 ? 'opacity-100' : 'opacity-50'} borderWidth={2} variant='outline' onClick={() => setActive(0)}>
          <LuList className='w-5 h-5' />
        </Button>
        <Button className={active === 1 ? 'opacity-100' : 'opacity-50'} borderWidth={2} variant='outline' onClick={() => setActive(1)}>
          <HiOutlineSquares2X2 className='w-5 h-5' />
        </Button>
      </Flex>
      <Flex gap={4} w={'100%'}>
        <Box w={'30%'}>
          <Select placeholder="Ordenar por" variant={'white'}>
            <option value="mensual">Filtro</option>
          </Select>
        </Box>
        <Box w={'100%'}>
          <InputGroup>
            <Input placeholder={`Buscar`} />
            <InputRightElement children={<SearchIcon color="gray.300" />} />
          </InputGroup>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PlanningFilters;
