import { useNavigationStore } from "@/core/stores/navigation.store"
import CustomModal from "./CustomModal"
import { FcSettings } from "react-icons/fc"
import { useEffect, useRef, useState } from "react"
import { NavigationItem } from "../molecules/Navigation"
import { Input, Switch } from "@chakra-ui/react"
import { DataUtil } from "@/core/utils/data.util"
import { TbCircleCheck } from "react-icons/tb"
import { TbCircleX } from "react-icons/tb"
import { TbEdit } from "react-icons/tb"

const SectionsModal: React.FC = () => {
    /** */
    const navigationItems = useNavigationStore( s => s.navigationItems )
    const { updateNavigationItem, getNavigationItemBy } = useNavigationStore()

    const handleSave = ( key: string, newDisplayName: string ) => {
        const item = getNavigationItemBy( key )

        if ( item ) {
            item.displayName = newDisplayName

            updateNavigationItem( item )
        }
    }

    const handleToggleEnable = ( key: string ) => {
        const item = getNavigationItemBy( key )

        if ( item ) {
            item.enable = !item.enable

            updateNavigationItem( item )
        }
    }

    return (
        <CustomModal id="sectionsModal"
            title="Gestionar secciones"
            size="xl"
            icon={FcSettings}
            withButtons={false}
        >
            {() => (
                <>
                    {navigationItems.map( item => (
                        <EditableRow
                            key={item.id}
                            item={item}
                            onSave={handleSave}
                            onToggleEnable={handleToggleEnable}
                        />
                    ))}
                </>
            )}
        </CustomModal>
    )
}

interface EditableRowProps {
    item: NavigationItem
    onSave: ( key: string, displayName: string ) => void
    onToggleEnable: ( key: string ) => void
}

const EditableRow: React.FC<EditableRowProps> = ({ item, onSave, onToggleEnable }) => {
    /** */
    const [ isEditing, setIsEditing ] = useState( false )
    const [ displayName, setDisplayName ] = useState( item.displayName )
    const inputRef = useRef( null )

    useEffect( () => {
        if ( isEditing && inputRef.current ) {
            (inputRef.current as any).focus()
        }
    }, [isEditing])

    const handleSave = () => {
        onSave( item.sectionType, displayName )
        setIsEditing( false )
    }

    const handleCancel = () => {
        setDisplayName( item.displayName )
        setIsEditing( false )
    }

    return (
        <div className="flex items-center justify-between p-3 my-3 rounded-md bg-gray-100">
            {isEditing ? (
                <Input ref={inputRef}
                    className="mr-4"
                    size="sm"
                    bgColor="transparent"
                    value={DataUtil.capitalizeText( displayName )}
                    onChange={( e ) => setDisplayName( e.target.value )}
                />
            ) : (
                <span>
                    {DataUtil.capitalizeText( item.displayName )}
                </span>
            )}
            <div className="flex items-center gap-4">
                {isEditing ? (
                <>
                    <TbCircleCheck size={24} className="text-green-500 cursor-pointer" onClick={handleSave} />
                    <TbCircleX size={24} className="text-red-500 cursor-pointer" onClick={handleCancel} />
                </>
                ) : (
                    <TbEdit size={24} className="text-gray-600 cursor-pointer" onClick={() => setIsEditing( true )} />
                )}
                <Switch
                    isChecked={item.enable}
                    onChange={() => onToggleEnable( item.sectionType )}
                />
            </div>
        </div>
    )
}

export default SectionsModal