import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Company } from "../models/company";
import { OwnerCompany } from "../models/owner-company.model";
import { CompanyRequest } from "../models/company-request.model";
import { CompanyService } from "../services/company.service";
import { AssociateCompanyRequest } from "../models/associate-company-request.model";
import { UpdateCompany } from "../models/update-company.model";

/** Services */
const companyService = new CompanyService()

/** Contrato del Store */
interface AdminCompanyState {
    ownerCompanies: OwnerCompany[]
    allCompanies: Company[]
    selectedOwnerCompany?: OwnerCompany
    selectOwnerCompany: ( company: OwnerCompany ) => void
    deselectOwnerCompany: () => void
    retrieveOwnerCompanies: ( ownerCompanyId: string ) => Promise<boolean>
    retrieveAllCompanies: ( page?: number, size?: number ) => Promise<boolean>
    addToOwnerCompany: ( ownerCompanyId: string, company: CompanyRequest ) => Promise<boolean>
    createCompany: ( ownerCompanyId: string, companyRequest: UpdateCompany ) => Promise<boolean>
    updateCompany: ( ownerCompany: OwnerCompany, companyRequest: UpdateCompany ) => Promise<boolean>
    removeFromOwnerCompany: ( companyId: string ) => Promise<boolean>
    associateToCompany: ( body: AssociateCompanyRequest ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    ownerCompanies: [],
    allCompanies: [],
    selectedOwnerCompany: undefined
}

/** Definición del Store */
const Store: StateCreator<AdminCompanyState> = ( set, get ) => ({

    ...initialState,

    selectOwnerCompany: ( company: OwnerCompany ) => set({ selectedOwnerCompany: company }),

    deselectOwnerCompany: () => set({ selectedOwnerCompany: undefined }),

    retrieveOwnerCompanies: ( ownerCompanyId: string ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().ownerCompanies.length === 0 ) {
                companyService.getOwnerCompanies( ownerCompanyId ).then(
                    response => {
                        const data: OwnerCompany[] = response.respuesta
    
                        set({ ownerCompanies: data })
    
                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            }
        })
    },

    retrieveAllCompanies: ( page?: number, size?: number ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().allCompanies.length === 0 ) {
                companyService.getAllCompanies( page, size ).then(
                    response => {
                        const data: Company[] = response.content

                        data.map( item => {
                            if ( item.legalRepresentatives && item.legalRepresentatives[0] ) {
                                item.legalRepresentatives[0].email = item.user.username
                            }

                            return item
                        })

                        // const ownerCompanies = get().ownerCompanies
                        // const filteredData = data.filter(
                        //     item => !ownerCompanies.some(
                        //         element => item.id === element.foreignCompanyId
                        //     )
                        // )
    
                        // set({ allCompanies: filteredData })
                        set({ allCompanies: data })
    
                        resolve( true )
                    }
                ).catch( () => reject() )
            }
        })
    },

    addToOwnerCompany: ( ownerCompanyId: string, company: CompanyRequest ) => {
        return new Promise(( resolve, reject ) => {
            companyService.createAndAssociateCompany( company ).then(
                response => {
                    if ( response ) {
                        get().retrieveOwnerCompanies( ownerCompanyId )

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    createCompany: ( ownerCompanyId: string, companyRequest: UpdateCompany ) => {
        return new Promise(( resolve, reject ) => {
            companyService.createCompany( companyRequest ).then(
                response => {
                    get().retrieveOwnerCompanies( ownerCompanyId ).then(
                        () => get().retrieveAllCompanies()
                    )

                    resolve( true )
                }
            ).catch( () => reject() )
        })
    },

    updateCompany: ( ownerCompany: OwnerCompany, companyRequest: UpdateCompany ) => {
        return new Promise(( resolve, reject ) => {
            companyService.updateCompany( companyRequest ).then(
                response => {
                    if ( response.status ) {
                        // const updatedCompanies = get().ownerCompanies.map(
                        //     item => item.id === ownerCompany.id
                        //         ? { ...item, ...ownerCompany }
                        //         : item
                        // )

                        // set({ ownerCompanies: updatedCompanies })
                        get().retrieveOwnerCompanies( ownerCompany.id ).then(
                            () => get().retrieveAllCompanies()
                        )

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    removeFromOwnerCompany: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            companyService.deleteCompanyAssociation( companyId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const filteredOwnerCompanies = get().ownerCompanies.filter(
                            item => item.id !== companyId
                        )

                        set({ ownerCompanies: filteredOwnerCompanies })

                        set({ allCompanies: [] })

                        get().retrieveAllCompanies()

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    associateToCompany: ( body: AssociateCompanyRequest ) => {
        return new Promise(( resolve, reject ) => {
            companyService.associateToCompany( body ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newOwnerCompany: OwnerCompany = response.respuesta
                        const ownerCompanies = get().ownerCompanies
                        // const filteredAllCompanies = get().allCompanies.filter(
                        //     item => item.id !== body.foreignCompanyId
                        // )


                        set({ ownerCompanies: [ ...ownerCompanies, newOwnerCompany ] })
                        // set({ allCompanies: filteredAllCompanies })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( error => reject( error ) )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminCompanyStore = create<AdminCompanyState>()(
    immer( Store )
)