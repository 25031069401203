import { Apis } from "@/apis";
import HttpClient from "@/core/http/http-client";
import { GenericApiResponse } from "@/core/models/generic-api-response";

export class CalendarSerivce {
    /** */
    private http = new HttpClient( `${Apis.ORKESTADOC}/api/v1/calendar` )

    public getAllYearsHolidaysDays( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get( `/idCompany/${companyId}` )
    }

    public getHolidaysDaysByYear( companyId: string, year: number ): Promise<GenericApiResponse> {
        return this.http.get( `/idCompany/${companyId}/year/${year}` )
    }

    public addDateToCalendar( calendarId: string, date: string ): Promise<GenericApiResponse> {
        return this.http.patch( `/addDate/${calendarId}`, { date } )
    }

    public removeToCalendar( calendarId: string, date: string ): Promise<GenericApiResponse> {
        return this.http.patch( `/removeDate/${calendarId}`, { date } )
    }

}