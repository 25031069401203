import React from 'react';
import { Select, Box, Flex, Input, Text } from '@chakra-ui/react';
import { useBinnacleStore } from '@/core/stores/binnacle.store';

const FilterComponent: React.FC = () => {
  const { autors, versions, filters, setFilters } = useBinnacleStore();

  const handleFilters = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const {name, value} = e.target;
    const filter = {
      ...filters,
      [name]: value
    }
    setFilters(filter);
  }

  return (
    <>
      <Text fontWeight={'semibold'}>
        Filtrar por:
      </Text>
      <Flex alignItems="center" gap={4} marginBottom={3}>
        <Select placeholder="Versión" name='version' className="w-1/4" onChange={(e) => handleFilters(e)}>
          {versions?.map((version) => (
            <option key={version} value={version}>
              {version}
            </option>
          ))}
        </Select>
        <Select placeholder="Autor" name='autor' className="w-1/4" onChange={(e) => handleFilters(e)}>
          {autors?.map((autor) => (
            <option key={autor} value={autor}>
              {autor}
            </option>
          ))}
        </Select>
        <Box className="w-2/4">
          <Input type="date" name='date' placeholder="Start Date" onChange={(e) => handleFilters(e)} />
        </Box>
      </Flex>
    </>
  );
};

export default FilterComponent;
