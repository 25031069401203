import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Checkbox, Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react"
import { useEffect, useState } from "react"

interface MultiSelectProps {
    options: any[]
    selectedOptions: any[]
    keyProp: string,
    displayProp: string,
    placeholder?: string
    onSelect: ( options: any[] ) => void
}

const MultiSelect: React.FC<MultiSelectProps> = ({
    options,
    selectedOptions,
    keyProp,
    displayProp,
    placeholder = 'Selecciona una opción',
    onSelect
}) => {
    /** */
    const [ selectedValues, setSelectedValues ] = useState( selectedOptions || [] )

    const toggleOption = ( option: any ) => {
        const optionKey = option[keyProp]
        const isSelected = selectedValues.some( opt => opt[keyProp] === optionKey )
        let updatedSelection = []

        if ( isSelected ) {
            updatedSelection = selectedValues.filter( opt => opt[keyProp] !== optionKey )
        } else {
            updatedSelection = [ ...selectedValues, option ]
        }

        setSelectedValues( updatedSelection as [] )
        onSelect( updatedSelection as [] )
    }

    const handleCheckboxChange = ( option: any ) => ( event: any ) => {
        if ( event.target.checked ) {
          toggleOption( option )
        } else {
          const updatedSelection = selectedValues.filter( opt => opt[keyProp] !== option[keyProp] )

          setSelectedValues( updatedSelection )
          onSelect( updatedSelection )
        }
    }

    useEffect( () => {
        setSelectedValues( selectedOptions || [] )
    }, [ selectedOptions ] )

    return (
        <Menu closeOnSelect={false}>
            <MenuButton as={Button}
                variant="outline"
                width="100%"
                textAlign="left"
                borderColor="gray.500"
                color="gray.700"
                fontWeight="normal"
                _hover={{ borderColor: 'gray.800', color: 'gray.800' }}
                _focus={{ borderColor: 'gray.800', color: 'gray.800', bg: 'gray.50' }}
                rightIcon={<ChevronDownIcon />}>
                <Box
                    flex="1"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    {selectedValues.length > 0
                        ? selectedValues.map(opt => opt[displayProp]).join(', ')
                        : placeholder
                    }
                </Box>
            </MenuButton>
            <Portal>
                <MenuList
                    minWidth="300px"
                    width="100%"
                    maxWidth="600px"
                    maxHeight="300px"
                    overflow="auto"
                    zIndex={1600}
                >
                    {options.map(( option: any ) => (
                        <MenuItem key={option[keyProp]} width="100%">
                            <Checkbox className="w-full"
                                isChecked={selectedOptions.some( opt => opt[keyProp] === option[keyProp] )}
                                onChange={handleCheckboxChange( option )}
                            >
                                <Box
                                    whiteSpace="normal"
                                    wordBreak="break-word"
                                >
                                    {option[displayProp]}
                                </Box>
                            </Checkbox>
                        </MenuItem>
                    ))}
                </MenuList>
            </Portal>
        </Menu>
    )
}

export default MultiSelect