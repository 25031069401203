import { Navigate, RouteObject } from "react-router-dom"
import WitnessesPage from "../../components/WitnessesPage"
import BuyOrdersPage from "../../components/BuyOrdersPage"
import ConciliationLetterPage from "../../components/ConciliationLetterPage"
import BillingPage from "../../components/BillingPage"

const PlanningSectionRoutes: RouteObject[] = [
    { path: 'witnesses', element: <WitnessesPage /> },
    { path: 'buy-order', element: <BuyOrdersPage /> },
    { path: 'conciliation-letter', element: <ConciliationLetterPage /> },
    { path: 'billing', element: <BillingPage /> },
    /* {
        path: 'flow',
        element: <FlowSectionLayout />, 
        children: [
          { index: true, element: <Navigate to="summary" replace /> },
          ...CampaignSectionRoutes
        ]
      }, */
]

export default PlanningSectionRoutes