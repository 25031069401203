import { Apis } from "@/apis";
import HttpClient from "@/core/http/http-client";
import { GenericApiResponse } from "@/core/models/generic-api-response";
import { AdminPolicy } from "../models/admin-policy.model";

export class ConfigService {
    /** */
    private http = new HttpClient( `${Apis.BASE}/api/general` )

    public getPoliciesByCompany( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( `/companyPasswordPolicy/companyId/${companyId}` )
    }

    public getNotApplicableUsers( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( `/otpConfig/notApplicableUsers/companyId/${companyId}` )
    }
    
    public updatePolicy( body: AdminPolicy ): Promise<GenericApiResponse> {
        return this.http.patch<GenericApiResponse>( '/companyPasswordPolicy', body )
    }

    public updateNotApplicableUsers( companyId: string, userIds: string[] ): Promise<GenericApiResponse> {
        return this.http.post<GenericApiResponse>( `/otpConfig/companyId/${companyId}`, userIds )
    }

}