import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { VaultService } from "@/core/services/vault.service";
import { FilesUtil } from "@/core/utils/files.util";
import { useExcelTableStore } from "@/core/stores/excel-table.store";
/** Services */
const vaultService = new VaultService();
/** Contrato del Store */
interface BudgetCardState {
  budgetCardSelected?: File;
  getBudgetCardFile: (idFile: string) => Promise<boolean>
  setBudgetCardSelect: (file: File) => void
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  budgetCardSelected: undefined
}

/** Definición del Store */
const Store: StateCreator<BudgetCardState> = (set, get) => ({
  ...initialState,

  getBudgetCardFile: (idFile: string) => {
    return new Promise((resolve, reject) => {
      vaultService.getFile(idFile).then(
        response => {
          if (!!response) {
            //@ts-ignore
            const extension = response.name.split('.')[1];
            const typeFile = FilesUtil.getMimeType(extension);
            //@ts-ignore
            const fileBlob: Blob = FilesUtil.b64toBlob(response.file, typeFile);
            const file = new File([fileBlob], `cedula_presupuestal_${idFile}.${extension}`, { type: typeFile })
            //@ts-ignore
            useExcelTableStore.getState().setSelectedFileTable(file!);
            resolve(true)
          } else {
            resolve(false)
          }
        }
      ).catch((e) => reject())
    })
  },

  setBudgetCardSelect: (file?: File) => set({ budgetCardSelected: file }),

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useBudgetStore = create<BudgetCardState>()(
  immer(Store),
)