import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { AdminPermision } from "../models/admin-permission.model";
import { PermissionService } from "../services/permission.service";

/** Services */
const permissionService = new PermissionService()
/** Contrato del Store */
interface AdminPermissionState {
    permisions: AdminPermision[],
    selectedPermissions: AdminPermision[],
    selectPermissions: ( permissions: AdminPermision[] ) => void
    retrievePermission: () => Promise<boolean>
    removePermission: ( permissionId: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    permisions: [],
    selectedPermissions: []
}

/** Definición del Store */
const Store: StateCreator<AdminPermissionState> = ( set, get ) => ({

    ...initialState,

    selectPermissions: ( permissions: AdminPermision[] ) => set({ selectedPermissions: permissions }),

    retrievePermission: () => {
        return new Promise(( resolve, reject ) => {
            if ( get().permisions.length === 0 ) {
                permissionService.getPermissions().then(
                    response => {
                        const data: AdminPermision[] = response.respuesta

                        set({ permisions: data })

                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            }
        })
    },

    removePermission: ( permissionId: string ) => {
        return new Promise(( resolve, reject ) => {
            permissionService.deletePermission( permissionId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const filteredPermissions = get().permisions.filter(
                            item => item.id !== permissionId
                        )

                        set({ permisions: filteredPermissions })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminPermissionStore = create<AdminPermissionState>()(
    immer( Store )
)