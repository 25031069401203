import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { AdminUser } from "../models/admin-user.model";
import { UserService } from "../services/user.service";
import { NotApplicableUser } from "../models/not-applicable-user.model";

/** Services */
const userService = new UserService()
/** Contrato del Store */
interface AdminUserState {
    users: AdminUser[]
    selectedUser?: AdminUser
    mutateUsers: ( users: AdminUser[] ) => void
    selectUser: ( user: AdminUser ) => void
    deselectedUser: () => void
    retrieveUserById: ( userId: string ) => Promise<boolean>
    retrieveUsers: ( companyId: string, notApplicableUsers?: NotApplicableUser[] ) => Promise<boolean>
    addUser: ( user: AdminUser ) => Promise<boolean>
    addExternalUser: ( user: AdminUser ) => Promise<boolean>
    updateUser: ( user: AdminUser ) => Promise<boolean>
    removeUser: ( userId: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    users: [],
    selectedUser: undefined
}

/** Definición del Store */
const Store: StateCreator<AdminUserState> = ( set, get ) => ({

    ...initialState,

    mutateUsers: ( data: AdminUser[] ) => set({ users: data }),

    selectUser: ( user: AdminUser ) => set({ selectedUser: user }),

    deselectedUser: () => set({ selectedUser: undefined }),

    retrieveUserById: ( userId: string ) => {
        return new Promise(( resolve, reject ) => {
            userService.getUserById( userId ).then(
                response => {
                    const userResponse: AdminUser = response

                    set({ selectedUser: userResponse })

                    resolve( true )
                }
            ).catch(
                error => reject( error )
            )
        })
    },

    retrieveUsers: ( companyId: string, notApplicableUsers?: NotApplicableUser[] ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().users.length === 0 ) {
                userService.getUsersByGruop( companyId ).then(
                    response => {
                        const data: AdminUser[] = response.users
    
                        data.map( item => {
                            item.fullName = `${item.name} ${item.lastName}`
                            item.userType = item.external ? 'Usuario externo' : 'Usuario interno'
                            item.isActive = true

                            if ( notApplicableUsers ) {
                                for ( const user of notApplicableUsers ) {
                                    item.id === user.userId && ( item.isActive = false )
                                }
                            }

                            return item
                        })

                        set({ users: data })
    
                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            } else {
                const updatedUsers = get().users

                if ( notApplicableUsers ) {
                    updatedUsers.map( item => {
                        for ( const user of notApplicableUsers ) {
                            item.id === user.userId && ( item.isActive = false )
                        }

                        return item
                    })
                }

                set({ users: updatedUsers })

                resolve( true )
            }
        })
    },

    addUser: ( user: AdminUser ) => {
        return new Promise(( resolve, reject ) => {
            userService.createUser( user ).then(
                response => {
                    if ( response.data ) {
                        const newUser: AdminUser = response.data
                        const users = get().users

                        newUser.external = false
                        newUser.userType = 'Usuario interno'
                        newUser.fullName = `${newUser.name} ${newUser.lastName}`

                        set({ users: [ ...users, newUser ] })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    addExternalUser: ( user: AdminUser ) => {
        return new Promise(( resolve, reject ) => {
            userService.createExternalUser( user ).then(
                response => {
                    if ( response.data ) {
                        const newUser: AdminUser = response.data
                        const users = get().users

                        newUser.external = true
                        newUser.userType = 'Usuario externo'
                        newUser.fullName = `${newUser.name} ${newUser.lastName}`

                        set({ users: [ ...users, newUser ] })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    updateUser: ( user: AdminUser ) => {
        return new Promise(( resolve, reject ) => {
            userService.updateUser( user ).then(
                response => {
                    if ( response.body.data ) {
                        const updated: AdminUser = response.body.data

                        updated.external = user.external
                        updated.fullName = `${updated.name} ${updated.lastName}`
                        
                        const updatedUsers = get().users.map(
                            item => item.id === updated.id
                                ? { ...item, ...updated }
                                : item
                        )

                        set({ users: updatedUsers })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    removeUser: ( userId: string ) => {
        return new Promise(( resolve, reject ) => {
            userService.deleteUser( userId ).then(
                response => {
                    const removedUser = response
                    const filteredUsers = get().users.filter(
                        item => item.id !== removedUser.id
                    )

                    set({ users: filteredUsers })

                    resolve( true )
                }
            ).catch( () => reject() )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminUserStore = create<AdminUserState>()(
    immer( Store )
)