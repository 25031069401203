import { useUserStore } from "@/modules/auth/stores/user.store"
import { useAdminCompanyStore } from "../stores/admin-company.store"
import { useModalStore } from "@/core/stores/modal.store"
import { useState } from "react"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbFilterSearch, TbTrash, TbBuilding, TbBuildingWarehouse, TbPencil } from "react-icons/tb"
import { OwnerCompany } from "../models/owner-company.model"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import { Box, Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import CreateOrEditCompanyModal from "../components/companies/CreateOrEditCompanyModal"
import ConfirmCompanyDeletionAlert from "../components/companies/ConfirmCompanyDeletionAlert"
import AddCompanyFromCompaniesModal from "../components/companies/AddCompanyFromCompaniesModal"

const CompaniesPage: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const ownerCompanies = useAdminCompanyStore( s => s.ownerCompanies )
    const { retrieveOwnerCompanies, retrieveAllCompanies, selectOwnerCompany } = useAdminCompanyStore()
    const openModal = useModalStore( s => s.openModal )
    const [ inputFilter, setInputFilter] = useState<string>('')

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'foreignCompanyName', sortable: true },
        { header: 'RFC', accessor: 'foreignCompanyRfc', sortable: true },
        {
            header: '', accessor: '', positionRow: 'end', customRow: [
                row => <ActionIcon label="Editar usuario" icon={TbPencil} onHandle={() => handleEditUser( row )} />,
                row => <ActionIcon label="Eliminar empresa" icon={TbTrash} onHandle={() => handleDeleteCompany( row )} />
            ]
        }
    ]

    const handleNewCompany = () => {
        openModal( 'createOrEditCompanyModal' )
    }

    const handleEditUser = ( row: OwnerCompany ) => {
        selectOwnerCompany( row )
        openModal( 'createOrEditCompanyModal' )
    }

    const handleAddCompanyFromCompanies = () => {
        openModal( 'addCompanyFromCompaniesModal' )
    }

    const handleDeleteCompany = ( row: OwnerCompany ) => {
        selectOwnerCompany( row )
        openModal( 'confirmCompanyDeletionAlert', row )
    }

    const filterOwnerCompanies = () => {
        const filteredOwnerCompaniesByInput = ownerCompanies.filter( item => {
            const searchString = `${item.foreignCompanyName} ${item.foreignCompanyRfc}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredOwnerCompaniesByInput
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    useMountedEffect( () => {
        retrieveOwnerCompanies( companyData?.id! ).then(
            () => retrieveAllCompanies()
        )
    })

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-end">
                <div className="flex items-center gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    <Box>
                        <Button
                            leftIcon={<TbBuildingWarehouse />}
                            colorScheme="brand"
                            onClick={handleAddCompanyFromCompanies}>
                            Asociar empresa
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            leftIcon={<TbBuilding />}
                            colorScheme="brand"
                            onClick={handleNewCompany}>
                            Nueva empresa
                        </Button>
                    </Box>
                </div>
            </div>
            {/* Datos */}
            <BrandTable
                columns={columns}
                data={filterOwnerCompanies()}
                rowsPerPageOptions={[5, 10, 15]}
                defaultRowsPerPage={5}
                showPagination={true}
            />
            {/* Modals | Alerts */}
            <CreateOrEditCompanyModal />
            <AddCompanyFromCompaniesModal />
            <ConfirmCompanyDeletionAlert />
        </article>
    )
}

export default CompaniesPage