import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios"
import { useAuthStore } from "@/modules/auth/stores/auth.store"
import { useLoaderStore } from "@/core/stores/loader.store";
import { useModalStore } from "@/core/stores/modal.store";
import { GenericApiResponse } from "@/core/models/generic-api-response";
import CleanStore from "../utils/clean-store.util";

interface CustomAxiosRequestConfig extends Omit<InternalAxiosRequestConfig, 'headers'> {
    headers?: AxiosRequestHeaders | undefined
    hideLoader?: boolean
    noAuth?: boolean
}

class HttpClient {

    private instance: AxiosInstance

    constructor( baseURL: string ) {

        this.instance = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        this.initializeRequestInterceptor()
        this.initializeResponseInterceptor()
    }

    private initializeRequestInterceptor() {
        this.instance.interceptors.request.use(
            ( config: CustomAxiosRequestConfig ) => {
                /** */
                if ( !config.headers ) config.headers = { } as AxiosRequestHeaders

                if ( !config.hideLoader ) {
                    useLoaderStore.getState().showLoader()
                }

                if ( !config.noAuth ) {
                    const accessToken = useAuthStore.getState().authData?.accessToken
    
                    if ( accessToken ) {
                        config.headers.Authorization = `Bearer ${accessToken}`
                    }
                }

                return config as InternalAxiosRequestConfig
            }, error => {
                useLoaderStore.getState().hideLoader()
                Promise.reject( error )
            }
        )
    }

    private initializeResponseInterceptor() {
        this.instance.interceptors.response.use(
            ( response: AxiosResponse ) => {
                /** */
                const config = response.config as CustomAxiosRequestConfig

                if ( !config.hideLoader ) {
                    useLoaderStore.getState().hideLoader()
                }

                return response
            }, error => {
                useLoaderStore.getState().hideLoader()
                return Promise.reject( error )
            }
        )
    }

    public async get<T>( url: string, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.get<T>( url, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async post<T>( url: string, body: any, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.post<T>( url, body, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async put<T>( url: string, body: any, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.put<T>( url, body, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async patch<T>( url: string, body: any, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.patch<T>( url, body, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async delete<T>( url: string, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.delete<T>( url, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async head<T>( url: string, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.head<T>( url, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    public async options<T>( url: string, config?: CustomAxiosRequestConfig ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.instance.options<T>( url, config )
            return response.data
        } catch ( error ) {
            return this.handleError<T>( error as AxiosError )
        }
    }

    private handleError<T>( error: AxiosError ): Promise<T> {
        /** */
        if ( error.response ) {
            // El servidor respondió con un código de estado diferente a 2xx
            this.validateStatusError( error.response.status )
            this.hasPermissionForAction( error.response.data as GenericApiResponse )

            return Promise.reject( error.response.data )
        } else if ( error.request ) {
            // La petición fue hecha pero no se recibió respuesta
            return Promise.reject( error.request )
        } else {
            // Algo sucedió al configurar la petición
            return Promise.reject( error )
        }
    }

    private validateStatusError( status: number ): void {
        switch ( status ) {
            case 401:
                useAuthStore.getState().signOutUser()
                CleanStore()
                break
        }
    }

    private hasPermissionForAction( response: GenericApiResponse ): void {
        if ( response.codigo === 3 && response.mensaje === 'No cuenta con el permiso para esta acción.' ) {
            useModalStore.getState().openModal(
                'restrictedActionModal',
                {
                    title: 'AVISO',
                    module: 'No cuenta con el permiso para esta acción',
                    message: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos."
                }
            )
        }
    }

}

export default HttpClient