import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import AdminLayout from "@/modules/admin/AdminLayout"
import AdminRoutes from "@/modules/admin/Admin.routes"
import PlanningLayout from "@/modules/planning/PlanningLayout"
import PlanningRoutes from '@/modules/planning/Planning.routes'
import CampaignsRoutes from '@/modules/campaigns/Campaigns.routes'
import ConciliationRouter from '@/modules/conciliation/Conciliation.routes'
import CampaignsLayout from "@/modules/campaigns/CampaignsLayout"
import ConciliationLayout from "@/modules/conciliation/ConciliationLayout"
import GraphicsTemplate from "@/modules/graphics/GraphicsTemplate"

const DashboardTemplate = lazy( () => import( '@/modules/dashboard/DashboardTemplate' ) )
const HomeTemplate = lazy( () => import( '@/modules/home/HomeTemplate' ) )

const ViewsRoutes: RouteObject[] = [
    {
        path: '',
        element: <HomeTemplate />
    },
    {
        path: 'dashboard',
        element: <DashboardTemplate />
    },
    {
        path: 'graphics',
        element: <GraphicsTemplate />
    },
    {
        path: 'planning',
        element: <PlanningLayout />,
        children: [
            { index: false, element: <Navigate to="" replace />},
            ...PlanningRoutes
        ]
    },
    {
        path: 'campaigns',
        element: <CampaignsLayout />,
        children: [
            { index: false, element: <Navigate to="" replace />},
            ...CampaignsRoutes
        ]
    },
    {
        path: 'reconciliations',
        element: <ConciliationLayout />,
        children: [
            { index: false, element: <Navigate to="" replace />},
            ...ConciliationRouter
        ]
    },
    {
        path: 'admin',
        element: <AdminLayout />,
        children: [
            { index: true, element: <Navigate to="users" replace />},
            ...AdminRoutes
        ]
    }
]

export default ViewsRoutes