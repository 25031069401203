import { Navigate, RouteObject } from "react-router-dom"
import UsersPage from "./pages/UsersPage"
import UserGroupsPage from "./pages/UserGroupsPage"
import CompaniesPage from "./pages/CompaniesPage"
import RolesPage from "./pages/RolesPage"
import PermissionsPage from "./pages/PermissionsPage"
import ConfigLayout from "./ConfigLayout"
import ConfigRoutes from "./Config.routes"

const AdminRoutes: RouteObject[] = [
    { path: 'users', element: <UsersPage /> },
    { path: 'user-groups', element: <UserGroupsPage /> },
    { path: 'companies', element: <CompaniesPage /> },
    { path: 'roles', element: <RolesPage /> },
    { path: 'permissions', element: <PermissionsPage /> },
    {
        path: 'config',
        element: <ConfigLayout />,
        children: [
            { index: true, element: <Navigate to="authentication" replace /> },
            ...ConfigRoutes
        ]
    }
]

export default AdminRoutes