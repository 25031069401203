import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { TbUser } from "react-icons/tb"
import { TbUsersGroup } from "react-icons/tb"
import { TbBuildingSkyscraper } from "react-icons/tb"
import { TbLockCog } from "react-icons/tb"
import { TbSettings2 } from "react-icons/tb"
import { TbAffiliate } from "react-icons/tb"

const AdminTabs: React.FC = () => {
    /** */
    const navigate = useNavigate()
    const location = useLocation()

    const paths: string[] = [
        '/views/admin/users',
        '/views/admin/user-groups',
        '/views/admin/companies',
        '/views/admin/roles',
        '/views/admin/permissions',
        '/views/admin/config'
    ]

    const getCurrentIndex = () => {
        const path = paths.find( path => location.pathname.startsWith( path ) )

        return path ? paths.indexOf( path ) : 0
    }

    const currentIndex = getCurrentIndex()

    const handleTabsChange = (index: number) => {
        navigate( paths[ index ] )
    }

    return (
        <Tabs
            index={currentIndex}
            variant='unstyled'
            position='relative'
            colorScheme="brand"
            onChange={handleTabsChange}
        >
            <div style={{ overflowX: 'auto' }}>
                <TabList style={{ display: 'flex', minWidth: '800px' }}>
                    <Tab className="flex items-center gap-1">
                        <TbUser />
                        <span>Usuarios</span>
                    </Tab>
                    <Tab className="flex items-center gap-1">
                        <TbUsersGroup />
                        <span>Grupos de usuarios</span>
                    </Tab>
                    <Tab className="flex items-center gap-1">
                        <TbBuildingSkyscraper />
                        <span>Empresas</span>
                    </Tab>
                    <Tab className="flex items-center gap-1">
                        <TbAffiliate />
                        <span>Roles</span>
                    </Tab>
                    <Tab className="flex items-center gap-1">
                        <TbLockCog />
                        <span>Permisos</span>
                    </Tab>
                    <Tab className="flex items-center gap-1">
                        <TbSettings2 />
                        <span>Configuración</span>
                    </Tab>
                </TabList>
            </div>
            <TabIndicator />
        </Tabs>
    )
}

export default AdminTabs