import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CommentsPanel from './CommentsPanel'
import { useCampaignStore } from '../store/campaign.store'

function BriefPage() {
  const selectedCampaign = useCampaignStore(e => e.selectedCampaign)
  /* const [pdfSrc, setPdfSrc] = useState<string | null>(null);
  useEffect(() => {
    console.log(selectedCampaign);
    if (selectedCampaign?.briefPdf !== null) {
      const objectUrl = URL.createObjectURL(selectedCampaign?.briefPdf as File);
      setPdfSrc(objectUrl);
      
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedCampaign]) */
  useEffect(() => {
    console.log(selectedCampaign);
  }, [selectedCampaign])
  return (
    <Flex>
      <Box w={'70%'}>
        {selectedCampaign?.briefPdf && (
          <object type="application/pdf" data={selectedCampaign?.briefPdf} width="100%" height="100%">
            <p>Tu navegador no soporta visualizar PDFs. Por favor, descarga el PDF para verlo: <a href={selectedCampaign?.briefPdf}>Descargar PDF</a>.</p>
          </object>
        )}
      </Box>
      <CommentsPanel />
    </Flex>
  )
}

export default BriefPage