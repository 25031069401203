import { Box, Button, Flex, IconButton, Input, InputGroup, InputRightElement, Text, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiSend } from 'react-icons/fi'
import { RxDotsVertical } from 'react-icons/rx'
import CommentBody from './CommentBody'
import { DatesUtil } from '@/core/utils/dates.util'
interface CommentsProps {
  autor?: string;
  text?: string;
  date?: string;
}
function CommentsPanel() {
  const [comment, setComment] = useState<string>();
  const [commentsData, setCommentsData] = useState<CommentsProps[] | undefined>([]);

  const addComment = () => {
    const currentDate = new Date();
    const parsedDate = DatesUtil.getDateToComment(currentDate);
    const body = [
      ...commentsData!
    ].concat({
      autor: 'vladimir',
      date: parsedDate,
      text: comment
    })
    setCommentsData(body)
    setComment('');
  }
  return (
    <Box w={'30%'}>
      <Box bgColor='gray.100' p={3} borderTopRadius='5px'>
        <Text fontSize="md" fontWeight="bold" mr="2">
          Comentarios
        </Text>
      </Box>
      <Box bgColor='white' height='50vh' p={3} overflowY={'auto'}>
        <Flex flexDirection='column' gap={2}>
          <>
            {commentsData?.map((item, index) => <CommentBody key={index} item={item} />)}
            {/* <CommentBody />
            <CommentBody />
            <CommentBody />
            <CommentBody /> */}
          </>
        </Flex>
      </Box>
      <Box bgColor='gray.100' p={3} borderBottomRadius='5px'>
        <InputGroup gap={1}>
          <Input placeholder="Comentario"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            colorScheme="brand"
            as={IconButton}
            icon={<FiSend size={20} />}
            onClick={addComment}
          >Enviar</Button>
        </InputGroup>
      </Box>
    </Box>
  )
}

export default CommentsPanel