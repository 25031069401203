import CustomModal from "@/core/components/organism/CustomModal"
import { FcTodoList } from "react-icons/fc"
import { useAdminCompanyStore } from "../../stores/admin-company.store"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbCategoryPlus, TbFilterSearch } from "react-icons/tb"
import { Company } from "../../models/company"
import { useState } from "react"
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { AssociateCompanyRequest } from "../../models/associate-company-request.model"
import { App } from "@/core/enums/app.enum"
import { useUserStore } from "@/modules/auth/stores/user.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { DataUtil } from "@/core/utils/data.util"

const AddCompanyFromCompaniesModal: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const allCompanies = useAdminCompanyStore( s => s.allCompanies )
    const { associateToCompany } = useAdminCompanyStore()
    const [ inputFilter, setInputFilter] = useState<string>('')
    const toast = useCustomToast()

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'name', sortable: true },
        { header: 'RFC', accessor: 'rfc', sortable: true },
        {
            header: '', accessor: '', positionRow: 'end', customRow: [
                row => <ActionIcon label="Asociar empresa" icon={TbCategoryPlus} onHandle={() => handleAssociateCompany( row )} />
            ]
        }
    ]

    const handleAssociateCompany = ( row: Company ) => {
        const body: AssociateCompanyRequest = {
            associatedApp: App.ID,
            ownCompanyId: companyData?.id!,
            foreignCompanyId: row.id
        }

        associateToCompany( body ).then(
            () => toast.success( 'Empresa asociada correctamente' )
        ).catch(
            error => toast.error( DataUtil.extractErrorMessage( error.mensaje ) )
        )
    }

    const filterAllCompanies = () => {
        const filteredUsersByInput = allCompanies.filter( item => {
            const searchString = `${item.name} ${item.rfc}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    return (
        <CustomModal id="addCompanyFromCompaniesModal"
            size="xl"
            icon={FcTodoList}
            title="Asociar empresa"
            closeOnAccept={false}
            withButtons={false}
        >
            {() => (
                <article className="flex flex-col gap-4">
                    {/* Filtrar */}
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    {/* Datos */}
                    <BrandTable
                        columns={columns}
                        data={filterAllCompanies()}
                        rowsPerPageOptions={[5, 10, 15]}
                        defaultRowsPerPage={5}
                        showPagination={false}
                    />
                </article>
            )}
        </CustomModal>
    )
}

export default AddCompanyFromCompaniesModal