import { RouteObject } from "react-router-dom"
import RoadMapPage from "../../components/RoadMapPage"
import BudgetCardPage from "../../components/BudgetCardPage"
import NegotiateRatePage from "../../components/NegotiateRatePage"

const PlanningSectionRoutes: RouteObject[] = [
    { path: 'roadmap', element: <RoadMapPage /> },
    { path: 'budget-card', element: <BudgetCardPage /> },
    { path: 'negotiate-rate', element: <NegotiateRatePage /> },
]

export default PlanningSectionRoutes