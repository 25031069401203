import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Badge,
  Flex,
  Divider,
  AccordionIcon
} from "@chakra-ui/react";
import React from "react";

interface AccordionBadgeProps {
  children: React.ReactNode
  title: string
}

const AccordionBadge = ({ children, title }: AccordionBadgeProps) => {

  return (
    <AccordionItem border={0}>
      <AccordionButton as={Flex} alignItems="center" paddingY={2} paddingX={0} _hover={{
        backgroundColor: "transparent"
      }}>
        <Box textAlign="left">
          <Badge
            bgColor="gray.200"
            cursor="pointer"
            paddingX={2}
            borderRadius="full"
            textTransform='capitalize'
            fontSize='md'
            fontWeight='semibold'
            display='flex'
            width='auto'
            transition="background-color 0.3s ease"
            alignItems='center'
            _hover={{
              backgroundColor: 'gray.300'
            }}
          >
            {title}
            <AccordionIcon />
          </Badge>
        </Box>
        <Flex align="center" flex="1" ml={1}>
          <Divider borderWidth="1px" />
        </Flex>
      </AccordionButton>
      <AccordionPanel pb={4} px={0}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionBadge;
