import { memo, useMemo, useState } from "react"
import { BiBell, BiChevronDown } from "react-icons/bi"
import { useNotificationStore } from "@/core/stores/notification.store"
import { Icon, Menu, MenuButton, MenuItem, MenuList, Portal, Progress, Tooltip, useBoolean } from "@chakra-ui/react"
import { DataUtil } from "@/core/utils/data.util"
import { DatesUtil } from "@/core/utils/dates.util"
import { IoEyeOutline } from "react-icons/io5"
import LinkButton from "@/core/components/atoms/LinkButton"
import { useNavigate } from "react-router-dom"
import { NotificationService } from "@/core/services/notification.service"
import { App } from "@/core/enums/app.enum"
import { useUserStore } from "@/modules/auth/stores/user.store"
import useMountedEffect from "@/core/hooks/useMountedEffect"

export interface NotificationItem {
    id: string
    idUser: string
    idCompany: string
    idApp: string
    appName: string
    text: string
    type: string
    source: Source[]
    read: boolean
    active: boolean
    createdAt: string
    viewedAt: string | null
}

interface Source {
    key: string
    val: string
}

const Notifications: React.FC = () => {
    /** Services */
    const notificationService = new NotificationService()
    /** States */
    const { notifications, size: sizeNotifications, setNotifications } = useNotificationStore()
    const startNotificationFlow = useNotificationStore( s => s.startNotificationFlow )
    const [ loading, setLoading ] = useBoolean( false )
    const [ countNotifications, setCountNotifications ] = useState( 10 )
    const userData = useUserStore( s => s.userData )
    const navigate = useNavigate()

    const unreadNotifications = useMemo( () => {
        notifications.sort( ( a, b ) => {
            const dateA = new Date( a.createdAt ).getTime()
            const dateB = new Date( b.createdAt ).getTime()
            return dateB - dateA
        })

        return notifications.filter( noti => !noti.read )
    }, [notifications] )

    const displayNotifications = useMemo( () => {
        return unreadNotifications.slice( 0, countNotifications )
    }, [ unreadNotifications, countNotifications ])

    const handleClick = ( notification: NotificationItem ) => {
        switch ( notification?.type ) {
            case 'SCM_solicitud_revision':
                if ( notification.source[0]?.key === 'url' ) {
                    const url = notification.source[0].val
                    navigate( url.split( '/dashboard' )[1] )
                }
                break
            case 'SCM_solicitud_firma':
                if ( notification.source[0]?.key === 'url' ) {
                    window.open( notification.source[0]?.val, '_blank' )?.focus()
                }
                break
            case 'SCM_revision_usuario':
                if ( notification.source[0]?.key === 'url' ) {
                    const url = notification.source[0].val
                    navigate( url.replace( '/dashboard/agreements', '/contracts' ) )
                }
                break
        }
    }

    const handleMarkAsRead = ( e: any, noti: NotificationItem ) => {
        e.stopPropagation()
        setLoading.on()

        /** Update notification service */
        noti.read = true
        notificationService.updateNotification( noti ).then(
            async () => {
                const request = {
                    idApp: App.ID,
                    idUser: userData?.id as string
                }
                const notiResponse = await notificationService.getNotifications( request )
    
                if ( notiResponse.codigo === 0 ) {
                    setNotifications( notiResponse.respuesta )
                    setLoading.off()
                }
            }
        ).catch( () => {
            setLoading.off()
        })
    }

    useMountedEffect( () => {
        /** */
        startNotificationFlow()
    })

    return (
        <section className="notifications flex">
            <Menu>
                <MenuButton className="relative flex items-center justify-center px-4 cursor-pointer">
                    <BiBell size={26} color="gray" />
                    <div className="absolute bg-brand-500 w-4 h-4 rounded-full flex items-center justify-center top-1.5 right-3.5">
                        <span className="text-white text-[6.5px]">
                            {unreadNotifications.length <= 100 && unreadNotifications.length}
                            {unreadNotifications.length >= 100 && '99+'}
                        </span>
                    </div>
                </MenuButton>
                <Portal>
                    <MenuList shadow={"2xl"} zIndex="modal">
                        <div className="w-[400px] p-3">
                            {/* Head */}
                            <div className="flex items-center gap-2 pb-2 border-b font-medium text-brand-600">
                                <span>Notificaciones</span>
                                <span>{`(${sizeNotifications})`}</span>
                            </div>
                            {/* Body */}
                            <div className="max-h-[70vh] overflow-y-auto mt-2">
                                {loading ? <Progress size="xs" isIndeterminate colorScheme="brand" /> : <div className="w-full h-1"></div>}

                                {displayNotifications?.map((noti) => (
                                    <MemoNotificationItem
                                        key={noti.id}
                                        noti={noti}
                                        handleClick={handleClick}
                                        handleMarkAsRead={handleMarkAsRead}
                                    />
                                ))}
                                {unreadNotifications?.length > countNotifications && (
                                    <div className="w-full p-4 flex justify-center">
                                        <LinkButton rightIcon={<BiChevronDown />} onClick={() => setCountNotifications( ( prev ) => prev + 10 )}>
                                            Ver más
                                        </LinkButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </MenuList>
                </Portal>
            </Menu>
        </section>
    )
}

const Noti = ({
    noti,
    handleClick,
    handleMarkAsRead
}: {
    noti: NotificationItem
    handleClick: ( noti: NotificationItem ) => void
    handleMarkAsRead: ( e: any, noti: NotificationItem ) => void
}) => {
    return (
        <MenuItem
            key={noti.id}
            as={"div"}
            onClick={() => handleClick( noti )}
            className="w-full p-2 flex gap-2 items-center cursor-pointer hover:bg-brand-50 rounded-lg"
        >
            <Icon viewBox="0 0 62 55" fill="none" boxSize="3rem">
                <g clipPath="url(#userIcon)">
                    <path
                        d="M31 0a31.43 31.43 0 0 0-18.232 5.819C7.47 9.599 3.532 14.925 1.516 21.034a29.799 29.799 0 0 0 .034 18.79C3.588 45.926 7.546 51.24 12.857 55v-1.071c0-4.728 1.916-9.263 5.328-12.606 3.412-3.343 8.039-5.221 12.864-5.221 4.825 0 9.452 1.878 12.864 5.221 3.411 3.343 5.328 7.878 5.328 12.606V55c5.318-3.765 9.279-9.087 11.314-15.199a29.798 29.798 0 0 0 .011-18.813c-2.027-6.114-5.982-11.44-11.295-15.211A31.432 31.432 0 0 0 31 0Zm0 31.257a8.493 8.493 0 0 1-4.65-1.382 8.24 8.24 0 0 1-3.083-3.681 8.049 8.049 0 0 1-.476-4.739 8.152 8.152 0 0 1 2.29-4.2 8.424 8.424 0 0 1 4.286-2.244 8.53 8.53 0 0 1 4.836.466 8.336 8.336 0 0 1 3.756 3.021 8.085 8.085 0 0 1 1.41 4.557 8.12 8.12 0 0 1-2.45 5.8 8.458 8.458 0 0 1-5.92 2.402Z"
                        fill="#25469B"
                    />
                </g>
            </Icon>
            {noti.text.length > 62 ? (
                <Tooltip label={noti.text} fontSize={"xs"} zIndex="toast">
                    <div>
                        <div className="text-sm">{DataUtil.truncateText( noti.text, 62 )}</div>
                        <div className="text-brand-500 text-xs mt-1">{DatesUtil.getTimeAgo( noti.createdAt )}</div>
                    </div>
                </Tooltip>
            ) : (
                <div>
                    <div className="text-sm">{noti.text}</div>
                    <div className="text-brand-500 text-xs mt-1">{DatesUtil.getTimeAgo( noti.createdAt )}</div>
                </div>
            )}
            {!noti.read && (
                <Tooltip label="Marcar como leído" fontSize={"xs"} zIndex="toast" openDelay={500}>
                    <button
                        className="p-2 hover:bg-slate-200 rounded-full text-gray-600 hover:text-brand-600"
                        onClick={(e) => handleMarkAsRead( e, noti )}
                    >
                        <IoEyeOutline size={24} color="inherit" />
                    </button>
                </Tooltip>
            )}
        </MenuItem>
    )
}

const MemoNotificationItem = memo( Noti, (prevProps, nextProps ) => {
    return prevProps.noti.id === nextProps.noti.id
})

export default memo( Notifications )