import BrandBox from '@/core/components/molecules/BrandBox'
import { Badge, Box, Divider, Flex, Text } from '@chakra-ui/react'
import { PropsBudgetCards } from '../models/planning.models'
import { FiBriefcase, FiMapPin } from 'react-icons/fi'
import { LuTv } from 'react-icons/lu'
import { BsCurrencyDollar } from 'react-icons/bs'

const BudgetCards = ({ data }: { data: PropsBudgetCards }) => {
  return (
    <BrandBox className='!p-0'>
      <Box bgColor='brand.500' color='white' fontWeight='semibold' roundedTop='md' px={3} py={2}>
        {data.title}
      </Box>
      <Box p={2}>
        <Box>
          {
            data.badges.map((e, index) => (
              <Badge key={index} colorScheme='green' margin={1} rounded='md'>
                {e}
              </Badge>
            ))
          }
        </Box>
        <Divider my={2} />

        <Box>
          <Text display='flex' alignItems='center' gap={1} fontSize='sm'><FiMapPin /> Plaza: <b>{data.place}</b></Text>
          <Text display='flex' alignItems='center' gap={1} fontSize='sm'><LuTv /> Tipo de medio: <b>{data.medioType}</b></Text>
          <Text display='flex' alignItems='center' gap={1} fontSize='sm'><LuTv /> Medio: <b>{data.medio}</b></Text>
          <Text display='flex' alignItems='center' gap={1} fontSize='sm'><FiBriefcase /> Tipo de negociación: <b>{data.negotiationType}</b></Text>
          <Text display='flex' alignItems='center' gap={1} fontSize='sm'><BsCurrencyDollar /> Tipo de pago: <b>{data.payType}</b></Text>
        </Box>
        <Text align='center' my={1}>Presupuesto</Text>
        <Divider my={2} />
        <Box>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Dinero fresco</Text>
            <Text fontSize='sm'>$893,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Ahorro</Text>
            <Text fontSize='sm'>$3,993,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Carry over</Text>
            <Text fontSize='sm'>$19,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Capacidad de compra 1</Text>
            <Text fontSize='sm'>$9,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Bonificables JV/TL</Text>
            <Text fontSize='sm'>$199,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Retornos JVALE 2021-22</Text>
            <Text fontSize='sm'>$303,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Retornos JVA</Text>
            <Text fontSize='sm'>$1,200,000.98</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>Capacidad de compra 2</Text>
            <Text fontSize='sm'>$20,000.98</Text>
          </Flex>
        </Box>
        <Text align='center' my={1}>Compensación</Text>
        <Divider my={2} />
        <Box>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Cuenta por pagar
            </Text>
            <Text fontSize='sm'>
              $10,000.98
            </Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Carry over bonificable
            </Text>
            <Text fontSize='sm'>
              $9,000.98
            </Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Carry over bonificable (mes)
            </Text>
            <Text fontSize='sm'>-</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='sm'>
              Facturable (mes)
            </Text>
            <Text fontSize='sm'>-</Text>
          </Flex>
        </Box>
      </Box>
    </BrandBox>
  )
}

export default BudgetCards