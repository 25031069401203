import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const SignInPage = lazy( () => import( '@/modules/auth/pages/SignInPage' ) )
const ResetPasswordPage = lazy( () => import( '@/modules/auth/pages/ResetPasswordPage' ) )

const AuthRoutes: RouteObject[] = [
    { path: 'signin', element: <SignInPage /> },
    { path: 'reset-password', element: <ResetPasswordPage /> }
]

export default AuthRoutes