import BrandBox from "@/core/components/molecules/BrandBox"
import { UserGroup } from "../../models/user-group.model"
import { Avatar, AvatarGroup, Divider, Grid, GridItem } from "@chakra-ui/react"
import { useAdminUserGroupStore } from "../../stores/admin-user-group.store"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbPencil, TbTrash } from "react-icons/tb"
import { useModalStore } from "@/core/stores/modal.store"
import { useAdminUserStore } from "../../stores/admin-user.store"
import { TbUser } from "react-icons/tb"
import BrandPagination from "@/core/components/molecules/BrandPagination"
import { useMemo, useState } from "react"

interface UserGroupCardsProps {
    data: UserGroup[]
}

const UserGroupCards: React.FC<UserGroupCardsProps> = ({ data }) => {
    /** */
    const { selectUserGroup } = useAdminUserGroupStore()
    const users = useAdminUserStore( s => s.users )
    const openModal = useModalStore( s => s.openModal )
    const [ currentPage, setCurrentPage ] = useState( 1 )
    const [ rowsPerPage ] = useState( 9 )

    const getFullNameUser = ( userId: string ) => {
        return users.find( item => item.id === userId )?.fullName || 'Sin Nombre'
    }

    const handleEditGroup = ( row: UserGroup ) => {
        selectUserGroup( row )
        openModal( 'createOrEditUserGroupModal' )
    }

    const handleDeleteGroup = ( row: UserGroup ) => {
        selectUserGroup( row )
        openModal( 'confirmUserGroupDeletionAlert', row )
    }

    const paginatedData = useMemo( () => {
        /** */
        const startIndex = ( currentPage - 1 ) * rowsPerPage
        const updatedData = data.map( item => {
            const filteredUsers = users.filter( user => item.userList.includes( user.id ) )
            const newItem = {
                ...item,
                userList: filteredUsers.map( user => user.id )
            }
    
            return newItem
        })
    
        return updatedData.slice(startIndex, startIndex + rowsPerPage);

    }, [ data, users, currentPage, rowsPerPage ])

    return (
        <>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                {paginatedData.map(( item, index ) => (
                    <GridItem key={index} colSpan={{ base: 12, md: 4 }}>
                        <BrandBox key={index + item.id} className="flex flex-col gap-3">
                            {item.userList.length > 0
                                ? (
                                    <AvatarGroup size='sm' max={5}>
                                        {item.userList.map( userId => (
                                            <Avatar name={getFullNameUser( userId )} />
                                            // <Avatar name={getFullNameUser( userId )} bg='brand.500' color='brand.50' />
                                        ))}
                                    </AvatarGroup>
                                )
                                : (
                                    <div className="p-2 border border-dashed w-fit rounded-full">
                                        <TbUser size={18} color="gray" />
                                    </div>
                                )
                            }
                            <div className="flex flex-col gap-1">
                                <strong className="text-brand-500">
                                    {item.name}
                                </strong>
                                <p className="text-gray-700">
                                    {item.description}
                                </p>
                            </div>
                            <Divider />
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <span className="font-semibold text-gray-700">
                                        Integrantes
                                    </span>
                                    <div className="px-2 border-2 rounded-lg border-brand-500">
                                        {item.userList.length}
                                    </div>
                                </div>
                                <div className="flex items-center gap-4">
                                    <ActionIcon label="Editar grupo"
                                        icon={TbPencil}
                                        onHandle={() => handleEditGroup( item )}
                                    />
                                    <ActionIcon label="Eliminar grupo"
                                        icon={TbTrash}
                                        onHandle={() => handleDeleteGroup( item )}
                                    />
                                </div>
                            </div>
                        </BrandBox>
                    </GridItem>
                ))}
            </Grid>
            {/* Pagination */}
            {data.length > 0 && (
                <div className="w-full flex justify-center">
                    <BrandPagination
                        totalRows={data.length}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        onPageChange={( page: number ) => setCurrentPage( page ) }
                        pagesPerView={5}
                    />
                </div>
            )}
        </>
    )
}

export default UserGroupCards