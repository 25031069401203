import { Button } from "@chakra-ui/react"
import { IconType } from "react-icons"

interface HeadModuleProps {
    title: string
    icon?: IconType
    handleAction?: () => void
    actionText?: string
}

const HeadModule: React.FC<HeadModuleProps> = ({
    title,
    icon: Icon,
    handleAction,
    actionText = 'Do something'
}) => {
    /** */

    return (
        <div className="flex items-center justify-between pb-2 mb-4 border-b">
            <div className="flex items-center gap-2">
                {Icon && <Icon size={20} /> }
                <strong className="font-semibold text-2xl">
                    {title}
                </strong>
            </div>
            {handleAction && (
                <Button size="sm"
                    onClick={handleAction}>
                    {actionText}
                </Button>
            )}
        </div>
    )
}

export default HeadModule