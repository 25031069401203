import React from 'react'
import BrandBox from './BrandBox'
import { AspectRatio, Badge, Box, Button, Flex, Icon, IconButton, Image, Text, Tooltip } from '@chakra-ui/react'
import { FaFileCode, FaFileImage, FaFilePdf, FaFileVideo } from 'react-icons/fa'
import { CiFileOff } from "react-icons/ci";
import { RxDotsVertical } from 'react-icons/rx';
import { FilesUtil } from '@/core/utils/files.util';

interface FileCardProps {
  typeFile: string
  nameFile: string
  comments?: number
  image?: string
  id?: string
  status?: string
  file?: string
}

function FileCard({ typeFile, nameFile, comments, image, id, status, file }: FileCardProps) {

  return (
    <BrandBox>
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex alignItems='center' gap={2}>
          <Image src={FilesUtil.handleIcon(typeFile)} /> <Text color='brand.500' fontWeight='semibold' fontSize='lg'>{nameFile}</Text>
        </Flex>
        <Box>
          <Tooltip label='Opciones'>
            <Button
              as={IconButton}
              icon={<RxDotsVertical color='gray' size={20} />}
              variant="ghost"
              size="md"
              margin={'0'}
              padding={'6px'}
              borderRadius='full'
              onClick={(e) => console.log('open')}
            />
          </Tooltip>
        </Box>
      </Flex>
      <Box paddingY={2}>
        {typeFile !== 'xml' && typeFile !== 'pdf' && <AspectRatio ratio={4 / 3}>
          <Image src={file} objectFit='cover' />
        </AspectRatio>
        }

      </Box>
      <Flex alignItems='center' justifyContent='space-between'>
        {id &&
          <Text fontWeight={'semibold'}>
            ID: {id}
          </Text>
        }
        {comments !== undefined &&
          <Text fontWeight={'semibold'}>
            Comentarios ({comments})
          </Text>
        }
        {status &&
          <Badge colorScheme={status === 'Bajo revisión' ? 'yellow' : 'green'} rounded='full' paddingX={3}>
            {status}
          </Badge>
        }
      </Flex>
    </BrandBox>
  )
}

export default FileCard