export const IndicatorsData = [
    {
        id: "1",
        type: "AMOUNT",
        filterBy: "Noviembre",
        description: "Gran total (Noviembre)",
        qantity: 8718000.13
    },
    {
        id: "2",
        type: "TV",
        filterBy: "Noviembre",
        description: "Total TV abierta",
        qantity: 3170938.80
    },
    {
        id: "3",
        type: "TV",
        filterBy: "Noviembre",
        description: "Total TV paga",
        qantity: 1501517.54
    },
    {
        id: "4",
        type: "TV",
        filterBy: "Noviembre",
        description: "Total TV local",
        qantity: 45419.79
    },
    {
        id: "5",
        type: "RADIO",
        filterBy: "Noviembre",
        description: "Total radio",
        qantity: 241554.32
    },
    {
        id: "6",
        type: "IMAGE",
        filterBy: "Noviembre",
        description: "Total OOH",
        qantity: 2133372.24
    },
    {
        id: "7",
        type: "DOCUMENT",
        filterBy: "Noviembre",
        description: "Total prensa",
        qantity: 2133372.24
    },
    {
        id: "8",
        type: "DOCUMENT",
        filterBy: "Noviembre",
        description: "Total digital",
        qantity: 1480652.64
    }
]