export enum CampaignStatus {
  Actived = 'ACTIVA',
  Closed = 'CERRADA',
  Progress = 'PROCESO',
}

export enum CurrentStatus {
  aprobado = 'APROBADO',
  rechazado = 'RECHAZADO',
  revision = 'REVISIÓN'
}