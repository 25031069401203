import { Apis } from "@/apis";
import HttpClient from "../http/http-client";
import { GenericApiResponse } from "../models/generic-api-response";
import { NavigationItem } from "../components/molecules/Navigation";


export class SectionService {
    /** */
    private http = new HttpClient( `${Apis.ORKESTAMEDIA}/api/v1/section` )

    public getSectionsByArea( areaId: string ): Promise<GenericApiResponse> {
        return this.http.get( `/areaId/${areaId}` )
    }

    public initSectionsByArea( areaId: string ): Promise<GenericApiResponse> {
        return this.http.post( `/init/areaId/${areaId}`, null )
    }

    public updateSection( body: Omit<NavigationItem, 'id' | 'route'> ): Promise<GenericApiResponse> {
        return this.http.patch( '', body )
    }

    public createSection( body: Omit<NavigationItem, 'id'> ): Promise<GenericApiResponse> {
        return this.http.put( '', body )
    }

}