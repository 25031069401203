export const Activites = [
    "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
    "Minería",
    "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final",
    "Construcción",
    "Industrias manufactureras",
    "Comercio al por mayor",
    "Comercio al por menor",
    "Transportes, correos y almacenamiento",
    "Información en medios masivos",
    "Servicios financieros y de seguros",
    "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos",
    "Servicios profesionales, científicos y técnicos",
    "Corporativos",
    "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
    "Servicios educativos",
    "Servicios de salud y de asistencia social",
    "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
    "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
    "Otros servicios excepto actividades gubernamentales",
    "Otro"
]