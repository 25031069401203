import { Apis } from "@/apis"
import { NotificationItem } from "@/core/components/organism/Notifications"
import { HideLoader } from "@/core/constants/config-header"
import { App } from "@/core/enums/app.enum"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { NotificationRequest } from "@/core/models/notification-request"


export class NotificationService {
    /** */
    private http = new HttpClient( `${Apis.BASE}/api/general/notification` )

    public getNotifications( request: NotificationRequest ): Promise<GenericApiResponse> {
        return this.http.post<GenericApiResponse>( '/', request, HideLoader )
    }

    public getSizeNotifications( userId: string ): Promise<GenericApiResponse> {
        return this.http.get( `/size/idUser/${userId}/idApp/${App.ID}`, HideLoader )
    }

    public updateNotification( request: NotificationItem ): Promise<GenericApiResponse> {
        return this.http.patch<GenericApiResponse>( `/`, request )
    }

}