import { StateCreator, create } from "zustand"
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { AuthStatus } from "@/modules/auth/models/auth-status"
import { AuthData } from "@/modules/auth/models/auth-data"
import { SecureSessionStorage } from "@/core/stores/secure-sessionstorage.store"
import CleanStore from "@/core/utils/clean-store.util"
import { useIntervalStore } from "@/core/stores/interval.store"
import { DatesUtil } from "@/core/utils/dates.util"
import { useModalStore } from "@/core/stores/modal.store"

/** Contrato del Store */
interface AuthState {
    status: AuthStatus
    authData?: AuthData
    timestampExp?: number
    authorize: () => void
    signInUser: ( auth: AuthData ) => void
    signOutUser: () => void
    checkSessionExpires: () => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState: Pick<AuthState, 'status' | 'authData' | 'timestampExp'> = {
    status: 'unauthorized',
    authData: undefined,
    timestampExp: undefined
}

/** Definición del Store */
const Store: StateCreator<AuthState> = ( set, get ) => ({

    ...initialState,

    authorize: () => set({ status: 'authorized' }),

    signInUser: ( data: AuthData ) => set({
        authData: data,
        timestampExp: data.exp
    }),

    checkSessionExpires: () => {
        const expiresDate = new Date( ( get().timestampExp || 0 ) * 1000 )
        const diffMill = DatesUtil.getDifferenceInMilliseconds( new Date(), expiresDate )
        const intervalTime = diffMill - 100000

        const sessionInterval = setInterval( () => {
            useIntervalStore.getState().clearInterval( 'sessionInterval' )
            useModalStore.getState().openModal( 'SessionExpireNotifier' )
        }, intervalTime )

        useIntervalStore.getState().addInterval(
            'sessionInterval', sessionInterval
        )
    },

    signOutUser: async () => {
        CleanStore()
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAuthStore = create<AuthState>()(
    persist(
        immer( Store ),
        {
            name: 'auth-storage',
            storage: SecureSessionStorage
        }
    )
)