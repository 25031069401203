import BrandBox from '@/core/components/molecules/BrandBox';
import { Badge, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useConciliationStore } from '../stores/conciliation.store';
import { RowConciliation, versions } from '../models/conciliation.model';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useComponentsStore } from '@/core/stores/components.store';
import DocumentViewTemplate from './DocumentViewTemplate';
import { useEffect } from 'react';

interface BuyOrderProps {
  tvType: string;
  provider: string;
  folio: string
  versions: versions[]
}

interface OrderProps {
  order: BuyOrderProps
}

function BuyOrdersPage() {
  const navigate = useNavigate();
  const { uiConciliationComponents } = useComponentsStore();
  const {  buyOrders, setSelectedBuyOrderProvider, setSelectedFile } = useConciliationStore();

  const handleClick = (order: BuyOrderProps) => {
    setSelectedBuyOrderProvider(order.provider)
    setSelectedFile(order.versions[0])
    navigate('/views/reconciliations/buy-order');
  }

  useEffect(() => {
    if (uiConciliationComponents.seeOrdersProviders) {
      //setSelectedBuyOrderProvider('')
    } else {

      setSelectedBuyOrderProvider('Facebook')
    }
  },
    [])

  const RowHover = ({ order }: OrderProps) => {
    return <Tr className='group hover:bg-blue-100'>
      <Td>
        <Flex columnGap={6}>
          <Text fontWeight={'bold'}>
            {order.tvType}
          </Text>
          <Text>
            {order.provider}
          </Text>
        </Flex>
      </Td>
      <Td>Folio: <b>{order.folio}</b></Td>
      <Td isNumeric>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant='ghost'
          className='opacity-0 group-hover:opacity-100 transition-opacity'
          onClick={() => handleClick(order!)}
        >Ver órden de compra</Button>
      </Td>
    </Tr>
  }

  return (
    uiConciliationComponents.seeOrdersProviders ?
      <BrandBox>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th fontWeight='bold' color='black' fontSize='sm'>Órdenes de compra</Th>
                <Th fontWeight='bold' color='black' fontSize='sm'>Folio</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {buyOrders.map((order, index) => (
                <RowHover order={order} key={index} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </BrandBox> :
      <DocumentViewTemplate title='Orden' />
  );
}

export default BuyOrdersPage;
