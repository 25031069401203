import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { BillingProps, BuyOrder, ConciliationBilling, ConciliationLetter, ConciliationWitnesses, Evidences, EvidencesByMonth, EvidencesFiles, WitnessesProviders } from "../models/conciliation.model";

/** Contrato del Store */
interface ConciliationState {
  witnessesProviders: WitnessesProviders[]
  buyOrders: BuyOrder[]
  conciliationLetter: ConciliationLetter[]
  conciliationWitnesses: ConciliationWitnesses[]
  conciliationBilling: ConciliationBilling[]
  billingFiles: BillingProps[]
  selectedWitnessesProvider: WitnessesProviders | null
  selectedWitnessesMonth: EvidencesByMonth | null
  selectedFile: EvidencesFiles | null
  SelectedBillingProvider: ConciliationBilling | null
  selectedBillingMonth: BillingProps | null
  selectedBuyOrderProvider: BuyOrder | null
  selectedLetterProvider: ConciliationLetter | null
  setSelectedWitnessesProvider: (provider: string) => void
  setSelectedWitnesses: (witnesses: WitnessesProviders[]) => void
  setSelectedWitnessesMonth: (month: string) => void
  setSelectedBillings: (billings: ConciliationBilling[]) => void
  setSelectedBillingProvider: (provider: string) => void
  setSelectedBillingMonth: (month: string) => void
  setSelectedBuyOrderProvider: (provider: string) => void
  setSelectedLetterProvider: (provider: string) => void
  setSelectedFile: (file: EvidencesFiles) => void
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  selectedBillingMonth: null,
  selectedFile: null,
  selectedWitnessesProvider: null,
  selectedWitnessesMonth: null,
  SelectedBillingProvider: null,
  selectedBuyOrderProvider: null,
  selectedLetterProvider: null,
  witnessesProviders: [
    {
      provider: "Facebook",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Post Kivit',
              file: '/conciliation/fb1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post 2 Kivit',
              file: '/conciliation/fb2.jpeg',
              id: '974K89',
              type: 'image'
            },
          ]
        }

      ]
    },
    {
      provider: "Tiktok",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Tiktok media',
              file: '/conciliation/tiktok1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Tiktok media 2',
              file: '/conciliation/tiktok2.jpeg',
              id: '974K90',
              type: 'image'
            },
          ]
        }

      ]
    },
    {
      provider: "Revista emprendedores",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Agosto 2024',
          files: [
            {
              name: 'Página 13 revista',
              file: '/conciliation/revista1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Página 73 revista',
              file: '/conciliation/revista2.jpeg',
              id: '974K90',
              type: 'image'
            }
          ]
        }

      ]
    },
    {
      provider: "Instagram",
      type: 'PTV',
      evidences: [
        {
          evidencesNumber: 4,
          status: 'Pendiente',
          month: 'Mayo 2024',
          files: [
            {
              name: 'Post instagram 1',
              file: '/conciliation/insta1.jpeg',
              id: '974K89',
              type: 'image'
            },
            {
              name: 'Post instagram 2',
              file: '/conciliation/insta2.jpeg',
              id: '974K90',
              type: 'image'
            },
          ]
        }

      ]
    },
  ],
  buyOrders: [
    {
      tvType: 'TVA',
      provider: 'Facebook',
      folio: '7jhb56',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'TikTok',
      folio: '8kij23',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'Revista emprendedores',
      folio: '9lmn45',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
    {
      tvType: 'TVA',
      provider: 'Instagram',
      folio: '1xyz78',
      versions: [
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra1.0", type: "pdf" },
        { file: '/dummy/Orden compra campaña aviones.pdf', name: "Orden compra2.0", type: "pdf" },
      ]
    },
  ],
  conciliationLetter: [
    {
      provider: "Facebook", fileName: "conciliacion_tv_azteca.pdf", tvType: 'TVA',
      versions: [
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "TikTok", fileName: "conciliacion_imagen_television.pdf", tvType: 'TVA',
      versions: [
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/dummy/3_2_2864631_CARTA CONCILIACION_AGOSTO (2).pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "Revista emprendedores", fileName: "conciliacion_azteca_trece.pdf", tvType: 'TVA',
      versions: [
        { file: '/conciliation/Factura PTV2.pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/conciliation/Factura PTV.pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
    {
      provider: "Instagram", fileName: "conciliacion_azteca_trece.pdf", tvType: 'TVA',
      versions: [
        { file: '/conciliation/Factura PTV2.pdf', name: "Carta conciliacion1.0", type: "pdf" },
        { file: '/conciliation/Factura PTV.pdf', name: "Carta conciliacion2.0", type: "pdf" },
      ]
    },
  ],
  conciliationWitnesses: [
    { month: "Enero 2023", numberBillings: 2, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Febrero 2023", numberBillings: 9, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Marzo 2023", numberBillings: 5, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Abril 2023", numberBillings: 3, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Mayo 2023", numberBillings: 4, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Junio 2023", numberBillings: 1, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Julio 2023", numberBillings: 2, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Agosto 2023", numberBillings: 7, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Septiembre 2023", numberBillings: 9, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Octubre 2023", numberBillings: 7, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Noviembre 2023", numberBillings: 3, evidencesNumber: 4, status: 'Pendiente' },
    { month: "Diciembre 2023", numberBillings: 8, evidencesNumber: 4, status: 'Pendiente' }
  ],
  conciliationBilling: [
    {
      provider: "Facebook",
      numberBillings: 2,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "TikTok",
      numberBillings: 9,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "Revista emprendedores",
      numberBillings: 5,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
    {
      provider: "Instagram",
      numberBillings: 3,
      billingsByMonth: [
        {
          status: 'Bajo revisión',
          month: "Agosto",
          files: [
            { file: '/conciliation/Factura PTV.pdf', name: "FacturaAgosto", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaAgosto", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        },
        {
          status: 'Bajo revisión',
          month: "Julio",
          files: [
            { file: '/conciliation/Factura PTV2.pdf', name: "FacturaJulio", type: "pdf", status: 'Bajo Revisión', comments: 0 },
            { file: '', name: "FacturaJulio", type: "xml", status: 'Bajo Revisión', comments: 0 }
          ]
        }
      ]
    },
  ],
  billingFiles: [
    {
      month: "Enero",
      files: [
        { name: "FacturaEnero1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaEnero2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Febrero",
      files: [
        { name: "FacturaFebrero1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaFebrero2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Marzo",
      files: [
        { name: "FacturaMarzo1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaMarzo2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Abril",
      files: [
        { name: "FacturaAbril1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaAbril2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Mayo",
      files: [
        { name: "FacturaMayo1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaMayo2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Junio",
      files: [
        { name: "FacturaJunio1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaJunio2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Julio",
      files: [
        { name: "FacturaJulio1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaJulio2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Agosto",
      files: [
        { name: "FacturaAgosto1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaAgosto2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Septiembre",
      files: [
        { name: "FacturaSeptiembre1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaSeptiembre2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    },
    {
      month: "Octubre",
      files: [
        { name: "FacturaOctubre1", type: "pdf", status: 'Bajo Revisión', comments: 10 },
        { name: "FacturaOctubre2", type: "xml", status: 'Bajo Revisión', comments: 10 }
      ]
    }
  ]
}

/** Definición del Store */
const Store: StateCreator<ConciliationState> = (set, get) => ({

  ...initialState,

  setSelectedWitnessesProvider: (provider: string) => {
    const witnessesProviders = get().witnessesProviders;

    const providerSelected = witnessesProviders.filter((e) => e.provider === provider)[0]
    set({ selectedWitnessesProvider: providerSelected })
  },

  setSelectedWitnessesMonth: (month: string) => {
    const witnessesMonth = get().selectedWitnessesProvider;

    const monthSelected = witnessesMonth?.evidences?.filter((e) => e.month === month)[0]
    set({ selectedWitnessesMonth: monthSelected })
  },

  setSelectedFile: (file: EvidencesFiles) => {
    set({ selectedFile: file })
  },

  setSelectedWitnesses: (witnesses: WitnessesProviders[]) => {
    set({ witnessesProviders: witnesses })
  },

  setSelectedBillings: (billings: ConciliationBilling[]) => {
    set({ conciliationBilling: billings })
  },

  setSelectedBuyOrderProvider: (provider: string) => {
    const billing = get().buyOrders;

    const providerSelected = billing.filter((e) => e.provider === provider)[0]
    set({ selectedBuyOrderProvider: providerSelected, selectedFile: providerSelected.versions[0] })
  },

  setSelectedBillingProvider: (provider: string) => {
    const conciliationBilling = get().conciliationBilling;

    const providerSelected = conciliationBilling.filter((e) => e.provider === provider)[0]
    set({ SelectedBillingProvider: providerSelected })
  },

  setSelectedBillingMonth: (month: string) => {
    const conciliationBilling = get().SelectedBillingProvider;

    const providerSelected = conciliationBilling?.billingsByMonth.filter((e) => e.month === month)[0]
    set({ selectedBillingMonth: providerSelected })
  },

  setSelectedLetterProvider: (provider: string) => {
    const conciliationBilling = get().conciliationLetter;

    const providerSelected = conciliationBilling.filter((e) => e.provider === provider)[0]
    set({ selectedLetterProvider: providerSelected, selectedFile: providerSelected.versions[0] })
  },

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useConciliationStore = create<ConciliationState>()(
  immer(Store)
)