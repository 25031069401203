import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Campaign } from "../models/campaign.model";
import { CampaignStatus } from "../enums/campaign.enum";
import { PlanningService } from "@/modules/planning/services/planning.service";
import { Planning } from "@/core/models/planning.model";
import { useNavigationStore } from "@/core/stores/navigation.store";

/** Services */
const planningService = new PlanningService()

/** Contrato del Store */
interface CampaignState {
  campaignItems: Campaign[]
  selectedCampaign?: Campaign
  tabSelected: string
  planningItems: Planning[]
  retrieveCampaignItems: (sectionId: string) => Promise<boolean>
  addCampaignItem: (item: Campaign) => Promise<boolean>
  updateCampaignItem: (item: Campaign) => Promise<boolean>
  setSelectedCampaign: (item: Campaign) => void
  getBudgets: () => Promise<boolean>
  reset: () => void
}

/** Estado inicial del Store */
const initialState = {
  campaignItems: [
    {
      advertiser: "Celebraciones Felices",
      campaignName: "Fiestas Navideñas 2024",
      idCampaign: "CAMP-001",
      targetAndAudience: "Familias, Niños, Amantes de la Navidad",
      objective: "Promover productos y eventos relacionados con la Navidad",
      campaignValidityDate: "2024-12-01",
      places: "México",
      url: "https://celebracionesfelices.com/campañas/fiestasnavideñas",
      openTv: "Spot 10” / 15” / 20” / Super Ele / Menciones",
      payTv: "Spot 10” / 15” / 20” / Super / Cortinilla / Bugs",
      localTv: "Spot 10” / 15” / 20” / Menciones",
      radio: "Spot 20” / Menciones",
      ooh: "Kioskos / Centros Comerciales / Universidades / Pantallas / Landmark / Muros Digitales (Vía Verde) / Parabuses",
      digital: "Social / Video / Display",
      imgCampaignFile: null,
      strategyPdfFile: null,
      briefPdfFile: null,
      imgCampaign: "https://img.freepik.com/foto-gratis/feliz-navidad-prospero-ano-nuevo-fondo-pantalla_24972-2461.jpg?w=1380&t=st=1723424407~exp=1723425007~hmac=c848f1281292d3e43d7656d98fa7a90bf48f2fe50c7525d22fcd74281f44febf",
      strategyPdf: "/dummy/Presentación Estrategia de marketing Corporativo Amarillo (1).pdf",
      briefPdf: "/dummy/Presentación Propuesta Proyecto Brief Cliente Moderno Profesional Negro y Blanco.pdf",
      status: CampaignStatus.Progress,
      flow: '/dummy/FLOW.xlsx'
    },
    {
      advertiser: "Año Nuevo Brillante",
      campaignName: "Celebraciones de Año Nuevo 2024",
      idCampaign: "CAMP-002",
      targetAndAudience: "Adultos, Jóvenes, Organizadores de eventos",
      objective: "Fomentar la participación en eventos y ventas de productos para Año Nuevo",
      campaignValidityDate: "2024-12-26",
      places: "México",
      url: "https://añonuevobrillante.com/campañas/celebracionesaño2024",
      openTv: "Spot 10” / 15” / 20” / Super Ele / Menciones",
      payTv: "Spot 10” / 15” / 20” / Super / Cortinilla / Bugs",
      localTv: "Spot 10” / 15” / 20” / Menciones",
      radio: "Spot 20” / Menciones",
      ooh: "Kioskos / Centros Comerciales / Universidades / Pantallas / Landmark / Muros Digitales (Vía Verde) / Parabuses",
      digital: "Social / Video / Display",
      imgCampaignFile: null,
      strategyPdfFile: null,
      briefPdfFile: null,
      imgCampaign: "https://img.freepik.com/vector-gratis/fondo-feliz-ano-nuevo_23-2148022232.jpg?t=st=1723424470~exp=1723428070~hmac=2f0bcf13d00e6c28bb30d9da79268d99f74ca7a58c01e76fd0dfe855cac512e4&w=740",
      strategyPdf: "/dummy/Presentación Estrategia de marketing Corporativo Amarillo (1).pdf",
      briefPdf: "/dummy/Presentación Propuesta Proyecto Brief Cliente Moderno Profesional Negro y Blanco.pdf",
      status: CampaignStatus.Progress,
      flow: '/dummy/FLOW.xlsx'
    },
    {
      advertiser: "Axeleratum",
      campaignName: "Campaña Kivit proveedor",
      idCampaign: "CAMP-003",
      targetAndAudience: "Adultos, Jóvenes, Organizadores de eventos",
      objective: "Fomentar la participación en eventos y ventas de productos para Año Nuevo",
      campaignValidityDate: "2024-08-19",
      places: "México",
      url: "https://añonuevobrillante.com/campañas/celebracionesaño2024",
      openTv: "Spot 10” / 15” / 20” / Super Ele / Menciones",
      payTv: "Spot 10” / 15” / 20” / Super / Cortinilla / Bugs",
      localTv: "Spot 10” / 15” / 20” / Menciones",
      radio: "Spot 20” / Menciones",
      ooh: "Kioskos / Centros Comerciales / Universidades / Pantallas / Landmark / Muros Digitales (Vía Verde) / Parabuses",
      digital: "Social / Video / Display",
      imgCampaignFile: null,
      strategyPdfFile: null,
      briefPdfFile: null,
      imgCampaign: "/campaign/campaña_kivit.jpeg",
      strategyPdf: "/dummy/Presentación Estrategia de marketing Corporativo Amarillo (1).pdf",
      briefPdf: "/dummy/Presentación Propuesta Proyecto Brief Cliente Moderno Profesional Negro y Blanco.pdf",
      status: CampaignStatus.Progress,
      flow: '/dummy/FLOW.xlsx'
    },
    {
      advertiser: "Axeleratum",
      campaignName: "Campaña OrkestaMedia",
      idCampaign: "CAMP-004",
      targetAndAudience: "Adultos, Jóvenes, Organizadores de eventos",
      objective: "Fomentar la participación en eventos y ventas de productos para Año Nuevo",
      campaignValidityDate: "2024-08-02",
      places: "México",
      url: "https://añonuevobrillante.com/campañas/celebracionesaño2024",
      openTv: "Spot 10” / 15” / 20” / Super Ele / Menciones",
      payTv: "Spot 10” / 15” / 20” / Super / Cortinilla / Bugs",
      localTv: "Spot 10” / 15” / 20” / Menciones",
      radio: "Spot 20” / Menciones",
      ooh: "Kioskos / Centros Comerciales / Universidades / Pantallas / Landmark / Muros Digitales (Vía Verde) / Parabuses",
      digital: "Social / Video / Display",
      imgCampaignFile: null,
      strategyPdfFile: null,
      briefPdfFile: null,
      imgCampaign: "/campaign/campaña_orkestamedia.jpeg",
      strategyPdf: "/dummy/Presentación Estrategia de marketing Corporativo Amarillo (1).pdf",
      briefPdf: "/dummy/Presentación Propuesta Proyecto Brief Cliente Moderno Profesional Negro y Blanco.pdf",
      status: CampaignStatus.Progress,
      flow: '/dummy/FLOW.xlsx'
    },
    {
      advertiser: "Axeleratum",
      campaignName: "Campaña Orkestadoc",
      idCampaign: "CAMP-004",
      targetAndAudience: "Adultos, Jóvenes, Organizadores de eventos",
      objective: "Fomentar la participación en eventos y ventas de productos para Año Nuevo",
      campaignValidityDate: "2024-08-22",
      places: "México",
      url: "https://añonuevobrillante.com/campañas/celebracionesaño2024",
      openTv: "Spot 10” / 15” / 20” / Super Ele / Menciones",
      payTv: "Spot 10” / 15” / 20” / Super / Cortinilla / Bugs",
      localTv: "Spot 10” / 15” / 20” / Menciones",
      radio: "Spot 20” / Menciones",
      ooh: "Kioskos / Centros Comerciales / Universidades / Pantallas / Landmark / Muros Digitales (Vía Verde) / Parabuses",
      digital: "Social / Video / Display",
      imgCampaignFile: null,
      strategyPdfFile: null,
      briefPdfFile: null,
      imgCampaign: "/campaign/campaña_orkestadoc.jpeg",
      strategyPdf: "/dummy/Presentación Estrategia de marketing Corporativo Amarillo (1).pdf",
      briefPdf: "/dummy/Presentación Propuesta Proyecto Brief Cliente Moderno Profesional Negro y Blanco.pdf",
      status: CampaignStatus.Progress,
      flow: '/dummy/FLOW.xlsx'
    }
  ],
  selectedCampaign: undefined,
  tabSelected: '',
  planningItems: [],

}

/** Definición del Store */
const Store: StateCreator<CampaignState> = (set, get) => ({

  ...initialState,

  retrieveCampaignItems: (sectionId: string) => {
    return new Promise((resolve, reject) => {

    })
  },

  addCampaignItem: (item: Campaign) => {
    return new Promise((resolve, reject) => {
      const items = get().campaignItems
      items.push(item)

      set({ campaignItems: items })
      resolve(true)
    })
  },

  updateCampaignItem: (item: Campaign) => {
    return new Promise((resolve, reject) => {

    })
  },

  getBudgets: () => {
    const planningSectionId = useNavigationStore.getState().navigationItems.filter((e) => e.sectionType === 'PLANNING')[0].id
    return new Promise((resolve, reject) => {
      planningService.getPlanningBySection(planningSectionId).then(
        response => {
          set({ planningItems: response.respuesta })
          resolve(true)
        }
      ).catch(() => {
        get().reset()
        reject()
      })
    })
  },

  setSelectedCampaign: (item: Campaign) => set({ selectedCampaign: item }),

  reset: () => set(initialState)

})

/** Exportación del Store */
export const useCampaignStore = create<CampaignState>()(
  immer(Store)
)