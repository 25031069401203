import React from "react"
import { useModalStore } from "@/core/stores/modal.store"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { IconType } from "react-icons"

interface CustomModalProps {
    id: string
    title?: string
    size: string
    icon?: IconType
    closeOnOverlayClick?: boolean
    closeOnEsc?: boolean
    withButtons?: boolean
    cancelText?: string
    acceptText?: string
    onClose?: () => void
    onCloseComplete?: () => void
    onAccept?: () => void
    onCancel?: () => void
    closeOnCancel?: boolean
    closeOnAccept?: boolean
    showCancelButton?: boolean
    showCloseButton?: boolean
    centerButtons?: boolean
    children: (data?: any) => React.ReactNode
}

const CustomModal: React.FC<CustomModalProps> = ({
    id,
    title,
    size,
    icon: Icon,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    withButtons = true,
    cancelText = 'Cancelar',
    acceptText = 'Aceptar',
    onClose,
    onCloseComplete,
    onAccept,
    onCancel,
    closeOnCancel = true,
    closeOnAccept = true,
    showCancelButton = true,
    showCloseButton = true,
    centerButtons = false,
    children
}) => {
    /** */
    const { modals, closeModal } = useModalStore()
    const modal = modals[id]

    const handleClose = () => {
        onCancel && onCancel()
        closeOnCancel && closeModal(id)
    }

    const handleAccept = () => {
        onAccept && onAccept()
        closeOnAccept && closeModal(id)
    }

    return (
        <Modal
            isOpen={modal?.isOpen}
            onClose={() => { closeModal(id); onClose && onClose() }}
            isCentered
            size={size}
            autoFocus={false}
            closeOnOverlayClick={closeOnOverlayClick}
            closeOnEsc={closeOnEsc}
            onCloseComplete={onCloseComplete}
        >
            <ModalOverlay />
            <ModalContent>
                {title && (
                    <ModalHeader className="flex items-center gap-2">
                        {Icon && <Icon />}
                        <span>{title}</span>
                    </ModalHeader>
                )}
                {showCloseButton && (
                    <ModalCloseButton />
                )}
                <ModalBody>
                    <div className={`${!withButtons ? 'pb-4' : ''}`}>
                        {children(modal?.data)}
                    </div>
                </ModalBody>
                {withButtons && (
                    <ModalFooter>
                        <div className={`w-full flex gap-6 ${centerButtons ? 'justify-center' : 'justify-end'}`}>
                            {showCancelButton && (
                                <Button variant='outline' onClick={handleClose}>
                                    {cancelText}
                                </Button>
                            )}
                            <Button onClick={handleAccept}>
                                {acceptText}
                            </Button>
                        </div>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    )
}

export default CustomModal