import { Apis } from "@/apis"
import HttpClient from "@/core/http/http-client"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { User } from "@/modules/auth/models/user"
import { AdminUser } from "../models/admin-user.model"
import { ApiResponseLegacy } from "@/core/models/api-response-legacy"
import { UserGroupResponse } from "../models/user-group-response.model"

export class UserService {
    /** */
    private http = new HttpClient( `${Apis.BASE}/api/general` )

    public getUsersByCompany( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( `/user/byCompany/${companyId}` )
    }

    public getUsersByGruop( companyId: string ): Promise<UserGroupResponse> {
        return this.http.get<any>( `/user/byGroup/${companyId}` )
    }

    public getUserById( userId: string ): Promise<User | AdminUser> {
        return this.http.get<User>( `/user/${userId}` )
    }

    public updateUser( body: AdminUser ): Promise<{body: {data: AdminUser}}> {
        return this.http.patch<{body: {data: AdminUser}}>( `/user/`, body )
    }

    public createUser( body: AdminUser ): Promise<ApiResponseLegacy> {
        return this.http.put<ApiResponseLegacy>( `/user/`, body )
    }

    public createExternalUser( body: AdminUser ): Promise<ApiResponseLegacy> {
        return this.http.put<ApiResponseLegacy>( `/user/external/`, body )
    }

    public deleteUser( userId: string ): Promise<AdminUser> {
        return this.http.delete<AdminUser>( `/user/${userId}` )
    }

}