import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Button, ButtonGroup } from '@chakra-ui/react'
import esLocale from '@fullcalendar/core/locales/es'
import { useAdminCalendarStore } from '../../stores/admin-calendar.store'
import useMountedEffect from '@/core/hooks/useMountedEffect'
import { useUserStore } from '@/modules/auth/stores/user.store'
import ActionIcon from '@/core/components/atoms/ActionIcon'
import { TbTrash, TbChevronLeft, TbChevronRight } from "react-icons/tb"

const Calendar: React.FC = () => {
    /** */
    const holidaysDays = useAdminCalendarStore( s => s.holidaysDays )
    const companyData = useUserStore( s => s.companyData )
    const { retrieveHolidaysDays, retrieveHolidayDayByYear, addHolidayDay, removeHolidayDay } = useAdminCalendarStore()
    const [ events, setEvents ] = useState<{ id: string, title: string, start: string, originData: string }[]>([])
    const [ calendarView, setCalendarView ] = useState( 'dayGridMonth' )
    const [ renderKey, setRenderKey ] = useState( 0 )
    let calendarRef: any = React.createRef()

    const handleDateClick = ( info: any ) => {
        const date: string = info.dateStr
        const year: number = Number( info.dateStr.split( '-' )[0] )
        const calendarId = holidaysDays.find( item => item.year === year )?.id

        if ( calendarId ) {
            const resultDate = new Date( date )

            addHolidayDay( calendarId, resultDate.toISOString() )
        } else {
            retrieveHolidayDayByYear( companyData?.id!, year ).then(
                result => {
                    if ( result ) {
                        const resultDate = new Date( date )

                        addHolidayDay( result.id, resultDate.toISOString() )
                    }
                }
            )
        }
    }

    const changeView = ( view: string ) => {
        let calendarApi = calendarRef.current.getApi()

        if ( view !== 'today' ) {
            setCalendarView( view )
            calendarApi.changeView( view )
        } else {
            calendarApi.today()
        }
    }

    const handlePrev = () => {
        let calendarApi = calendarRef.current.getApi()

        calendarApi.prev()
    }

    const handleNext = () => {
        let calendarApi = calendarRef.current.getApi()

        calendarApi.next()
    }

    const renderEventContent = ( eventInfo: any ) => {
        return (
            <div className="w-full flex items-center justify-between px-3 py-2 bg-brand-100 text-brand-500">
                <strong>{eventInfo.event.title}</strong>
                <ActionIcon icon={TbTrash}
                    label="Eliminar día feriado"
                    size="xs"
                    variant="ghost"
                    color='yellow'
                    onHandle={() => {
                        const calendar = holidaysDays.find( item => item.id === eventInfo.event.id )
                        const dateValue: string = eventInfo.event.extendedProps.originData

                        if ( calendar ) {
                            removeHolidayDay( calendar.id, dateValue )
                        }
                    }}
                />
            </div>
        );
    };

    useEffect( () => {
        if ( holidaysDays ) {
            let days: { id: string, title: string, start: string, originData: string }[] = []

            for ( const holiday of holidaysDays ) {
                for ( const nonWorking of holiday.nonWorkingDayList ) {
                    const item = {
                        id: holiday.id,
                        title: 'Día feriado',
                        // start: nonWorking,
                        start: nonWorking.split('T')[0],
                        originData: nonWorking
                    }

                    days.push( item )
                }
            }

            setEvents( days )
            setRenderKey( renderKey + 1 )
        }
    }, [ holidaysDays ] )

    useMountedEffect( () => {
        retrieveHolidaysDays( companyData?.id! )
    })

    return (
        <div className="relative">
            <div className="absolute right-0 flex gap-4">
                <ButtonGroup spacing={4} marginBottom={4}>
                    {/* <Button variant="outline" onClick={() => changeView('timeGridWeek')}>Semana</Button> */}
                    <Button
                        variant={calendarView === 'dayGridYear' ? 'solid' : 'outline'}
                        onClick={() => changeView('dayGridYear')}
                    >
                        Año
                    </Button>
                    <Button
                        variant={calendarView === 'dayGridMonth' ? 'solid' : 'outline'}
                        onClick={() => changeView('dayGridMonth')}
                    >
                        Mes
                    </Button>
                </ButtonGroup>
                <ButtonGroup spacing={4} marginBottom={4}>
                    <ActionIcon icon={TbChevronLeft}
                        label="Anterior"
                        size="md"
                        variant="outline"
                        color="brand"
                        onHandle={handlePrev}
                    />
                    <Button variant="outline"
                        onClick={() => changeView('today')}
                    >
                        Hoy
                    </Button>
                    <ActionIcon icon={TbChevronRight}
                        label="Siguiente"
                        size="md"
                        variant="outline"
                        color="brand"
                        onHandle={handleNext}
                    />
                </ButtonGroup>
            </div>
            <FullCalendar
                key={renderKey}
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={calendarView}
                events={events}
                dateClick={handleDateClick}
                editable={true}
                selectable={true}
                locale={esLocale}
                headerToolbar={{
                    right: ''
                }}
                eventClassNames="bg-brand-100 text-brand-500"
                eventContent={renderEventContent}
            />
        </div>
    )
}

export default Calendar