import { Box, Button, Flex, Select } from '@chakra-ui/react'
import React from 'react'
import PlanningRoadMap from './PlanningRoadMap';
import AddRoadMapModal from './AddRoadMapModal';
import { useModalStore } from '@/core/stores/modal.store';
import { DataUtil } from '@/core/utils/data.util';
import { BiPlus } from 'react-icons/bi';
import { RoadMap } from '@/core/models/planning.model';
import { usePlanningStore } from '../store/planning.store';

const RoadMapPage = () => {
  const { setRoadMapSelect, roadMapSelected, setTasksRoadMapselect, selectedPlanning } = usePlanningStore();
  const openModal = useModalStore(e => e.openModal);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const jsonTasks = selectedPlanning?.roadMapList?.filter((e: RoadMap) => e.name === event.target.value)[0];
    const isjsonTasks = DataUtil.isValidJsonString(jsonTasks?.jsonText ?? '');
    const tasks = isjsonTasks ? JSON.parse(jsonTasks?.jsonText ?? '') : [];
    const tasksDateParsed = tasks.map((e: any) => ({
      ...e,
      start: new Date(e.start),
      end: new Date(e.end),
    }))
    setTasksRoadMapselect(tasksDateParsed);
    setRoadMapSelect(event.target.value);
  };

  return (
    <Box pt={4}>
      <Flex align="center" justifyContent={selectedPlanning?.roadMapList?.length !== 0 ? 'space-between' : 'end'}>
        {selectedPlanning?.roadMapList?.length !== 0 &&
          <Select
            placeholder="Seleccione un roadmap"
            value={roadMapSelected}
            onChange={handleSelectChange}
            bg="white"
            borderRadius="md"
            mr={4}
            w={'auto'}
          >
            {selectedPlanning?.roadMapList?.map((e, index: number) => (
              <option value={e.name} key={index}>{e.name}</option>
            ))}
          </Select>
        }
        <Button leftIcon={<BiPlus />} colorScheme="brand" onClick={() => openModal('addRoadMapModal')}>
          Cargar roadmap
        </Button>
      </Flex>
      {
        roadMapSelected !== '' && 
        <PlanningRoadMap />
      }
      <AddRoadMapModal />
    </Box>
  )
}

export default RoadMapPage