import CustomModal from "@/core/components/organism/CustomModal"
import FileDropzone from "@/core/components/organism/FileDropzone"
import { useModalStore } from "@/core/stores/modal.store"
import { useBoolean } from "@chakra-ui/react"
import { useFormik } from "formik"
import { FcUpload } from "react-icons/fc"
import { useExcelTableStore } from "../../../core/stores/excel-table.store"
import { useState } from "react"

interface LoadProps {
  selectedFile?: File | null;
  setSelectedFile: (e: File | null) => void
}

const LoadFileModal: React.FC<LoadProps> = ({ selectedFile, setSelectedFile }) => {
  const setSelectedFileExcel = useExcelTableStore(e => e.setSelectedFile)
  const openSelectedFile = useExcelTableStore(e => e.openFile)
  const selectedFileExcel = useExcelTableStore(e => e.selectedFile)
  const closeModal = useModalStore(s => s.closeModal)
  const [hasErrorFile, setHasErrorFile] = useBoolean(false)
  const [file, setFile] = useState<File | null>(null)

  const handleBudgetFile = () => {
    if (file === null) {
      setHasErrorFile.on()
      return
    }
    setHasErrorFile.off()
    setSelectedFile(file)
    closeModal('LoadFileModal')
  }

  const loadFileForm = useFormik({
    onSubmit: handleBudgetFile,
    validateOnBlur: true,
    initialValues: {}
  })

  const handleFileUpload = (file: File) => {
    setFile(file);
  }

  return (
    <CustomModal id="LoadFileModal"
      title="Cargar documento"
      size="xl"
      icon={FcUpload}
      showCancelButton={false}
      onAccept={loadFileForm.handleSubmit}
      closeOnAccept={false}
      acceptText="Cargar">
      {() => (
        <form onSubmit={loadFileForm.handleSubmit}
          className="flex flex-col gap-4">
          <FileDropzone accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onDropFile={handleFileUpload} />
          {hasErrorFile && (
            <span className="text-red-500">
              El archivo es requerido
            </span>
          )}
        </form>
      )}
    </CustomModal>
  )
}

export default LoadFileModal