import { useUserStore } from "@/modules/auth/stores/user.store"
import { AdminUser } from "../../models/admin-user.model"
import { useAdminUserStore } from "../../stores/admin-user.store"
import { useState } from "react"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import BrandBadge from "@/core/components/atoms/BrandBadge"
import ActionIcon from "@/core/components/atoms/ActionIcon"
import { TbFilterSearch, TbPencil, TbTrash, TbUserPlus } from "react-icons/tb"
import { Box, Button, Grid, GridItem, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Select } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

interface UsersGridProps {
    pagesPerView?: number
    selectedUserIds?: string[]
    onNewUser?: () => void
    onAddUser?: ( user: AdminUser ) => void
    onEdit?: ( user: AdminUser ) => void
    onDelete?: ( user: AdminUser ) => void
}

const UsersGrid: React.FC<UsersGridProps> = ({
    pagesPerView = 10,
    selectedUserIds,
    onNewUser,
    onAddUser,
    onEdit,
    onDelete
}) => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const { retrieveUsers, selectUser } = useAdminUserStore()
    const users = useAdminUserStore( s => s.users )
    const [ selectedUserType, setSelectedUserType ] = useState( 'all' )
    const [ inputFilter, setInputFilter] = useState<string>('')
    const [ inputAddUserFilter, setInputAddUserFilter] = useState<string>('')

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'fullName', sortable: true },
        { header: 'Correo', accessor: 'email', sortable: true },
        {
            header: 'Tipo de usuario', accessor: 'userType', sortable: true, customRow: [
                ( row: AdminUser ) => <BrandBadge label={row.userType!} color={row.external ? 'purple' : 'default'} />
            ]
        }
    ]

    if ( onEdit || onDelete ) {
        columns.push({
            header: 'Acciones', accessor: '', positionRow: 'end', customRow: [
                row => onEdit ? <ActionIcon label="Editar usuario" icon={TbPencil} onHandle={() => handleEditUser( row )} /> : <></>,
                row => onDelete ? <ActionIcon label="Eliminar usuario" icon={TbTrash} onHandle={() => handleDeleteUser( row )} /> : <></>
            ]
        })
    }

    const handleEditUser = ( row: AdminUser ) => {
        selectUser( row )
        onEdit && onEdit( row )
    }

    const handleDeleteUser = ( row: AdminUser ) => {
        selectUser( row )
        onDelete && onDelete( row )
    }

    const handleAddUser = ( user: AdminUser ) => {
        onAddUser && onAddUser( user )
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    const handleFilterAddUserChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputAddUserFilter( event.target.value )
    }

    const filterUsers = () => {
        let filteredUsers = users

        if ( selectedUserIds ) {
            filteredUsers = users.filter( item => selectedUserIds.includes( item.id ) )
        }

        const filteredUsersByType = filteredUsers.filter( item => {
            if ( selectedUserType === 'internal' ) {
                return !item.external
            } else if ( selectedUserType === 'external' ) {
                return item.external
            } else {
                return true
            }
        })

        const filteredUsersByInput = filteredUsersByType.filter( item => {
            const searchString = `${item.name} ${item.lastName} ${item.email} ${item.userType || ''}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }

    const filterAddUser = () => {
        const filteredUsersByInput = users.filter( item => {
            const searchString = `${item.name} ${item.lastName} ${item.email} ${item.userType || ''}`.toLowerCase()

            return searchString.includes( inputAddUserFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }

    useMountedEffect( () => {
        retrieveUsers( companyData?.id! )
    })

    return (
        <article className="flex flex-col gap-4">
            {/* Filtros y acciones */}
            <div className="flex items-center justify-between">
                {( filterUsers().length > 0 && selectedUserIds ) && (
                    <Select
                        bg="white"
                        borderRadius="md"
                        w={'auto'}
                        value={selectedUserType}
                        onChange={( event ) => setSelectedUserType( event.target.value )}
                    >
                        <option value="all">Todos</option>
                        <option value="internal">Internos</option>
                        <option value="external">Externos</option>
                    </Select>
                )}
                <div className="flex items-center gap-4">
                    {( filterUsers().length > 0 && selectedUserIds ) && (
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <TbFilterSearch size={20} className="text-gray-400" />
                            </InputLeftElement>
                            <Input type="text"
                                placeholder="Filtrar por..."
                                value={inputFilter}
                                onChange={handleFilterChange}
                            />
                        </InputGroup>
                    )}
                    {onNewUser && (
                        <Box>
                            <Button
                                leftIcon={<TbUserPlus />}
                                colorScheme="brand"
                                onClick={onNewUser}>
                                Agregar usuario
                            </Button>
                        </Box>
                    )}
                    <Menu>
                        <MenuButton as={Button}
                            rightIcon={<ChevronDownIcon />}
                            w="16rem"
                        >
                            Agregar usuario
                        </MenuButton>
                        <MenuList zIndex="10">
                            <div className="py-2 px-2">
                                <Input type="text"
                                    placeholder="Filtrar por..."
                                    value={inputAddUserFilter}
                                    onChange={handleFilterAddUserChange}
                                />
                            </div>
                            <Box maxH="20rem"
                                overflow="auto">
                                {filterAddUser().map( item => (
                                    <MenuItem key={item.id}
                                        onClick={() => handleAddUser( item )}>
                                        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                                            <GridItem colSpan={{ base: 12, md: 8 }}>
                                                <small>{item.fullName}</small>
                                            </GridItem>
                                            <GridItem colSpan={{ base: 12, md: 4 }}>
                                                <BrandBadge
                                                    label={item.userType!}
                                                    color={item.external ? 'purple' : 'default'}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </MenuItem>
                                ))}
                                {filterAddUser().length === 0 && (
                                    <small className="p-4">
                                        No se encontraron coincidencias
                                    </small>
                                )}
                            </Box>
                        </MenuList>
                    </Menu>
                </div>
            </div>
            {/* Datos */}
            {( filterUsers().length > 0 && selectedUserIds ) && (
                <BrandTable
                    columns={columns}
                    data={filterUsers()}
                    rowsPerPageOptions={[5, 10, 15]}
                    defaultRowsPerPage={5}
                    showPagination={true}
                    pagesPerView={pagesPerView}
                />
            )}
            {( filterUsers().length === 0 || !selectedUserIds ) && (
                <div className="flex justify-center">
                    No hay usuarios
                </div>
            )}
            {/* Modals | Alerts */}
            {/* <CreateOrEditUserModal />
            <ConfirmUserDeletionAlert /> */}
        </article>
    )
}

export default UsersGrid