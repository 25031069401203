import React, { useEffect } from "react";
import { Task, ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { ViewSwitcher } from "./RoadMapTimeOptions";
/* import { getStartEndDateForProject } from "../hooks/useRoadmapHelpers"; */
import { useModalStore } from "@/core/stores/modal.store";
import EditTaskModal from "./EditTaskModal";
import { usePlanningStore } from "../store/planning.store";
import DateRoadmapAlert from "@/core/components/molecules/DateRoadmapAlert";

// Init
const PlanningRoadMap = () => {
  const { setTasksRoadMapselect, tasksRoadmapSelected } = usePlanningStore()
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const openModal = useModalStore(s => s.openModal);
  //const [tasks, setTasks] = React.useState<Task[]>(tasksRoadmapSelected ?? []);
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
    console.log("On date change Id:" + task);
    let oldTask: Task = tasksRoadmapSelected.filter(e => e.id === task.project)[0]
    if (task.start < oldTask.start || task.end > oldTask.end) {
      /* setTasksRoadMapselect(tasksRoadmapSelected); */
      openModal('DateRoadmapAlert', task)
      //alert('La fecha de la tarea está fuera del rango del proyecto')
    } else {
      let newTasks: Task[] = tasksRoadmapSelected.map(t => (t.id === task.id ? task : t));
      setTasksRoadMapselect(newTasks);
    }
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasksRoadMapselect(tasksRoadmapSelected.filter(t => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasksRoadMapselect(tasksRoadmapSelected.map(t => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    openModal('editTaskModal', task)
  };

  const handleClick = (task: Task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    console.log(task, tasksRoadmapSelected);
    setTasksRoadMapselect(tasksRoadmapSelected.map(t => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };


  useEffect(() => {
    if (tasksRoadmapSelected.length !== 0 && isChecked === true) {
      (document.querySelectorAll('._WuQ0f')[0] as HTMLInputElement).innerHTML = `<div class='tagsRoadMap'>Tareas</>`;
      (document.querySelectorAll('._WuQ0f')[1] as HTMLInputElement).innerHTML = `<div class='tagsRoadMap'>Inicio</>`;
      (document.querySelectorAll('._WuQ0f')[2] as HTMLInputElement).innerHTML = `<div class='tagsRoadMap'>Fin</>`;
    }
  }, [tasksRoadmapSelected, isChecked])

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={viewMode => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {tasksRoadmapSelected.length !== 0 &&
        <Gantt
          tasks={tasksRoadmapSelected ?? []}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "120px" : ""}
          columnWidth={columnWidth}
          locale="es"
        />
      }
      {/* <h3>Gantt With Limited Height</h3>
      <Gantt
        tasks={tasks}
        viewMode={view}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onClick={handleClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "155px" : ""}
        ganttHeight={300}
        columnWidth={columnWidth}
      /> */}
      <EditTaskModal />
      <DateRoadmapAlert />
    </div>
  );
};

export default PlanningRoadMap;
