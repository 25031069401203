import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import PlanningSectionRoutes from "./pages/planning-section/PlanningSection.routes"

const PlanningPage = lazy(() => import('./pages/PlanningPage'))
const PlanningSectionLayout = lazy(() => import('./pages/planning-section/PlanninSectionLayout'))

const PlanningRoutes: RouteObject[] = [
  { path: '', element: <PlanningPage /> },
  {
    path: 'detail',
    element: <PlanningSectionLayout />, 
    children: [
      { index: true, element: <Navigate to="roadmap" replace /> },
      ...PlanningSectionRoutes
    ]
  },
]

export default PlanningRoutes