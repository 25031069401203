import CustomModal from '@/core/components/organism/CustomModal'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

function CompairSummaryModal() {
  return (
    <CustomModal id="CompairSummaryModal"
      title="Resumen de cambios"
      size="xl"
      showCancelButton={false}
    >
      {() => (
        <Table variant='simple'>
          <Tbody>
            <Tr>
              <Td>Para el medio de <b>televisión de paga</b>  se cambió el monto de <b>$2,9940.00</b> a <b>$2,7839.74</b></Td>
            </Tr>
            <Tr>
              <Td>En la celda <b>D3</b> se cambió la duración de <b>10”</b> a <b>15”</b></Td>
            </Tr>
            <Tr>
              <Td>En la celda <b>F23</b> se cambió el monto de <b>$22,3940.00</b> a <b>$21,7839.00</b></Td>
            </Tr>
            <Tr>
              <Td>En la celda <b>D9</b> se cambió la duración de <b>15”</b> a <b>6”</b></Td>
            </Tr>
            <Tr>
              <Td>En la celda <b>F23</b> se cambió el horario de <b>13:00</b> a <b>17:00</b></Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </CustomModal>
  )
}

export default CompairSummaryModal