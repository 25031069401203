import { Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import AuthLayout from '@/layouts/AuthLayout'
import ViewsLayout from '@/layouts/ViewsLayout'
import AuthRoutes from './Auth.routes'
import ViewsRoutes from './Views.routes'

const Router = () => {
    /** */
    return (
        <Suspense>
            {
                useRoutes([
                    {
                        path: '*',
                        element: <Navigate to='/views' />
                    },
                    {
                        path: 'auth',
                        element: <AuthLayout />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="signin" replace />
                            },
                            ...AuthRoutes
                        ]
                    },
                    {
                        path: 'views',
                        element: <ViewsLayout />,
                        children: [
                            ...ViewsRoutes
                        ]
                    }
                ])
            }
        </Suspense>
    )
}

export default Router