import { Button } from "@chakra-ui/react"
import { TbChevronLeft } from "react-icons/tb"
import { TbChevronRight } from "react-icons/tb"

interface BrandPaginationProps {
    totalRows: number
    rowsPerPage: number
    currentPage: number
    pagesPerView?: number
    onPageChange: ( page: number ) => void
}

const BrandPagination: React.FC<BrandPaginationProps> = ({
    totalRows,
    rowsPerPage,
    currentPage,
    pagesPerView = 10,
    onPageChange
}) => {
    /** */
    const totalPages = Math.ceil( totalRows / rowsPerPage )
    const middle = Math.floor( pagesPerView / 2 )

    const generatePages = () => {
        /** */
        const pages = []

        if ( totalPages <= pagesPerView ) {
            for ( let i = 1 ; i <= totalPages ; i++ ) {
                pages.push( i )
            }
        } else {
            if ( currentPage <= middle ) {
                for ( let i = 1 ; i <= pagesPerView ; i++ ) {
                    pages.push( i )
                }

                pages.push( 'forward' )
            } else if ( currentPage > totalPages - middle ) {
                pages.push( 'backward' )

                for ( let i = totalPages - pagesPerView + 1 ; i <= totalPages ; i++ ) {
                    pages.push( i )
                }
            } else {
                pages.push( 'backward' )

                for ( let i = currentPage - middle + 1 ; i <= currentPage + middle - 1 ; i++ ) {
                    pages.push( i )
                }

                pages.push( 'forward' )
            }
        }

        return pages
    }

    const handlePageChange = ( page: string | number ) => {
        if ( typeof page === 'number' && page !== currentPage ) {
            onPageChange( page )
        } else if ( page === 'forward' ) {
            const nextPage = Math.min( currentPage + pagesPerView, totalPages )
            onPageChange( nextPage )
        } else if ( page === 'backward' ) {
            const prevPage = Math.max( currentPage - pagesPerView, 1 )
            onPageChange( prevPage )
        }
    }

    const handlePrevPage = () => {
        if ( currentPage > 1 ) {
            onPageChange( currentPage - 1 )
        }
    }

    const handleNextPage = () => {
        if ( currentPage < totalPages ) {
            onPageChange( currentPage + 1 )
        }
    }

    const pages = generatePages()

    return (
        <div className="flex items-center gap-2">
            <Button
                variant="ghost"
                isDisabled={currentPage === 1}
                onClick={handlePrevPage}>
                <TbChevronLeft />
            </Button>
            {pages.map(( page, index ) => (
                <Button key={index}
                    onClick={ () => handlePageChange( page )}
                    bg={page === currentPage ? 'brand.500': 'gray.100'}
                    color={page === currentPage ? 'brand.50' : ''}
                    sx={{
                        _hover: {
                            bg: page === currentPage ? 'brand.400' : 'gray.200',
                            color: page === currentPage ? 'white' : 'black',
                        }
                    }}
                >
                    {typeof page === 'number' ? page : '...'}
                </Button>
            ))}
            <Button
                variant="ghost"
                isDisabled={currentPage === totalPages}
                onClick={handleNextPage}>
                <TbChevronRight />
            </Button>
        </div>
    )
}

export default BrandPagination