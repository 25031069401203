import BrandBox from "@/core/components/molecules/BrandBox"
import { FcRules } from "react-icons/fc"
import { Outlet } from "react-router-dom"
import AdminTabs from "./components/AdminTabs"
import HeadModule from "@/core/components/molecules/HeadModule"

const AdminLayout: React.FC = () => {
    /** */
    return (
        <>
            <BrandBox className="pb-0">
                <HeadModule
                    title="Administración"
                    icon={FcRules}
                />
                <AdminTabs />
            </BrandBox>
            <section className="px-4 py-6">
                <Outlet />
            </section>
        </>
    )
}

export default AdminLayout