import { ArrowBackIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

interface DocumentHeadProps {
  nameFile?: string
  setCurrentIndex?: (index: number) => void
  lenghtData?: number
  currentIndex?: number
}
function DocumentHead({ nameFile, setCurrentIndex, lenghtData, currentIndex }: DocumentHeadProps) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // -1 indica que queremos ir una página atrás en el historial
  };
  return (
    <Flex justifyContent='space-between' p={3}>
      <Flex gap={2} alignItems={'center'}>
        <IconButton
          aria-label="Back"
          icon={<ArrowBackIcon />}
          colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
          variant="ghost"
          onClick={handleBack} // Agrega la función de retroceso
        />
        <Text fontWeight="bold">{nameFile}</Text>
      </Flex>
      <Flex alignItems='center' columnGap={3}>
        <IconButton
          aria-label="Prev"
          icon={<IoMdArrowBack />}
          colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
          variant="outline"
          onClick={() => setCurrentIndex?.(currentIndex! - 1)} // Agrega la función de retroceso
          isDisabled={ currentIndex === 0}
        />
        <IconButton
          aria-label="Next"
          icon={<IoMdArrowForward />}
          colorScheme="brand" // Ajusta esto al esquema de color de tu proyecto
          variant="outline"
          onClick={() => setCurrentIndex?.(currentIndex! + 1)} // Agrega la función de retroceso
          isDisabled={ currentIndex === (lenghtData! - 1)}
        />
      </Flex>
    </Flex>
  )
}

export default DocumentHead