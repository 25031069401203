import { StyleFunctionProps, extendTheme, withDefaultColorScheme } from "@chakra-ui/react"

const colors = {
    brand: {
        50: '#e3eaff',
        100: '#c0d3fe',
        200: '#5e8efd',
        300: '#3671fc',
        400: '#0e55fb',
        500: '#0344dc',
        600: '#033ec9',
        700: '#0338b5',
        800: '#0232a1',
        900: '#022c8d'
    },
    dark: {
        50: "#f2f2f2",
        100: "#d9d9d9",
        200: "#bfbfbf",
        300: "#a6a6a6",
        400: "#8c8c8c",
        500: "#737373",
        600: "#595959",
        700: "#404040",
        800: "#262626",
        900: "#0d0d0d"
    }
}

const styles = {
    global: {
        " *, *::before, ::after": {
            borderColor: "gray.200 !important",
            wordWrap: "break-word"
        }
    }
}

const components = {
    ModalCloseButton: {
        baseStyle: {
            outline: "none",
            _focus: {
                outline: "none"
            }
        }
    },
    Input: {
        baseStyle: (props: StyleFunctionProps) => ({
            field: {
                backgroundColor: props.colorMode === "dark" ? "gray.700" : "white !important",
                borderRadius: "8px"
            }
        })
    },
    Button: {
        baseStyle: {
            borderRadius: "8px"
        },
        variants: {
            outline: {
                border: '1px solid',
                borderColor: 'rgb(3 68 220 / 50%) !important'
            }
        }
    },
    Alert: {
        baseStyle: {
            container: {
                borderRadius: "16px"
            }
        }
    },
    Tabs: {
        variants: {
            'unstyled': {
                tab: {
                    fontWeight: 'semibold',
                    padding: '5px 15px',
                    _selected: {
                        color: 'brand.500',
                        bg: 'brand.50',
                        borderRadius: '6px 6px 0 0'
                    },
                },
                tablist: {
                    borderBottom: '2px solid',
                    borderColor: 'gray.200',
                },
                indicator: {
                    height: '2px',
                    bg: 'brand.500',
                    mt: '-3px'
                },
            },
        },
    },
    Switch: {
        baseStyle: {
            track: {
                _checked: {
                    bg: "brand.500"
                }
            }
        }
    }
}

const chakraTheme = extendTheme(
    withDefaultColorScheme({
        colorScheme: "brand",
        components: ["Button", "Checkbox", "Radio", "Input"],
    }),
    { colors, components, styles }
)

export default chakraTheme
