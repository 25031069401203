import { BsExclamationCircleFill } from "react-icons/bs"
import CustomModal from "./CustomModal"

const RestrictedActionModal = () => {
    /** */
    const handleRedirectHome = () => {
        /** */
    }

    return (
        <CustomModal id="restrictedActionModal" size="lg"
            acceptText="Aceptar"
            onAccept={handleRedirectHome}
            showCancelButton={false}
            centerButtons={true}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseButton={false}
        >
            {( data ) => (
                <div className="w-full flex flex-col items-center space-y-2 py-4">
                    <div className="flex flex-col gap-2 text-brand-500">
                        <BsExclamationCircleFill size={50} />
                        <p className="title-3 font-semibold">
                            {data?.title}
                        </p>
                    </div>
                    <div className="text-center leading-none">
                        <h3 className=" mt-2 font-bold">{data?.module}</h3>
                        <h3 className=" mt-2">{data?.message}</h3>
                    </div>
                </div>
            )}
        </CustomModal>
    );
};

export default RestrictedActionModal;
