import { useExcelTableStore } from "@/core/stores/excel-table.store";
import { ExcelUtils } from "@/core/utils/excel-table.util";
//import { useModalStore } from "@/core/stores/modal.store";
import { Avatar, Badge, Box, Text, useTheme } from "@chakra-ui/react";
import { MdOutlineOpenInNew } from "react-icons/md";

interface ItemProps {
  imgAvatar: string;
  name: string;
  titleComment: string;
  comment: string;
  date: string
  version: string;
  x: number;
  y: number;
  selectedVersion?: string;
  setSelectedVersion?: (selectedVersion: string) => void;
}

const BinnacleItem: React.FC<ItemProps> = ({ imgAvatar, name, titleComment, comment, date, version, x, y, selectedVersion, setSelectedVersion }) => {
  const theme = useTheme()
  const { spread, selectedFile } = useExcelTableStore()
  //const closeModal = useModalStore(e => e.closeModal);



  const handleCell = () => {
    if (selectedFile?.name !== version) {
      setSelectedVersion?.(version)
    }
    setTimeout(() => {
      const sheet = spread?.getActiveSheet()
      if (sheet) {
        sheet.setActiveCell(y, x)
        ExcelUtils.applyCellStyles(sheet, y, x)
      }
    }, 1500);
    //closeModal('BinnacleModal')
  };
  return (
    <Box borderBottom={'2px solid'} mb={5}>
      <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={2} marginBottom={2}>
        <Avatar name={name}
          background={theme.colors.brand[500]}
          color={theme.colors.brand[50]}
          size={'sm'}
        />
        <Text fontSize='lg' fontWeight={'bold'}>{name}</Text>
        <Badge colorScheme={'brand'} rounded={10} padding={'1px 8px'} fontWeight={'semibold'} textTransform={'capitalize'} fontSize='sm'>{version}</Badge>
      </Box>
      <Box display={'flex'} flexWrap={'wrap'} flexDirection={'column'} gap={2}>
        <Text fontSize='sm' fontWeight={'bold'}>
          <span onClick={() => handleCell()} className={'cursor-pointer flex items-center gap-2'}>{titleComment}<MdOutlineOpenInNew /></span>
        </Text>
        <Text fontSize='md' color="black">
          {comment}
        </Text>
        <Text fontSize={'sm'} color={'gray.500'}>
          {date}
        </Text>
      </Box>
    </Box>
  )
}

export default BinnacleItem