import { Apis } from "@/apis";
import HttpClient from "@/core/http/http-client";
import { GenericApiResponse } from "@/core/models/generic-api-response";
import { UserGroupRequest } from "../models/user-group-request.model";

export class UserGroupService {
    /** */
    private http = new HttpClient( `${Apis.ORKESTADOC}/api/v1` )

    public getUserGroupsByCompany( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get<GenericApiResponse>( `/userGroup/companyId/${companyId}` )
    }

    public createUserGroup( body: UserGroupRequest ): Promise<GenericApiResponse> {
        return this.http.put( '/userGroup', body )
    }

    public updateUserGroup( body: UserGroupRequest ): Promise<GenericApiResponse> {
        return this.http.patch<GenericApiResponse>( '/userGroup', body )
    }

    public deleteUserGroup( userGroupId: string ): Promise<GenericApiResponse> {
        return this.http.delete( `/userGroup/${userGroupId}` )
    }

}