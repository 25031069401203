import { Button } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { DndProvider, DropTargetMonitor } from "react-dnd"
import { useDrop } from "react-dnd"
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend"
import useCustomToast from "@/core/hooks/useCustomToast"

interface FileDropzoneProps {
    onDropFile: ( file: File ) => any
    accept?: string
    defaultFile?: File | null;
}

const FileDropzoneComp = ( { onDropFile, accept, defaultFile }: FileDropzoneProps ) => {
    /** */
    const toast = useCustomToast()
    const [ { canDrop, isOver }, drop ] = useDrop( useDropOptions, [] )

    const [ updatedFile, setUpdatedFile ] = useState<File | null>( null )

    function useDropOptions() {
        return {
            accept: [NativeTypes.FILE],

            drop( item: { files: any[] } ) {
                if ( onDrop ) {
                    onDrop( item.files )
                }
            },

            canDrop( item: any ) {
                return true
            },

            hover( item: any ) { },

            collect: ( monitor: DropTargetMonitor ) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop()
                }
            }
        }
    }

    function onDrop( files: File[] ) {
        if ( files?.length > 1 ) {
            toast.error( 'Solo se puede subir un archivo a la vez' )
            return
        }
        if ( files[0].size === 0 ) {
            toast.error( 'El archivo está vacío (0 KB) o corrupto' )
            console.error( "El archivo está vacío (0 KB)." )
            return
        }

        setUpdatedFile( files[0] )
        onDropFile( files[0] )
    }

    const inputRef = useRef<any>()
    const isActive = canDrop && isOver

    return (
        <div
            ref={drop}
            className={`relative w-full min-w-[350px] h-36 flex flex-col justify-center items-center gap-3  border-2 border-dashed border-brand-500 rounded-2xl ${
                isActive ? " bg-brand-50" : "bg-slate-50"
            }`}
        >
            {(!defaultFile && !updatedFile) ? (
                <>
                    <div className="text-xl text-brand-600 font-medium">Puedes arrastrar y soltar un archivo aquí</div>
                    <div className="text-base text-brand-600 font-medium">ó</div>
                </>
            ) : (
                <div className="text-xl text-brand-600 font-medium text-center">{defaultFile?.name ?? updatedFile?.name}</div>
            )}
            <label htmlFor="vault-file-upload">
                <input
                    accept={accept || "*"}
                    onInput={(e: any) => onDrop(e.target.files)}
                    ref={inputRef}
                    id="vault-file-upload"
                    name="vault-file-upload"
                    type="file"
                    className="absolute bottom-0 w-1 h-1 invisible"
                ></input>
                <Button variant="outline" size="sm" onClick={() => inputRef.current?.click()}>
                    Seleccionar archivo dando click aquí
                </Button>
            </label>
        </div>
    )
}

const FileDropzone = ( props: FileDropzoneProps ) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <FileDropzoneComp {...props} />
        </DndProvider>
    )
}

export default FileDropzone
