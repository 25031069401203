import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

function NegotiateRatePage() {
  return (
    <Box pt={4} display={'inline-flex'}>
      <Tabs position='relative' variant='unstyled'>
        <TabList>
          <Tab>Lista de proveedores</Tab>
          <Tab>Negociación en proceso</Tab>
        </TabList>
        <TabIndicator />
        <TabPanels>
          <TabPanel>
            <p>Lista de proveedores</p>
          </TabPanel>
          <TabPanel>
            <p>Negociación en proceso</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default NegotiateRatePage