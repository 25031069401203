import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { AdminUser } from "../../models/admin-user.model"
import { useAdminUserStore } from "../../stores/admin-user.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useAdminUserGroupStore } from "../../stores/admin-user-group.store"

const ConfirmUserDeletionOnGroup: React.FC = () => {
    /** */
    const selectedUser = useAdminUserStore( s => s.selectedUser )
    const selectedUserGroup = useAdminUserGroupStore( s => s.selectedUserGroup )
    const { deselectedUser } = useAdminUserStore()
    const { updateUserGroup } = useAdminUserGroupStore()
    const toast = useCustomToast()

    const handleDeleteUser = () => {
        const userGroup = selectedUserGroup!
        const userList = userGroup?.userList!.filter( item => item !== selectedUser!.id )

        userGroup.userList = userList

        updateUserGroup( userGroup! ).then(
            () => toast.success( 'Usuario eliminado del grupo correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al intentar eliminar el usuario del grupo' )
        )
    }

    return (
        <CustomAlertDialog
            id="confirmUserDeletionOnGroup"
            title="Eliminar usuario"
            size="xl"
            cancelText="Cancelar"
            acceptText="Estoy de acuerdo"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onAccept={handleDeleteUser}
            onCloseComplete={deselectedUser}
        >
            {( data?: AdminUser ) => (
                <>
                    <p className="text-lg">
                        Se eliminará el usuario <strong className="text-brand-500">{data?.fullName}</strong> del grupo. ¿Estás de acuerdo?
                    </p>
                </>
            )}
        </CustomAlertDialog>
    )
}

export default ConfirmUserDeletionOnGroup