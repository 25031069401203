import { StateStorage, createJSONStorage } from "zustand/middleware";

const Storage: StateStorage = {

    getItem: function ( name: string ): any {

        const encryptedData = sessionStorage.getItem( name )

        return encryptedData
            ? atob( encryptedData )
            : undefined
    },

    setItem: function ( name: string, value: string ): void {

        const encryptedData = btoa( value )

        sessionStorage.setItem( name, encryptedData )
    },

    removeItem: function ( name: string ): void {
        sessionStorage.removeItem( name )
    }

}

export const SecureSessionStorage = createJSONStorage( () => Storage )