export const MediosBarData = {
    medios1: {
        amounts: [
            { name: 'Inversión', amount: 1684891.90, currency: "$" },
            { name: 'Visibilidad', amount: 521875.08, currency: "$" },
            { name: 'Bonificable', amount: 429032.50, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 93 },
            { name: 'Semana 2', ns: 50 },
            { name: 'Semana 3', ns: 75 },
            { name: 'Semana 4', ns: 23 }
        ]
    },
    medios2: {
        amounts: [
            { name: 'Inversión', amount: 1684891.90, currency: "$" },
            { name: 'Bonificable', amount: 429032.50, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 93 },
            { name: 'Semana 2', ns: 50 },
            { name: 'Semana 3', ns: 75 },
            { name: 'Semana 4', ns: 23 }
        ]
    },
    medios3: {
        amounts: [
            { name: 'Inversión', amount: 144546.02, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 93 },
            { name: 'Semana 2', ns: 50 },
            { name: 'Semana 3', ns: 75 },
            { name: 'Semana 4', ns: 23 }
        ]
    },
    medios4: {
        amounts: [
            { name: 'Inversión', amount: 0, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 0 },
            { name: 'Semana 2', ns: 0 },
            { name: 'Semana 3', ns: 0 },
            { name: 'Semana 4', ns: 0 }
        ]
    },
    medios5: {
        amounts: [
            { name: 'Inversión', amount: 144546.02, currency: "$" },
            { name: 'Visibilidad', amount: 144546.02, currency: "$" },
            { name: 'Bonificable', amount: 144546.02, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 93 },
            { name: 'Semana 2', ns: 50 },
            { name: 'Semana 3', ns: 75 },
            { name: 'Semana 4', ns: 23 }
        ]
    },
    medios6: {
        amounts: [
            { name: 'Inversión', amount: 144546.02, currency: "$" }
        ],
        data: [
            { name: 'Semana 1', ns: 93 },
            { name: 'Semana 2', ns: 50 },
            { name: 'Semana 3', ns: 75 },
            { name: 'Semana 4', ns: 23 }
        ]
    }
}