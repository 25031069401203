import CustomModal from "@/core/components/organism/CustomModal"
import { FcBadDecision } from "react-icons/fc"
import { useAdminUserStore } from "../../stores/admin-user.store"
import BrandTable, { BrandTableColumn } from "@/core/components/organism/BrandTable"
import { AdminUser } from "../../models/admin-user.model"
import BrandBadge from "@/core/components/atoms/BrandBadge"
import { Input, InputGroup, InputLeftElement, Switch } from "@chakra-ui/react"
import { TbFilterSearch } from "react-icons/tb"
import { useState } from "react"
import { useAdminOtpPoliciesStore } from "../../stores/admin-otp-policies.store"
import { useUserStore } from "@/modules/auth/stores/user.store"
import useCustomToast from "@/core/hooks/useCustomToast"

const AddOtpUsersExceptionsModal: React.FC = () => {
    /** */
    const companyData = useUserStore( s => s.companyData )
    const users = useAdminUserStore( s => s.users )
    const { mutateUsers } = useAdminUserStore()
    const { updateNotApplicableUsers } = useAdminOtpPoliciesStore()
    const [ inputFilter, setInputFilter] = useState<string>('')
    const toast = useCustomToast()

    const columns: BrandTableColumn[] = [
        { header: 'Nombre', accessor: 'fullName', sortable: true },
        { header: 'Correo', accessor: 'email', sortable: true },
        {
            header: 'Tipo de usuario', accessor: 'userType', sortable: true, customRow: [
                ( row: AdminUser ) => <BrandBadge label={row.userType!} color={row.external ? 'purple' : 'default'} />
            ]
        },
        {
            header: 'Aplica OTP', accessor: '', positionRow: 'center', customRow: [
                row => <Switch id={'sw-policy-otp'+row.id}
                            size="md"
                            isChecked={row.isActive}
                            onChange={() => handleChangeUser( row )}
                        />
            ]
        }
    ]

    const handleChangeUser = ( user: AdminUser ) => {
        const updatedUsers = users.map( item => {
            if ( item.id === user.id ) item.isActive = !item.isActive

            return item
        })
        const notApplicables = updatedUsers.filter( item => !item.isActive ).map(
            item => { return ( item.id ) }
        )

        updateNotApplicableUsers( companyData?.id!, notApplicables ).then(
            () => toast.success(
                `Usuario ${user.fullName} ${user.isActive ? 'removido de' : 'agregado a'} las excepciones correctamente`
            )
        ).catch(
            () => toast.error( 'Ocurrió un error al agregar usuario a las excepciones' )
        )

        mutateUsers( updatedUsers )
    }

    const filterUsers = () => {
        const filteredUsersByInput = users.filter( item => {
            const searchString = `${item.name} ${item.lastName} ${item.email} ${item.userType || ''}`.toLowerCase()

            return searchString.includes( inputFilter.toLowerCase() )
        })

        return filteredUsersByInput
    }

    const handleFilterChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setInputFilter( event.target.value )
    }

    return (
        <CustomModal id="addOtpUsersExceptionsModal"
            size="5xl"
            icon={FcBadDecision}
            title="Excepciones de usuarios"
            closeOnAccept={false}
            withButtons={false}
        >
            {() => (
                <div className="flex flex-col gap-4">
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <TbFilterSearch size={20} className="text-gray-400" />
                        </InputLeftElement>
                        <Input type="text"
                            placeholder="Filtrar por..."
                            value={inputFilter}
                            onChange={handleFilterChange}
                        />
                    </InputGroup>
                    <BrandTable
                        columns={columns}
                        data={filterUsers()}
                        rowsPerPageOptions={[5, 10, 15]}
                        defaultRowsPerPage={5}
                        showPagination={true}
                        pagesPerView={5}
                    />
                </div>
            )}
        </CustomModal>
    )
}

export default AddOtpUsersExceptionsModal