import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { AdminPolicy } from "../models/admin-policy.model";
import { PasswordPolicy } from "../models/password-policy.model";
import { ConfigService } from "../services/config.service";
import { NotApplicableUser } from "../models/not-applicable-user.model";

/** Services */
const configService = new ConfigService()
/** Contrato del Store */
interface AdminOtpPoliciesState {
    policyData?: AdminPolicy
    pwdPolicies: PasswordPolicy[]
    otpPolicies: PasswordPolicy[]
    notApplicableUsers: NotApplicableUser[]
    setPolicies: ( policies: PasswordPolicy[] ) => void
    mutatePwdPolicies: ( mutatedPolicies: PasswordPolicy[] ) => void
    mutateOtpPolicies: ( mutatedPolicies: PasswordPolicy[] ) => void
    retrievePolicyData: ( companyId: string ) => Promise<boolean>
    retrieveNotApplicableUsers: ( companyId: string ) => Promise<boolean>
    updatePolicyData: ( policy: AdminPolicy ) => Promise<boolean>
    updateNotApplicableUsers: ( companyId: string, userIds: string[] ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    policyData: undefined,
    pwdPolicies: [],
    otpPolicies: [],
    notApplicableUsers: []
}

/** Definición del Store */
const Store: StateCreator<AdminOtpPoliciesState> = ( set, get ) => ({

    ...initialState,

    setPolicies: ( policies: PasswordPolicy[] ) => {
        const newPolicyData = get().policyData

        if ( newPolicyData ) {
            newPolicyData.passwordPolicyList = policies

            set({ policyData: newPolicyData })
        }
    },

    mutatePwdPolicies: ( mutatedPolicies: PasswordPolicy[] ) => {
        const newPolicies = mutatedPolicies.concat( get().otpPolicies )

        get().setPolicies( newPolicies )
        set({ pwdPolicies: mutatedPolicies })
    },

    mutateOtpPolicies: ( mutatedPolicies: PasswordPolicy[] ) => {
        const newPolicies = get().pwdPolicies.concat( mutatedPolicies )

        get().setPolicies( newPolicies )
        set({ otpPolicies: mutatedPolicies })
    },

    retrievePolicyData: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            if ( !get().policyData ) {
                configService.getPoliciesByCompany( companyId ).then(
                    response => {
                        if ( response.codigo === 0 ) {
                            const data: AdminPolicy = response.respuesta
                            const newPwdPolicies = data.passwordPolicyList.filter(
                                item => !item.policyType.includes( 'OTP' )
                            )
                            const newOtpPolicies = data.passwordPolicyList.filter(
                                item => item.policyType.includes( 'OTP' )
                            )
    
                            set({ policyData: data })
                            set({ pwdPolicies: newPwdPolicies })
                            set({ otpPolicies: newOtpPolicies })
    
                            resolve( true )
                        } else {
                            get().reset()
                            resolve( false )
                        }
                    }
                ).catch(
                    () => {
                        get().reset()
                        reject()
                    }
                )
            }
        })
    },

    retrieveNotApplicableUsers: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().notApplicableUsers.length === 0 ) {
                configService.getNotApplicableUsers( companyId ).then(
                    response => {
                        if ( response.codigo === 0 ) {
                            const data: NotApplicableUser[] = response.respuesta
    
                            set({ notApplicableUsers: data })
    
                            resolve( true )
                        }
                    }
                ).catch(
                    () => reject()
                )
            }
        })
    },

    updatePolicyData: ( policy: AdminPolicy ) => {
        return new Promise(( resolve, reject ) => {
            configService.updatePolicy( policy ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newPolicyData: AdminPolicy = response.respuesta

                        set({ policyData: newPolicyData })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch(
                () => reject()
            )
        })
    },

    updateNotApplicableUsers: ( companyId: string, userIds: string[] ) => {
        return new Promise(( resolve, reject ) => {
            configService.updateNotApplicableUsers( companyId, userIds ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        set({ notApplicableUsers: [] })
                        get().retrieveNotApplicableUsers( companyId )
                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch(
                () => reject()
            )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminOtpPoliciesStore = create<AdminOtpPoliciesState>()(
    immer( Store )
)