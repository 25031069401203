import ExcelTable from "@/core/components/organism/ExcelTable"
import FlowFiltersSections from "./FlowFiltersSections"
import { useState } from "react";

interface TVProps {
  tvType: string
} 

function TvaPage({tvType}: TVProps) {
  const [selectedVersion, setSelectedVersion] = useState<string>('');
  return (
    <>
      <FlowFiltersSections tvType={tvType} selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion} />
      {selectedVersion && <ExcelTable />}
    </>
  )
}

export default TvaPage