import { FcSettings } from "react-icons/fc"
import { useEffect, useRef, useState } from "react"
import { Input, Switch } from "@chakra-ui/react"
import { TbCircleCheck } from "react-icons/tb"
import { TbCircleX } from "react-icons/tb"
import { TbEdit } from "react-icons/tb"
import { usePlanningStore } from "../store/planning.store"
import CustomModal from "@/core/components/organism/CustomModal"
import { SubSectionList } from "@/core/models/planning.model"

const SubSectionsModal: React.FC = () => {
  /** */
  const subSectionItems = usePlanningStore(e => e.selectedPlanning?.subSectionList)
  const { selectedPlanning, updatePlanningSubSectionList, getSubSectionItemBy } = usePlanningStore()

  const handleSave = (key: string, newDisplayName: string) => {
    const item = getSubSectionItemBy(key)

    if (item) {
      item.name = newDisplayName
      updatePlanningSubSectionList(selectedPlanning?.id!, item!)
    }
  }

  const handleToggleEnable = (key: string) => {
    const item = getSubSectionItemBy(key)

    if (item) {
      item.enable = !item.enable
      updatePlanningSubSectionList(selectedPlanning?.id!, item!)
    }
  }

  return (
    <CustomModal id="SubSectionsModal"
      title="Gestionar sub secciones"
      size="xl"
      icon={FcSettings}
      withButtons={false}
    >
      {() => (
        <>
          {subSectionItems?.map(item => (
            <EditableRow
              key={item.name}
              item={item}
              onSave={handleSave}
              onToggleEnable={handleToggleEnable}
            />
          ))}
        </>
      )}
    </CustomModal>
  )
}

interface EditableRowProps {
  item: SubSectionList
  onSave: (key: string, displayName: string) => void
  onToggleEnable: (key: string) => void
}

const EditableRow: React.FC<EditableRowProps> = ({ item, onSave, onToggleEnable }) => {
  /** */
  const [isEditing, setIsEditing] = useState(false)
  const [displayName, setDisplayName] = useState(item.name)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      (inputRef.current as any).focus()
    }
  }, [isEditing])

  const handleSave = () => {
    onSave(item.type, displayName)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setDisplayName(item.name)
    setIsEditing(false)
  }

  return (
    <div className="flex items-center justify-between p-3 my-3 rounded-md bg-gray-100">
      {isEditing ? (
        <Input ref={inputRef}
          className="mr-4"
          size="sm"
          bgColor="transparent"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      ) : (
        <span>
          {item.name}
        </span>
      )}
      <div className="flex items-center gap-4">
        {isEditing ? (
          <>
            <TbCircleCheck size={24} className="text-green-500 cursor-pointer" onClick={handleSave} />
            <TbCircleX size={24} className="text-red-500 cursor-pointer" onClick={handleCancel} />
          </>
        ) : (
          <TbEdit size={24} className="text-gray-600 cursor-pointer" onClick={() => setIsEditing(true)} />
        )}
        <Switch
          isChecked={item.enable}
          onChange={() => onToggleEnable(item.type)}
        />
      </div>
    </div>
  )
}

export default SubSectionsModal