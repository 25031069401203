import { Apis } from "@/apis"
import { NoAuth } from "@/core/constants/config-header"
import HttpClient from "@/core/http/http-client"
import { AuthResponse } from "@/modules/auth/models/auth-response"
import { SignInRequest } from "@/modules/auth/models/signin-request"

export class AuthService {
    /** */
    private http = new HttpClient( `${Apis.BASE}/api/general/auth` )

    public authenticate( request: SignInRequest ): Promise<AuthResponse> {
        return this.http.post<AuthResponse>( `/login`, request, NoAuth )
    }

    public updateSession( token: string ): Promise<AuthResponse> {
        return this.http.post<AuthResponse>( `/refreshToken`, { token }, NoAuth )
    }

}