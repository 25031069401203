import { StateCreator, create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { NavigationItem } from "@/core/components/molecules/Navigation"
import { persist } from "zustand/middleware"
import { SectionService } from "@/core/services/section.service"
import { SecureSessionStorage } from "./secure-sessionstorage.store"

/** Services */
const sectionService = new SectionService()

/** Contrato del Store */
interface NavigationState {
    navigationItems: NavigationItem[]
    selectedNavigation?: NavigationItem
    retrieveNavigationItems: ( areaId: string ) => Promise<boolean>
    addNavigationItem: ( item: NavigationItem ) => Promise<boolean>
    updateNavigationItem: ( updatedItem: NavigationItem ) => Promise<boolean>
    initializeSections: ( areaId: string ) => Promise<boolean>
    getNavigationName: ( key: string ) => string | undefined
    getNavigationItemBy: ( key: string ) => NavigationItem | undefined
    selectNavigation: ( item: NavigationItem ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    navigationItems: [],
    selectedNavigation: undefined
}

/** Definición del Store */
const Store: StateCreator<NavigationState> = ( set, get ) => ({

    ...initialState,

    retrieveNavigationItems: ( areaId: string ) => {
        return new Promise( ( resolve, reject ) => {
            sectionService.getSectionsByArea( areaId ).then(
                response => {
                    const data: NavigationItem[] = response.respuesta
    
                    data.map( item => item.route = item.sectionType.toUpperCase() )
    
                    set({ navigationItems: data })

                    resolve( true )
                }
            ).catch( () => {
                get().reset()
                reject()
            })
        })
    },

    addNavigationItem: ( item: NavigationItem ) => {
        return new Promise( ( resolve, reject ) => {
            sectionService.createSection( item ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newItem = response.respuesta
                        const items = get().navigationItems

                        items.push( newItem )

                        set({ navigationItems: items })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    updateNavigationItem: ( updatedItem ) => {
        return new Promise( ( resolve, reject ) => {
            sectionService.updateSection( updatedItem ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const updatedItem: NavigationItem = response.respuesta
                        const items = get().navigationItems

                        items.map( item => {
                            if ( updatedItem.id === item.id ) {
                                item = updatedItem
                            }

                            return item
                        })

                        set({ navigationItems: items })

                        resolve( true )
                    } else {                        
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    initializeSections: ( areaId: string ) => {
        return new Promise( ( resolve, reject ) => {
            sectionService.initSectionsByArea( areaId ).then(
                response => {
                    const data: NavigationItem[] = response.respuesta
    
                    data.map( item => item.route = item.sectionType.toUpperCase() )
    
                    set({ navigationItems: data })

                    resolve( true )
                }
            ).catch( () => {
                get().reset()
                reject()
            })
        })
    },

    getNavigationName: ( key: string ) => get().navigationItems.find(
        item => item.sectionType === key
    )?.displayName,

    getNavigationItemBy: ( key: string ) => get().navigationItems.find(
        item => item.sectionType === key
    ),

    selectNavigation: ( item: NavigationItem ) => set({ selectedNavigation: item }),

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useNavigationStore = create<NavigationState>()(
    persist(
        immer( Store ),
        {
            name: 'navigation-store',
            storage: SecureSessionStorage
        }
    )
)