import { Fragment, useMemo, useState } from "react"
import BrandPagination from "../molecules/BrandPagination"
import { Box, Select, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import React from "react"
import { TbArrowsDownUp, TbArrowNarrowUp, TbArrowNarrowDown } from "react-icons/tb"

type PositionRow = 'start' | 'center' | 'end'

export interface BrandTableColumn {
    header: string
    accessor: string
    sortable?: boolean
    positionRow?: PositionRow
    customRow?: (( row: any ) => JSX.Element)[]
}

type SortConfig = {
    key: string
    direction: string
}

interface BrandTableProps {
    columns: BrandTableColumn[]
    data: any[]
    showPagination?: boolean
    rowsPerPageOptions?: number[]
    defaultRowsPerPage?: number
    pagesPerView?: number
}

const BrandTable: React.FC<BrandTableProps> = ({
        columns = [],
        data = [],
        showPagination = true,
        rowsPerPageOptions = [5, 10, 20, 100],
        defaultRowsPerPage = 5,
        pagesPerView = 10
    }) => {
    /** */
    const [ currentPage, setCurrentPage ] = useState( 1 )
    const [ rowsPerPage, setRowsPerPage ] = useState( defaultRowsPerPage || rowsPerPageOptions[0] )
    const [ sortConfig, setSortConfig ] = useState<SortConfig | null >( null )

    const sortedData = useMemo( () => {
        /** */
        let sortableItems = [ ...data ]
        
        if ( sortConfig !== null ) {
            sortableItems.sort(( a, b ) => {
                if ( a[sortConfig['key']] < b[sortConfig['key']] ) {
                    return sortConfig['direction'] === 'ascending' ? -1 : 1
                }

                if ( a[sortConfig['key']] > b[sortConfig['key']] ) {
                    return sortConfig['direction'] === 'ascending' ? 1 : -1
                }

                return 0
            })
        }

        return sortableItems
    }, [ data, sortConfig ])

    const paginatedData = useMemo( () => {
        /** */
        if ( showPagination ) {
            const startIndex = ( currentPage - 1 ) * rowsPerPage
            return sortedData.slice( startIndex, startIndex + rowsPerPage )
        }

        return sortedData
    }, [ sortedData, currentPage, rowsPerPage, showPagination ])

    const requestSort = ( key: string ) => {
        let direction = 'ascending'

        if ( sortConfig
            && sortConfig['key'] === key
            && sortConfig['direction'] === 'ascending'
        ) {
            direction = 'descending'
        }

        setSortConfig({ key, direction })
    }

    const getSortIcon = ( column: BrandTableColumn ) => {
        if ( !column.sortable ) return null

        if ( !sortConfig || sortConfig.key !== column.accessor ) {
            return <TbArrowsDownUp size={14} />
        }

        return sortConfig.direction === 'ascending'
            ? <TbArrowNarrowUp size={14} />
            : <TbArrowNarrowDown size={14} />
    }

    const getPositionRow = ( positionRow?: PositionRow ) => {
        if ( !positionRow ) return 'start'

        return positionRow
    }

    const handlePageChange = ( page: number ) => {
        setCurrentPage( page )
    }

    const handleRowsPerPageChange = ( event: any ) => {
        setRowsPerPage( Number( event.target.value ) )
        setCurrentPage( 1 )
    }

    return (
        <>
            <Box borderRadius="md" overflow="hidden">
                <Box maxHeight="calc(100vh - 26.8rem)" overflow="auto">
                    <Table variant="simple">
                        <Thead
                            position="sticky"
                            top={0}
                            zIndex={1}
                        >
                            <Tr>
                                {columns.map(( column ) => (
                                    <Th key={column.accessor}
                                        bg="#F6F7FA"
                                        color="gray.600"
                                        onClick={() => column.sortable && requestSort( column.accessor )}
                                        cursor={column.sortable ? 'pointer' : 'default'}
                                    >
                                        <div className={`flex items-center justify-${getPositionRow( column.positionRow )} gap-2`}>
                                            <span>{column.header}</span>
                                            {column.sortable && (
                                                <>{getSortIcon( column )}</>
                                            )}
                                        </div>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody bg="white">
                            {paginatedData.map(( row, rowIndex ) => (
                                <Tr key={rowIndex}>
                                    {columns.map( column => (
                                        <Td key={column.accessor}>
                                            <div className={`flex items-center justify-${getPositionRow( column.positionRow )} gap-4`}>
                                                {column.customRow ? (
                                                    <>
                                                        {column.customRow.map(( custom, index ) => (
                                                            <Fragment key={index}>
                                                                {custom( row )}
                                                            </Fragment>
                                                        ))}
                                                    </>
                                                ) : (
                                                    row[column.accessor]
                                                )}
                                            </div>
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
            {showPagination && (
                <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                    <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        width="auto"
                    >
                        {rowsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                            {option} registros por página
                            </option>
                        ))}
                    </Select>
                    <BrandPagination
                        totalRows={data.length}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        pagesPerView={pagesPerView}
                    />
                </Box>
            )}
        </>
    )
}

export default BrandTable