import FlowTabs from "@/modules/campaigns/components/FlowTabs";
import { Outlet } from "react-router-dom";

const FlowSectionLayout: React.FC = () => {
  /** */
  return (
    <>
      <section className="">
        <FlowTabs />
        <Outlet />
      </section>
    </>
  )
}

export default FlowSectionLayout;