import { useAuthStore } from "@/modules/auth/stores/auth.store"
import { useUserStore } from "@/modules/auth/stores/user.store"
import { useIntervalStore } from "@/core/stores/interval.store"
import { useLoaderStore } from "@/core/stores/loader.store"
import { useModalStore } from "@/core/stores/modal.store"
import { useTimerStore } from "@/core/stores/timer.store"
import { useNavigationStore } from "@/core/stores/navigation.store"
import { useNotificationStore } from "@/core/stores/notification.store"
import { useAreaStore } from "@/core/stores/area.store"
import { usePlanningStore } from "../../modules/planning/store/planning.store"
import { useAdminUserStore } from "@/modules/admin/stores/admin-user.store"
import { useExcelTableStore } from "@/core/stores/excel-table.store"
import { useBudgetStore } from "@/modules/planning/store/budget-card.store"
import { useAdminUserGroupStore } from "@/modules/admin/stores/admin-user-group.store"
import { useAdminCompanyStore } from "@/modules/admin/stores/admin-company.store"
import { useAdminRoleStore } from "@/modules/admin/stores/admin-role.store"
import { useCampaignStore } from "@/modules/campaigns/store/campaign.store"
import { useComponentsStore } from "../stores/components.store"
import { useConciliationStore } from "@/modules/conciliation/stores/conciliation.store"

const CleanStore = () => {
    useAuthStore.getState().reset()
    useUserStore.getState().reset()
    useIntervalStore.getState().reset()
    useLoaderStore.getState().reset()
    useModalStore.getState().reset()
    useTimerStore.getState().reset()
    useNotificationStore.getState().reset()
    useNavigationStore.getState().reset()
    useAreaStore.getState().reset()
    usePlanningStore.getState().reset()
    useAdminUserStore.getState().reset()
    useExcelTableStore.getState().reset()
    useBudgetStore.getState().reset()
    useAdminUserGroupStore.getState().reset()
    useAdminCompanyStore.getState().reset()
    useAdminRoleStore.getState().reset()
    useCampaignStore.getState().reset()
    useComponentsStore.getState().reset()
    useConciliationStore.getState().reset()
}

export default CleanStore