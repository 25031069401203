import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { NotificationItem } from "@/core/components/organism/Notifications";
import { NotificationService } from "@/core/services/notification.service";
import { App } from "@/core/enums/app.enum";
import { useUserStore } from "@/modules/auth/stores/user.store";
import { useIntervalStore } from "./interval.store";
import { NotificationRequest } from "../models/notification-request";

const notificationService = new NotificationService()

/** Contrato del Store */
interface NotificationState {
    notifications: NotificationItem[]
    size: number
    setNotifications: ( data: NotificationItem[] ) => void
    startNotificationFlow: () => void
    retrieveNotifications: ( body: NotificationRequest ) => Promise<boolean>
    retrieveNotificationsSize: ( userId: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    notifications: [],
    size: 0
}

/** Definición del Store */
const Store: StateCreator<NotificationState> = ( set, get ) => ({

    ...initialState,

    setNotifications: ( data: NotificationItem[] ) => set({
        notifications: data,
        size: data.length
    }),

    startNotificationFlow: () => {
        /** */
        const body = {
            idApp: App.ID,
            idUser: useUserStore.getState().userData?.id as string
        }

        get().retrieveNotifications( body )

        const notificationsInterval = setInterval( () => {
            get().retrieveNotificationsSize( body.idUser ).then(
                () => {
                    if ( get().notifications.length !== get().size ) {
                        get().retrieveNotifications( body )
                    }
                }
            )
        }, 60000 )

        /** Limpiar interval antes de agregar al estado global */
        useIntervalStore.getState().clearInterval( 'notificationsInterval' )
        /** Agregar interval al estado global */
        useIntervalStore.getState().addInterval(
            'notificationsInterval', notificationsInterval
        )
    },

    retrieveNotifications: ( body: NotificationRequest ) => {
        return new Promise(( resolve, reject ) => {
            notificationService.getNotifications( body ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const data: NotificationItem[] = response.respuesta

                        set({ notifications: data })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch(
                error => console.error( error )
            )
        })
    },

    retrieveNotificationsSize: ( userId: string ) => {
        return new Promise(( resolve, reject ) => {
            notificationService.getSizeNotifications( userId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const sizeResponse: number = response.respuesta

                        set({ size: sizeResponse })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch(
                error => console.error( error )
            )
        })
    },

    reset: () => {
        set( initialState )
        useIntervalStore.getState().clearInterval(
            'notificationsInterval'
        )
    }

})

/** Exportación del Store */
export const useNotificationStore = create<NotificationState>()(
    immer( Store )
)