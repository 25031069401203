import { useNavigationStore } from "@/core/stores/navigation.store"
import { memo } from "react"
import { NavLink } from "react-router-dom"
import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList, Tooltip } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Sections } from "@/core/enums/section.enum"
import { TbSettings2 } from "react-icons/tb"
import { useAreaStore } from "@/core/stores/area.store"
import { DataUtil } from "@/core/utils/data.util"
import useMountedEffect from "@/core/hooks/useMountedEffect"
import useCustomToast from "@/core/hooks/useCustomToast"
import SectionsModal from "../organism/SectionsModal"
import { useModalStore } from "@/core/stores/modal.store"

export interface NavigationItem {
    id: string
    areaId: string
    enable: boolean
    sectionType: Sections
    displayName: string
    route: string
}

const Navigation: React.FC = () => {
    /** */
    const navigationItems = useNavigationStore( s => s.navigationItems )
    const { retrieveNavigationItems, initializeSections, selectNavigation } = useNavigationStore()
    const selectedArea = useAreaStore( s => s.selectedArea )
    const toast = useCustomToast()
    const openModal = useModalStore( s => s.openModal )

    useMountedEffect( () => {
        /** */
        retrieveNavigationItems( selectedArea?.id! ).then(
            () => {
                if ( useNavigationStore.getState().navigationItems.length === 0 ) {
                    initializeSections( selectedArea?.id! ).catch( () => {
                        toast.error( 'No fue posible inicializar las secciones' )
                    })
                }
            }
        ).catch( () => {
            toast.error( 'No se pudieron recuperar las secciones' )
        })
    })

    return (
        <>
            {navigationItems?.length > 0 && (
                <nav className="lg:flex items-center gap-6 ml-10 hidden">
                    {navigationItems.map( ( nav: NavigationItem, index: number ) => (
                        nav.enable && (
                            <NavLink key={index} to={`/views/${nav.route.toLowerCase()}`}
                                className={({ isActive }) => (isActive ? "text-brand-500" : "")}
                                onClick={() => selectNavigation( nav )}>
                                {DataUtil.capitalizeText( nav.displayName )}
                            </NavLink>
                        )
                    ))}
                </nav>
            )}
            <div className="flex items-center ml-4 -mt-1 cursor-pointer lg:hidden">
                <Menu>
                    <MenuButton
                        as={IconButton}
                        size="sm"
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                        variant="outline"
                    />
                    <MenuList>
                        {navigationItems.map( ( nav: NavigationItem, index: number ) => (
                            nav.enable && (
                                <MenuItem key={nav.id}>
                                    <NavLink key={index} to={`/views/${nav.route.toLowerCase()}`}
                                        className={({ isActive }) => (isActive ? "text-brand-500" : "")}
                                        onClick={() => selectNavigation( nav )}>
                                        {DataUtil.capitalizeText( nav.displayName )}
                                    </NavLink>
                                </MenuItem>
                            )
                        ))}
                    </MenuList>
                </Menu>
            </div>
            {/* {navigationItems.length === 0 && ( */}
                <Tooltip label="Gestionar secciones">
                    <Button className="ml-4"
                        as={IconButton}
                        icon={<TbSettings2 size={20} />}
                        variant="ghost"
                        size="sm"
                        onClick={() => openModal( 'sectionsModal' )}
                    />
                </Tooltip>
                {/* Modal */}
                <SectionsModal />
            {/* )} */}
        </>
    )
}

export default memo( Navigation )