import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { UserGroup } from "../models/user-group.model";
import { UserGroupService } from "../services/user-group.service";
import { UserGroupRequest } from "../models/user-group-request.model";

/** Services */
const userGroupService = new UserGroupService()
/** Contrato del Store */
interface AdminUserGroupState {
    userGroups: UserGroup[]
    selectedUserGroup?: UserGroup
    selectUserGroup: ( userGroup: UserGroup ) => void
    deselectedUserGroup: () => void
    retrieveUserGroups: ( companyId: string ) => Promise<boolean>
    addUserGroup: ( userGroup: UserGroupRequest ) => Promise<boolean>
    updateUserGroup: ( userGroup: UserGroupRequest ) => Promise<boolean>
    removeUserGroup: ( userGroupId: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    userGroups: [],
    selectedUserGroup: undefined
}

/** Definición del Store */
const Store: StateCreator<AdminUserGroupState> = ( set, get ) => ({

    ...initialState,

    selectUserGroup: ( userGroup: UserGroup ) => set({ selectedUserGroup: userGroup }),

    deselectedUserGroup: () => set({ selectedUserGroup: undefined }),

    retrieveUserGroups: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().userGroups.length === 0 ) {
                userGroupService.getUserGroupsByCompany( companyId ).then(
                    response => {
                        const data: UserGroup[] = response.respuesta

                        set({ userGroups: data })

                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            }
        })
    },

    addUserGroup: ( userGroup: UserGroupRequest ) => {
        return new Promise(( resolve, reject ) => {
            userGroupService.createUserGroup( userGroup ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newUserGroup: UserGroup = response.respuesta
                        const userGroups = get().userGroups

                        set({ userGroups: [ ...userGroups, newUserGroup ] })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    updateUserGroup: ( userGroup: UserGroupRequest ) => {
        return new Promise(( resolve, reject ) => {
            userGroupService.updateUserGroup( userGroup ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const updated: UserGroup = response.respuesta
                        const updatedUserGroups = get().userGroups.map(
                            item => item.id === updated.id
                                ? { ...item, ...updated }
                                : item
                        )

                        set({ userGroups: updatedUserGroups })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    removeUserGroup: ( userGroupId: string ) => {
        return new Promise(( resolve, reject ) => {
            userGroupService.deleteUserGroup( userGroupId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const filteredUserGroups = get().userGroups.filter(
                            item => item.id !== userGroupId
                        )

                        set({ userGroups: filteredUserGroups })

                        resolve( true )
                    }
                }
            ).catch( () => reject() )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminUserGroupStore = create<AdminUserGroupState>()(
    immer( Store )
)