import BrandBox from '@/core/components/molecules/BrandBox'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ConciliationLetter, RowConciliation } from '../models/conciliation.model'
import { useConciliationStore } from '../stores/conciliation.store'
import { useEffect } from 'react'
import { useComponentsStore } from '@/core/stores/components.store'
import DocumentViewTemplate from './DocumentViewTemplate'

interface OrderProps {
  conciliation: ConciliationLetter
}

function ConciliationLetterPage() {
  const { uiConciliationComponents } = useComponentsStore();
  const { setSelectedLetterProvider, conciliationLetter, setSelectedFile } = useConciliationStore();
  const navigate = useNavigate();

  const handleClick = (conciliation: ConciliationLetter) => {
    setSelectedLetterProvider(conciliation.provider)
    setSelectedFile(conciliation.versions[0])
    navigate('/views/reconciliations/conciliation-letter')
  }

  useEffect(() => {
    if (uiConciliationComponents.seeConciliationLetters) {
      //setSelectedLetterProvider('')
    } else {
      setSelectedLetterProvider('Facebook')
    }
  }, [])

  const RowHover = ({ conciliation }: OrderProps) => {

    return <Tr className='group hover:bg-blue-100'>
      <Td>
        <Flex columnGap={6}>
          <Text fontWeight={'bold'}>
            {conciliation.tvType}
          </Text>
          <Text>
            {conciliation.provider}
          </Text>
        </Flex>
      </Td>
      <Td>{conciliation.versions[0].name}</Td>
      <Td isNumeric>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant='ghost'
          className='opacity-0 group-hover:opacity-100 transition-opacity'
          onClick={() => handleClick(conciliation)}
        >Ver carta</Button>
      </Td>
    </Tr>
  }
  return (
    uiConciliationComponents.seeConciliationLetters ?
      <BrandBox>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th fontWeight='bold' color='black' fontSize='sm'>Proveedores</Th>
                <Th fontWeight='bold' color='black' fontSize='sm'>Nombre del archivo</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                conciliationLetter.map((conciliation, index) => {
                  return <RowHover key={index} conciliation={conciliation} />
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </BrandBox> :
      <DocumentViewTemplate title='Carta' />
  )
}

export default ConciliationLetterPage