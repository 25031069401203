import { Box, Img, Text } from '@chakra-ui/react'

interface  EmptyDataMessageProps{
  text: string;
  children?: React.ReactNode;
  imageSrc?: string
}

const EmptyDataMessage: React.FC<EmptyDataMessageProps> = ({text, children, imageSrc}) => {
  return (
    <Box height={'50vh'} display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={3}>
      <Img src={imageSrc ?? "/general/document-device.png"} />
      <Text fontSize={'2xl'} textAlign={'center'}>{text}</Text>
      {children}
    </Box>
  )
}

export default EmptyDataMessage