import { DataUtil } from "@/core/utils/data.util"
import { TbBriefcase2 } from 'react-icons/tb'

interface CompanyProps {
    name?: string
}

const Company: React.FC<CompanyProps> = ({ name }) => {
    /** */
    return (
        <>
            {name && (
                <div className="md:flex items-center gap-1 hidden">
                    <TbBriefcase2 />
                    <span>{DataUtil.capitalizeText( name )}</span>
                </div>
            )}
        </>
    )
}

export default Company