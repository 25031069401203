import { Apis } from "@/apis"
import HttpClient from "@/core/http/http-client"
import { Company } from "../models/company"
import { GenericApiResponse } from "@/core/models/generic-api-response"
import { CompanyResponse } from "../models/company-response.model"
import { CompanyRequest } from "../models/company-request.model"
import { AssociateCompanyRequest } from "../models/associate-company-request.model"
import { UpdateCompany } from "../models/update-company.model"

export class CompanyService {
    /** */
    private httpBase = new HttpClient( `${Apis.BASE}/api/general/company` )
    private http = new HttpClient( `${Apis.COMPANY}/api/v1/companies` )

    public getAllCompanies( page: number = 0, size: number = 100000 ): Promise<CompanyResponse> {
        return this.http.get<CompanyResponse>( `?page=${page}&size=${size}` )
    }

    public getOwnerCompanies( ownerCompanyId: string ): Promise<GenericApiResponse> {
        return this.httpBase.get<GenericApiResponse>( `/associate/ownCompanyId/${ownerCompanyId}` )
    }

    public getCompanyData(): Promise<{ companies: Company[] }> {
        return this.http.get<{ companies: Company[] }>( `/owner` )
    }

    public createCompany( body: UpdateCompany ): Promise<any> {
        return this.http.post( '', body )
    }

    public createAndAssociateCompany( body: CompanyRequest ): Promise<Company> {
        return this.httpBase.put<Company>( '/createAndAssociateCompany', body )
    }

    public updateCompany( body: UpdateCompany ): Promise<{code: number, message: string, status: boolean}> {
        return this.http.patch( '', body )
    }

    public deleteCompanyAssociation( companyId: string ): Promise<GenericApiResponse> {
        return this.httpBase.delete<GenericApiResponse>( `/associate/${companyId}` )
    }

    public associateToCompany( body: AssociateCompanyRequest ): Promise<GenericApiResponse> {
        return this.httpBase.put<GenericApiResponse>( '/associate', body )
    }

}