import { Box, Button, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { RxDotsVertical } from 'react-icons/rx'

interface CommentsProps {
  autor?: string;
  text?: string;
  date?: string;
}
interface CommentBodyProps {
  item: CommentsProps
}

function CommentBody({ item }: CommentBodyProps) {
  return (
    <Box borderBottom={'2px solid'} pb={2} display={'flex'} flexWrap={'wrap'} flexDirection={'column'} gap={1}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='sm' fontWeight={'bold'}>{item.autor}</Text>
        <Tooltip label='Opciones'>
          <Button
            as={IconButton}
            icon={<RxDotsVertical color='gray.600' size={20} />}
            variant="ghost"
            size="md"
            margin={'0'}
            padding={1}
            borderRadius='full'
            onClick={(e) => console.log('open')}
          />
        </Tooltip>
      </Flex>
      <Text fontSize='md' color="black">
        {item.text}
      </Text>
      <Text fontSize={'sm'} color={'gray.400'}>
        {item.date}
      </Text>
    </Box>
  )
}

export default CommentBody