export const Services: { id: string, name: string }[] = [
    { id: "64b4323d73919b40e8d7c01b", name: "Agricultura" },
    { id: "64b4323d73919b40e8d7c01c", name: "Cría y explotación de animales" },
    { id: "64b4323d73919b40e8d7c01d", name: "Aprovechamiento forestal" },
    { id: "64b4323d73919b40e8d7c01e", name: "Servicios relacionados con las actividades agropecuarias y forestales" },
    { id: "64b4323d73919b40e8d7c01f", name: "Pesca, caza y captura" },
    { id: "64b4323d73919b40e8d7c020", name: "Extracción de petróleo y gas" },
    { id: "64b4323d73919b40e8d7c021", name: "Minería de minerales metálicos y no metálicos, excepto petróleo y gas" },
    { id: "64b4323d73919b40e8d7c022", name: "Servicios relacionados con la minería" },
    { id: "64b4323d73919b40e8d7c023", name: "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final" },
    { id: "64b4323d73919b40e8d7c024", name: "Edificación" },
    { id: "64b4323d73919b40e8d7c025", name: "Construcción de obras de ingeniería civil" },
    { id: "64b4323d73919b40e8d7c026", name: "Trabajos especializados para la construcción" },
    { id: "64b4323d73919b40e8d7c027", name: "Industria alimentaria" },
    { id: "64b4323d73919b40e8d7c028", name: "Industria de las bebidas y del tabaco" },
    { id: "64b4323d73919b40e8d7c029", name: "Fabricación de insumos textiles y acabado de textiles" },
    { id: "64b4323d73919b40e8d7c02a", name: "Fabricación de productos textiles, excepto prendas de vestir" },
    { id: "64b4323d73919b40e8d7c02b", name: "Fabricación de prendas de vestir" },
    { id: "64b4323d73919b40e8d7c02c", name: "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos" },
    { id: "64b4323d73919b40e8d7c02d", name: "Industria de la madera" },
    { id: "64b4323d73919b40e8d7c02e", name: "Industria del papel" },
    { id: "64b4323d73919b40e8d7c02f", name: "Impresión e industrias conexas" },
    { id: "64b4323d73919b40e8d7c030", name: "Fabricación de productos derivados del petróleo y del carbón" },
    { id: "64b4323d73919b40e8d7c031", name: "Industria química" },
    { id: "64b4323d73919b40e8d7c032", name: "Industria del plástico y del hule" },
    { id: "64b4323d73919b40e8d7c033", name: "Fabricación de productos a base de minerales no metálicos" },
    { id: "64b4323d73919b40e8d7c034", name: "Industrias metálicas básicas" },
    { id: "64b4323d73919b40e8d7c035", name: "Fabricación de productos metálicos" },
    { id: "64b4323d73919b40e8d7c036", name: "Fabricación de maquinaria y equipo" },
    { id: "64b4323d73919b40e8d7c037", name: "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos" },
    { id: "64b4323d73919b40e8d7c038", name: "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica" },
    { id: "64b4323d73919b40e8d7c039", name: "Fabricación de equipo de transporte" },
    { id: "64b4323d73919b40e8d7c03a", name: "Fabricación de muebles, colchones y persianas" },
    { id: "64b4323d73919b40e8d7c03b", name: "Otras industrias manufactureras" },
    { id: "64b4323d73919b40e8d7c03c", name: "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco" },
    { id: "64b4323d73919b40e8d7c03d", name: "Comercio al por mayor de productos textiles y calzado" },
    { id: "64b4323d73919b40e8d7c03e", name: "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca" },
    { id: "64b4323d73919b40e8d7c03f", name: "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho" },
    { id: "64b4323d73919b40e8d7c040", name: "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general" },
    { id: "64b4323d73919b40e8d7c041", name: "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones" },
    { id: "64b4323d73919b40e8d7c042", name: "Intermediación de comercio al por mayor" },
    { id: "64b4323d73919b40e8d7c043", name: "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco" },
    { id: "64b4323d73919b40e8d7c044", name: "Comercio al por menor de artículos de ferretería, tlapalería y vidrios" },
    { id: "64b4323d73919b40e8d7c045", name: "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal" },
    { id: "64b4323d73919b40e8d7c046", name: "Comercio al por menor de artículos para el cuidado de la salud" },
    { id: "64b4323d73919b40e8d7c047", name: "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados" },
    { id: "64b4323d73919b40e8d7c048", name: "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado" },
    { id: "64b4323d73919b40e8d7c049", name: "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes" },
    { id: "64b4323d73919b40e8d7c04a", name: "Comercio al por menor en tiendas de autoservicio y departamentales" },
    { id: "64b4323d73919b40e8d7c04b", name: "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares" },
    { id: "64b4323d73919b40e8d7c04c", name: "Transporte aéreo" },
    { id: "64b4323d73919b40e8d7c04d", name: "Transporte por ferrocarril" },
    { id: "64b4323d73919b40e8d7c04e", name: "Transporte por agua" },
    { id: "64b4323d73919b40e8d7c04f", name: "Autotransporte de carga" },
    { id: "64b4323d73919b40e8d7c050", name: "Transporte terrestre de pasajeros, excepto por ferrocarril" },
    { id: "64b4323d73919b40e8d7c051", name: "Transporte por ductos" },
    { id: "64b4323d73919b40e8d7c052", name: "Transporte turísticas" },
    { id: "64b4323d73919b40e8d7c053", name: "Servicios relacionados con el transporte" },
    { id: "64b4323d73919b40e8d7c054", name: "Servicios postales" },
    { id: "64b4323d73919b40e8d7c055", name: "Servicios de mensajería y paquetería" },
    { id: "64b4323d73919b40e8d7c056", name: "Servicios de almacenamiento" },
    { id: "64b4323d73919b40e8d7c057", name: "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión" },
    { id: "64b4323d73919b40e8d7c058", name: "Industria fílmica y del video, e industria del sonido" },
    { id: "64b4323d73919b40e8d7c059", name: "Radio y televisión" },
    { id: "64b4323d73919b40e8d7c05a", name: "Telecomunicaciones" },
    { id: "64b4323d73919b40e8d7c05b", name: "Procesamiento electrónico de información, hospedaje y otros servicios relacionados" },
    { id: "64b4323d73919b40e8d7c05c", name: "Otros servicios de información" },
    { id: "64b4323d73919b40e8d7c05d", name: "Banca central" },
    { id: "64b4323d73919b40e8d7c05e", name: "Actividades bursátiles, cambiarias y de inversión financiera" },
    { id: "64b4323d73919b40e8d7c05f", name: "Compañías de seguros, fianzas, y administración de fondos para el retiro" },
    { id: "64b4323d73919b40e8d7c060", name: "Instituciones de intermediación crediticia y financiera no bursátil" },
    { id: "64b4323d73919b40e8d7c061", name: "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión" },
    { id: "64b4323d73919b40e8d7c062", name: "Servicios inmobiliarios" },
    { id: "64b4323d73919b40e8d7c063", name: "Servicios de alquiler de bienes muebles" },
    { id: "64b4323d73919b40e8d7c064", name: "Servicios de alquiler de marcas registradas, patentes y franquicias" },
    { id: "64b4323d73919b40e8d7c065", name: "Servicios profesionales, científicos y técnicos" },
    { id: "64b4323d73919b40e8d7c066", name: "Corporativos" },
    { id: "64b4323d73919b40e8d7c067", name: "Servicios de apoyo a los negocios" },
    { id: "64b4323d73919b40e8d7c068", name: "Limpieza" },
    { id: "64b4323d73919b40e8d7c069", name: "Servicios de mantenimiento" },
    { id: "64b4323d73919b40e8d7c06a", name: "Protección y Seguridad" },
    { id: "64b4323d73919b40e8d7c06b", name: "Manejo de residuos y servicios de remediación" },
    { id: "64b4323d73919b40e8d7c06c", name: "Servicios educativos" },
    { id: "64b4323d73919b40e8d7c06d", name: "Servicios médicos de consulta externa y servicios relacionados" },
    { id: "64b4323d73919b40e8d7c06e", name: "Hospitales pertenecientes al sector privado" },
    { id: "64b4323d73919b40e8d7c06f", name: "Residencias de asistencia social y para el cuidado de la salud" },
    { id: "64b4323d73919b40e8d7c070", name: "Otros servicios de asistencia social" },
    { id: "64b4323d73919b40e8d7c071", name: "Servicios artísticos, culturales y deportivos, y otros servicios relacionados" },
    { id: "64b4323d73919b40e8d7c072", name: "Museos, sitios históricos, zoológicos y similares" },
    { id: "64b4323d73919b40e8d7c073", name: "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos" },
    { id: "64b4323d73919b40e8d7c074", name: "Servicios de alojamiento temporal" },
    { id: "64b4323d73919b40e8d7c075", name: "Servicios de preparación de alimentos y bebidas" },
    { id: "64b4323d73919b40e8d7c076", name: "Servicios de reparación y mantenimiento" },
    { id: "64b4323d73919b40e8d7c077", name: "Servicios personales" },
    { id: "64b4323d73919b40e8d7c078", name: "Asociaciones y organizaciones" },
    { id: "64b4323d73919b40e8d7c079", name: "Hogares con empleados domésticos" },
    { id: "64b4323d73919b40e8d7c07a", name: "Otro" }
]