import CustomAlertDialog from "./CustomAlertDialog"
import { useIntervalStore } from "@/core/stores/interval.store"
import { useAuthStore } from "@/modules/auth/stores/auth.store"
import { AuthService } from "@/modules/auth/services/auth.service"
import { AuthResponse } from "@/modules/auth/models/auth-response"
import { JwtUtil } from "@/core/utils/jwt.util"
import Timer from "../atoms/Timer"
import { useTimerStore } from "@/core/stores/timer.store"

const SessionExpireNotifier: React.FC = () => {
    /** Services */
    const authService = new AuthService()
    /** */
    const clearInterval = useIntervalStore( s => s.clearInterval )
    const signInUser = useAuthStore( s => s.signInUser )
    const signOutUser = useAuthStore( s => s.signOutUser )
    const checkSessionExpires = useAuthStore( s => s.checkSessionExpires )
    const { stopTimer, removeTimer } = useTimerStore()
    const authData = useAuthStore( s => s.authData )

    const handleSignOut = () => {
        signOutUser()
    }

    const handleUpdateSession = () => {
        authService.updateSession( authData!!.refreshToken ).then(
            ( response: AuthResponse ) => {

                const { exp } = JwtUtil.decodeToken( response.access_token )

                stopTimer( 'SessionExpiresTimer' )
                removeTimer( 'SessionExpiresTimer' )
                clearInterval( 'SessionExpiresTimerInterval' )
                clearInterval( 'sessionInterval' )

                signInUser({
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    expiresInToken: response.expires_in,
                    expiresInRefreshToken: response.refresh_expires_in,
                    exp
                })

                checkSessionExpires()
            }
        ).catch(
            () => handleSignOut()
        )
    }

    return (
        <CustomAlertDialog
            id="SessionExpireNotifier"
            title="Expiración de sesión"
            cancelText="Cerrar sesión"
            acceptText="Seguir conectado"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onCancel={handleSignOut}
            onAccept={handleUpdateSession}
        >
            {() => (
                <p>
                    Tu sesión expirará en 
                        <Timer id={"SessionExpiresTimer"}
                            from={60}
                            autoStart={true}
                            onEndTimer={handleSignOut}
                        />
                     ¿Qué quieres hacer?
                </p>
            )}
        </CustomAlertDialog>
    )
}

export default SessionExpireNotifier