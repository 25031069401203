import { FcSurvey } from "react-icons/fc"
import BinnacleItem from "./BinnacleItem"
import { Box, Text } from "@chakra-ui/react"
import { useBinnacleStore } from "@/core/stores/binnacle.store"
import { DatesUtil } from "@/core/utils/dates.util"
import { DataUtil } from "@/core/utils/data.util"
import BinnacleFilters from "./BinnacleFilters"
import CustomDrawer from "@/core/components/organism/CustomDrawer"

interface BinacleProps {
  selectedVersion?: string
  setSelectedVersion?: (selectedVersion: string) => void
}

const BinnacleModal: React.FC<BinacleProps> = ({ selectedVersion, setSelectedVersion }) => {
  const comments = useBinnacleStore(e => e.filteredComments);

  return (
    <CustomDrawer id="BinnacleModal"
      title="Bitácora"
      size="lg"
      acceptText="Enviar comentarios"
      icon={FcSurvey}
      withButtons={false}
      showOverlay={false}
    >
      {() => (
        <>
          <Box className='max-h-[70vh] overflow-y-auto mb-3'>
            <BinnacleFilters />
            {
              comments.length > 0 ?
                <>
                  {
                    comments.map((e, index) => (
                      <BinnacleItem
                        key={index}
                        imgAvatar={e.autor!}
                        comment={e.description!}
                        date={DatesUtil.getDateToComment(e.date!)}
                        titleComment={`Celda: ${DataUtil.getAlphabetLetterFromNumber((e.x! + 1))}${e.y! + 1}`}
                        x={e.x!}
                        y={e.y!}
                        name={e.autor!}
                        version={e.version!}
                        selectedVersion={selectedVersion}
                        setSelectedVersion={setSelectedVersion}
                      />
                    ))
                  }
                </> :
                <Text>No hay comentarios</Text>
            }
          </Box>
          {/* <Button w={'100%'} rightIcon={<FiSend />} onClick={() => closeModal('BinnacleModal')} >
            Enviar comentarios
          </Button> */}
        </>
      )}
    </CustomDrawer>
  )
}

export default BinnacleModal