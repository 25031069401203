import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Role } from "../models/role.model";
import { RoleService } from "../services/role.service";
import { AdminRole } from "../models/admin-role.model";
import { RoleRequest } from "../models/role-request.model";

/** Services */
const roleService = new RoleService()
/** Contrato del Store */
interface AdminRoleState {
    roles: Role[]
    adminRoles: AdminRole[]
    selectedAdminRole?: AdminRole
    selectAdminRole: ( role: AdminRole ) => void
    deselectAdminRole: () => void
    retrieveRoles: () => Promise<boolean>
    retrieveAdminRoles: ( companyId: string ) => Promise<boolean>
    addAdminRole: ( role: RoleRequest ) => Promise<boolean>
    updateAdminRole: ( role: AdminRole ) => Promise<boolean>
    removeAdminRole: ( roleId: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    roles: [],
    adminRoles: [],
    selectedAdminRole: undefined
}

/** Definición del Store */
const Store: StateCreator<AdminRoleState> = ( set, get ) => ({

    ...initialState,

    selectAdminRole: ( role: AdminRole ) => set({ selectedAdminRole: role }),

    deselectAdminRole: () => set({ selectedAdminRole: undefined }),

    retrieveRoles: () => {
        return new Promise(( resolve, reject ) => {
            if ( get().roles.length === 0 ) {
                roleService.getAllRoles().then(
                    response => {
                        const roles: Role[] = response.data
    
                        set({ roles })
    
                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            }
        })
    },

    retrieveAdminRoles: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            if ( get().adminRoles.length === 0 ) {
                roleService.getRolesByCompany( companyId ).then(
                    response => {
                        const data: AdminRole[] = response.respuesta
    
                        set({ adminRoles: data })
    
                        resolve( true )
                    }
                ).catch( () => {
                    get().reset()
                    reject()
                })
            }
        })
    },

    addAdminRole: ( role: RoleRequest ) => {
        return new Promise(( resolve, reject ) => {
            roleService.createRole( role ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newRole: AdminRole = response.respuesta
                        const adminRoles = get().adminRoles

                        set({ adminRoles: [ ...adminRoles, newRole ] })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    updateAdminRole: ( role: AdminRole ) => {
        return new Promise(( resolve, reject ) => {
            roleService.updateRole( role ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const updated: AdminRole = response.respuesta
                        const updatedRoles = get().adminRoles.map(
                            item => item.id === updated.id
                                ? { ...item, ...updated }
                                : item
                        )

                        set({ adminRoles: updatedRoles })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    removeAdminRole: ( roleId: string ) => {
        return new Promise(( resolve, reject ) => {
            roleService.deleteRole( roleId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const filteredAdminRoles = get().adminRoles.filter(
                            item => item.id !== roleId
                        )

                        set({ adminRoles: filteredAdminRoles })

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminRoleStore = create<AdminRoleState>()(
    immer( Store )
)