import AmountIndicator from "./components/AmountIndicator"
import { TbCurrencyDollar, TbDeviceTv, TbAccessPoint, TbPhoto, TbFileText, TbActivity } from "react-icons/tb"
import { IndicatorsData } from "./data/indicators.data"
import { Grid, GridItem } from "@chakra-ui/react"
import { IconType } from "react-icons"
import MediosCompareChart from "./components/MediosCompareChart"
import { MediosCompareData } from "./data/medios-compare.data"
import MediosRadialChart from "./components/MediosRadialChart"
import MediosBarChart from "./components/MediosBarChart"
import { MediosBarData } from "./data/medios-bars.data"

const GraphicsTemplate: React.FC = () => {
    /** */
    const amountIndicators = IndicatorsData

    const getIndicatorIcon = ( type: string ): IconType => {
        switch ( type ) {
            case "AMOUNT": return TbCurrencyDollar
            case "TV": return TbDeviceTv
            case "RADIO": return TbAccessPoint
            case "IMAGE": return TbPhoto
            case "DOCUMENT": return TbFileText
            default: return TbActivity
        }
    }
    /** Graphics data */
    const mediosCompare1 = MediosCompareData
    const mediosRadial = [
        { firstLabel: 'Inversión', firstQuantity: 2030000.00, secondLabel: 'Bonificable', secondQuantity: 952000.00 }
    ]
    const mediosBarData = MediosBarData

    return (
        <div className="flex flex-col gap-4">
            {/* Indicators */}
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                {amountIndicators.map(( indicator, index ) => (
                    <GridItem key={index} colSpan={{ base: 12, md: 3 }}>
                        <AmountIndicator
                            icon={getIndicatorIcon( indicator.type )}
                            filterText={indicator.filterBy}
                            label={indicator.description}
                            amount={indicator.qantity}
                            currency="$"
                            isActive={index === 0 ? true : false}
                        />
                    </GridItem>
                ))}
            </Grid>
            {/* 2 Bars | 1 Donut */}
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                {/* Medios 1 */}
                <GridItem colSpan={{ base: 12, md: 4 }}>
                    <MediosCompareChart
                        label="TV abierta (regular)"
                        amount={3858698.02}
                        currency="$"
                        nsColor="#5D8DEB"
                        prColor="#0344DC"
                        data={mediosCompare1}
                    />
                </GridItem>
                {/* Medios 2 */}
                <GridItem colSpan={{ base: 12, md: 4 }}>
                    <MediosCompareChart
                        label="TV abierta (Key Properties)"
                        amount={3858698.02}
                        currency="$"
                        nsColor="#0BC5EA"
                        prColor="#0987A0"
                        data={mediosCompare1}
                    />
                </GridItem>
                {/* Medios 3 */}
                <GridItem colSpan={{ base: 12, md: 4 }}>
                    <MediosRadialChart
                        label="Estadios Televisivos"
                        data={mediosRadial}
                        nsColor="#0344DC"
                        prColor="#F6E05E"
                    />
                </GridItem>
            </Grid>
            {/* Bars */}
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                {/* Medios 4 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="TV Paga (Regular)"
                        amounts={mediosBarData.medios1.amounts}
                        barName="No. Spots"
                        barColor="#3266ED"
                        data={mediosBarData.medios1.data}
                    />
                </GridItem>
                {/* Medios 5 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="TV Paga (Patrocinios Key Properties)"
                        amounts={mediosBarData.medios2.amounts}
                        barName="No. Spots"
                        barColor="#0031C5"
                        data={mediosBarData.medios2.data}
                    />
                </GridItem>
                {/* Medios 6 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="TV Local"
                        amounts={mediosBarData.medios3.amounts}
                        barName="No. de interacciones"
                        barColor="#805AD5"
                        data={mediosBarData.medios3.data}
                    />
                </GridItem>
                {/* Medios 7 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="TV Local (AAEE)"
                        amounts={mediosBarData.medios4.amounts}
                        barName="No. de interacciones"
                        barColor="#553C9A"
                        data={mediosBarData.medios4.data}
                    />
                </GridItem>
                {/* Medios 8 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="Radio (Pauta regular)"
                        amounts={mediosBarData.medios5.amounts}
                        barName="No. de interacciones"
                        barColor="#22C677"
                        data={mediosBarData.medios5.data}
                    />
                </GridItem>
                {/* Medios 9 */}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <MediosBarChart
                        label="Radio (AAEE)"
                        amounts={mediosBarData.medios6.amounts}
                        barName="No. de interacciones"
                        barColor="#22C677"
                        data={mediosBarData.medios6.data}
                    />
                </GridItem>
            </Grid>
        </div>
    )
}

export default GraphicsTemplate