import useCustomToast from "@/core/hooks/useCustomToast"
import { useAdminCompanyStore } from "../../stores/admin-company.store"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { OwnerCompany } from "../../models/owner-company.model"

const ConfirmCompanyDeletionAlert: React.FC = () => {
    /** */
    const selectedOwnerCompany = useAdminCompanyStore( s => s.selectedOwnerCompany )
    const { removeFromOwnerCompany, deselectOwnerCompany } = useAdminCompanyStore()
    const toast = useCustomToast()

    const handleDeleteCompany = () => {
        removeFromOwnerCompany( selectedOwnerCompany?.id! ).then(
            () => toast.success( 'Empresa eliminada correctamente' )
        ).catch(
            () => toast.error( 'Ocurrió un error al intentar eliminar la empresa' )
        )
    }

    return (
        <CustomAlertDialog
            id="confirmCompanyDeletionAlert"
            title="Eliminar grupo"
            size="xl"
            cancelText="Cancelar"
            acceptText="Estoy de acuerdo"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onAccept={handleDeleteCompany}
            onCloseComplete={deselectOwnerCompany}
        >
            {( data?: OwnerCompany ) => (
                <>
                    <p className="text-lg">
                        Se eliminará la empresa <strong className="text-brand-500">{data?.foreignCompanyName}</strong>. ¿Estás de acuerdo?
                    </p>
                </>
            )}
        </CustomAlertDialog>
    )
}

export default ConfirmCompanyDeletionAlert