import BrandBox from "@/core/components/molecules/BrandBox"
import PlanningDetailHeadCard from "@/modules/planning/components/PlanningDetailHeadCard";
import PlanningTabs from "@/modules/planning/components/PlanningTabs";
import { usePlanningStore } from "@/modules/planning/store/planning.store";
import { Outlet } from "react-router-dom";
import ConciliationDetailHeadCard from "../../components/ConciliationDetailHeadCard";
import ConciliationTabs from "../../components/ConciliationTabs";

const ConciliationSectionLayout: React.FC = () => {
  const selectedPlanning = usePlanningStore(e => e.selectedPlanning);
  /** */
  return (
    <>
      <BrandBox className="pb-0">
        {/* <HeadModule
                  title={selectedPlanning?.name ?? ''}
                  icon={FcSurvey}
              /> */}
        <ConciliationDetailHeadCard
          title={selectedPlanning?.name}
          status={selectedPlanning?.status}
          creationDate={selectedPlanning?.createdDate}
        />
        <ConciliationTabs />
      </BrandBox>
      <section className="py-6">
        <Outlet />
      </section>
    </>
  )
}

export default ConciliationSectionLayout;