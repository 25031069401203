import EmptyDataMessage from '@/core/components/molecules/EmptyDataMessage'
import AmountIndicator from '@/modules/graphics/components/AmountIndicator'
import MediosBarChart from '@/modules/graphics/components/MediosBarChart'
import MediosCompareChart from '@/modules/graphics/components/MediosCompareChart'
import MediosRadialChart from '@/modules/graphics/components/MediosRadialChart'
import { IndicatorsData } from '@/modules/graphics/data/indicators.data'
import { MediosBarData } from '@/modules/graphics/data/medios-bars.data'
import { MediosCompareData } from '@/modules/graphics/data/medios-compare.data'
import { Button, Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { BiCloudUpload } from 'react-icons/bi'
import { TbAccessPoint, TbActivity, TbCurrencyDollar, TbDeviceTv, TbFileText, TbPhoto } from 'react-icons/tb'
import LoadFileModal from './LoadFileModal'
import { useModalStore } from '@/core/stores/modal.store'
import FlowFilters from './FlowFilters'
import ExcelTable from '@/core/components/organism/ExcelTable'
import { useExcelTableStore } from '@/core/stores/excel-table.store'
import { useCampaignStore } from '../store/campaign.store'
import path from 'path'
import fs from 'fs'

function SummaryPage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [active, setActive] = useState<number>(0);
  const amountIndicators = IndicatorsData
  const openModal = useModalStore(s => s.openModal)
  const selectedFileExcel = useExcelTableStore(e => e.selectedFile)
  const openSelectedFile = useExcelTableStore(e => e.openFile)
  const setSelectedFileExcel = useExcelTableStore(e => e.setSelectedFile)
  const { selectedCampaign } = useCampaignStore();

  const getIndicatorIcon = (type: string): IconType => {
    switch (type) {
      case "AMOUNT": return TbCurrencyDollar
      case "TV": return TbDeviceTv
      case "RADIO": return TbAccessPoint
      case "IMAGE": return TbPhoto
      case "DOCUMENT": return TbFileText
      default: return TbActivity
    }
  }

  function arrayBufferToFile(arrayBuffer: ArrayBuffer, fileName: string, mimeType: string) {
    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Crea un objeto File a partir del Blob
    const file = new File([blob], fileName, { type: mimeType });

    console.log(file);

    setSelectedFile(file)

    return file;
}

  const readFile = () => {
    fetch('/dummy/FLOW.xlsx')
      .then(response => response.arrayBuffer())
      .then(data => {
        console.log('Archivo leído exitosamente:', data);
        arrayBufferToFile(data, 'flow.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      })
      .catch(error => console.error('Error al leer el archivo:', error));

  }
  /** Graphics data */
  const mediosCompare1 = MediosCompareData
  const mediosRadial = [
    { firstLabel: 'Inversión', firstQuantity: 2030000.00, secondLabel: 'Bonificable', secondQuantity: 952000.00 }
  ]
  const mediosBarData = MediosBarData

  useEffect(() => {
    if (active === 1) {
      console.log(selectedFileExcel)
      setSelectedFileExcel(selectedFile!)
      setTimeout(() => {
        openSelectedFile()
      }, 50)
    }
  }, [active])

  useEffect(() => {
    if (selectedCampaign?.flow) {
      console.log(selectedCampaign?.flow)
      readFile()
    }
  }, [selectedCampaign])

  return (
    <>
      {
        selectedFile ? (
          <>
            <FlowFilters active={active} setActive={setActive} />
            {
              active === 0 ? (
                <div className="flex flex-col gap-4">
                  <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                    {amountIndicators.map((indicator, index) => (
                      <GridItem key={index} colSpan={{ base: 12, md: 3 }}>
                        <AmountIndicator
                          icon={getIndicatorIcon(indicator.type)}
                          filterText={indicator.filterBy}
                          label={indicator.description}
                          amount={indicator.qantity}
                          currency="$"
                          isActive={index === 0 ? true : false}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                  <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                    <GridItem colSpan={{ base: 12, md: 4 }}>
                      <MediosCompareChart
                        label="TV abierta (regular)"
                        amount={3858698.02}
                        currency="$"
                        nsColor="#5D8DEB"
                        prColor="#0344DC"
                        data={mediosCompare1}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 4 }}>
                      <MediosCompareChart
                        label="TV abierta (Key Properties)"
                        amount={3858698.02}
                        currency="$"
                        nsColor="#0BC5EA"
                        prColor="#0987A0"
                        data={mediosCompare1}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 4 }}>
                      <MediosRadialChart
                        label="Estadios Televisivos"
                        data={mediosRadial}
                        nsColor="#0344DC"
                        prColor="#F6E05E"
                      />
                    </GridItem>
                  </Grid>
                  {/* Bars */}
                  <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="TV Paga (Regular)"
                        amounts={mediosBarData.medios1.amounts}
                        barName="No. Spots"
                        barColor="#3266ED"
                        data={mediosBarData.medios1.data}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="TV Paga (Patrocinios Key Properties)"
                        amounts={mediosBarData.medios2.amounts}
                        barName="No. Spots"
                        barColor="#0031C5"
                        data={mediosBarData.medios2.data}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="TV Local"
                        amounts={mediosBarData.medios3.amounts}
                        barName="No. de interacciones"
                        barColor="#805AD5"
                        data={mediosBarData.medios3.data}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="TV Local (AAEE)"
                        amounts={mediosBarData.medios4.amounts}
                        barName="No. de interacciones"
                        barColor="#553C9A"
                        data={mediosBarData.medios4.data}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="Radio (Pauta regular)"
                        amounts={mediosBarData.medios5.amounts}
                        barName="No. de interacciones"
                        barColor="#22C677"
                        data={mediosBarData.medios5.data}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                      <MediosBarChart
                        label="Radio (AAEE)"
                        amounts={mediosBarData.medios6.amounts}
                        barName="No. de interacciones"
                        barColor="#22C677"
                        data={mediosBarData.medios6.data}
                      />
                    </GridItem>
                  </Grid>
                </div>
              ) : active === 1 &&
              <ExcelTable />
            }
          </>
        )
          : (
            <EmptyDataMessage text='No hay ninguna flow cargada todavía'>
              <Button rightIcon={<BiCloudUpload />} onClick={() => openModal('LoadFileModal')} colorScheme="brand">{/* </Button> */}
                Cargar documento
              </Button>
            </EmptyDataMessage>
          )
      }
      <LoadFileModal setSelectedFile={(e: File | null) => setSelectedFile(e)} />
    </>
  )
}

export default SummaryPage