import { jwtDecode } from "jwt-decode"

export class JwtUtil {

    public static decodeToken( token: string ): any {
        /** */
        const tokenDecoded = jwtDecode( token )
        return tokenDecoded
    }

    public static isExpiredToken( token: string ): boolean {
        /** */
        const exp = this.decodeToken( token ).exp

        const date = +new Date( exp * 1000 )
    
        if ( Date.now() > date ) {
            return true
        }
    
        return false
    }

    public static getAuthHeader() {
        let token = JSON.parse(sessionStorage.authUser ?? "{}").state?.accessToken;
        if (token) {
            return { Authorization: "Bearer " + token };
        } else {
            return {};
        }
    }
}