export const ExcludePathsNav = [
    /** Dashboard */
    '/views',
    /** Admin module */
    '/views/admin',
    '/views/admin/users',
    '/views/admin/user-groups',
    '/views/admin/companies',
    '/views/admin/roles',
    '/views/admin/permissions',
    '/views/admin/config',
    '/views/admin/config/authentication',
    '/views/admin/config/calendar'
]