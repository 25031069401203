import CompairPage from "@/modules/campaigns/components/CompairPage"
import PtvPage from "@/modules/campaigns/components/PtvPage"
import SummaryPage from "@/modules/campaigns/components/SummaryPage"
import TVAPage from "@/modules/campaigns/components/TVAPage"
import { RouteObject } from "react-router-dom"

const FlowSectionRoutes: RouteObject[] = [
    { path: 'summary', element: <SummaryPage /> },
    { path: 'tva', element: <TVAPage tvType='tva' /> },
    { path: 'ptv', element: <TVAPage tvType='ptv' /> },
    { path: 'tvl', element: <TVAPage tvType='tvl' /> },
    { path: 'radio', element: <TVAPage tvType='radio' /> },
    { path: 'stadium', element: <TVAPage tvType='stadium' /> },
    { path: 'ooh', element: <TVAPage tvType='ooh' /> },
    { path: 'press', element: <TVAPage tvType='press' /> },
    { path: 'digital', element: <TVAPage tvType='digital' /> },
    { path: 'tva/compare', element: <CompairPage title='TVA' /> },
    { path: 'ptv/compare', element: <CompairPage title='PTV' /> },
    { path: 'tva/compare', element: <CompairPage title='TVA' /> },
    { path: 'tvl/compare', element: <CompairPage title='TVL' /> },
    { path: 'radio/compare', element: <CompairPage title='Radio' /> },
    { path: 'stadium/compare', element: <CompairPage title='Estadios' /> },
    { path: 'ooh/compare', element: <CompairPage title='OOH' /> },
    { path: 'press/compare', element: <CompairPage title='Prensa' /> },
    { path: 'digital/compare', element: <CompairPage title='Digital' /> },
]

export default FlowSectionRoutes