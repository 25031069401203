import { Box, Text } from "@chakra-ui/react"
import CustomAlertDialog from "@/core/components/organism/CustomAlertDialog"
import { RoadMap } from "@/core/models/planning.model"
import { useNavigationStore } from "@/core/stores/navigation.store"
import useCustomToast from "@/core/hooks/useCustomToast"
import { useModalStore } from "@/core/stores/modal.store"
import { usePlanningStore } from "../store/planning.store"

const SaveRoadMapAlert: React.FC = () => {
  const toast = useCustomToast()
  const sectionId = useNavigationStore(e => e.selectedNavigation)
  const closeModal = useModalStore(s => s.closeModal)
  const { updateRoadMapPlanningItem, retrievePlanningItems, selectedPlanning, tasksRoadmapSelected, roadMapSelected } = usePlanningStore()

  const handleClosePlanning = () => {

    const parsedTasks = JSON.stringify(tasksRoadmapSelected);

    const body: RoadMap[] = selectedPlanning?.roadMapList?.map(e => e.name === roadMapSelected ? {
      name: roadMapSelected,
      jsonText: parsedTasks
    } : e) ?? [];

    updateRoadMapPlanningItem((selectedPlanning?.id ?? ''), body).then(
      () => {
        toast.success('Cambios del archivo guardados correctamente')
        retrievePlanningItems(sectionId?.id!)
        closeModal('SaveRoadMapAlert')
      }
    ).catch(
      () => toast.error('Ocurrió un error al guardar cambios')
    )
  }

  return (
    <CustomAlertDialog
      id="SaveRoadMapAlert"
      title="Guardar cambios"
      cancelText="No"
      acceptText="Si"
      closeOnOverlayClick={true}
      closeOnEsc={true}
      onAccept={handleClosePlanning}
    >
      {() => (
        <Box>
          <Text>¿Desea guardar los cambios realizados en el archivo?</Text>
        </Box>
      )}
    </CustomAlertDialog>
  )
}

export default SaveRoadMapAlert