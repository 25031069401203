import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import CampaignSectionRoutes from "./pages/campaign-section/CampaignSection.routes"

const CampaignsPage = lazy(() => import('./pages/CampaignsPage'))
const CampaignCreate = lazy(() => import('./components/AddCampaingPage'))
const CampaignSectionLayout = lazy(() => import('./pages/campaign-section/CampaignSectionLayout'))

const CampaignRoutes: RouteObject[] = [
  { path: '', element: <CampaignsPage /> },
  {
    path: 'detail',
    element: <CampaignSectionLayout />, 
    children: [
      { index: true, element: <Navigate to="cover" replace /> },
      ...CampaignSectionRoutes
    ]
  },
  { path: 'create', element: <CampaignCreate /> },
]

export default CampaignRoutes